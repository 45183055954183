import * as React from 'react';
import { Typography, Divider, Grid, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { FieldArray, ErrorMessage } from 'formik';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { generateTimeSlots } from '../../../../shared/util/GenerateTimeSlots';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { SelectChangeEvent } from '@mui/material/Select';
import { FormSelectField } from 'app/shared/atoms';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import 'dayjs/locale/es'; // Import the Spanish locale
import 'dayjs/locale/fr'; // Import the French locale
import 'dayjs/locale/en'; // Import the English locale
import moment from 'moment';

dayjs.extend(utc);
dayjs.extend(timezone);

interface TimeField {
	startTime: string;
	endTime: string;
}

interface CourtSection {
	courts: {
		date: string;
		court: string;
		startTime: string;
		endTime: string;
		slots: string[];
	}[];
}

interface AvailabilityProps {
	page?: any;
	formProps?: any;
	selectedClubId?: any;
	selectedClubObj?:any;
	clubsList?: any[];
	onJSONChange?: (json: CourtSection[]) => void; // Callback to pass JSON to parent
	startDate?: any;
	endDate?: any;
}

const StyledAccordionSummary = styled(AccordionSummary)({
	'&.MuiAccordionSummary-root': {
		padding: 0,
	},
});

const Availability: React.FC<AvailabilityProps> = ({
	formProps,
	selectedClubId,
	selectedClubObj,
	clubsList,
	onJSONChange,
	startDate,
	endDate,
}) => {
	const { t } = useTranslation();
	const { setFieldValue, values } = formProps;
	const [datePickerOpen, setDatePickerOpen] = React.useState([]);
	//const [courtSections, setCourtSections] = React.useState<CourtSection[]>([]);

	// React.useEffect(() => {
	//   if (selectedClub && clubsList) {
	//     if (formProps?.values?._id !== undefined) {
	//       const initialCourtSections = formProps?.values?.courts?.map(
	//         (court: any) => ({
	//           courts: [
	//             {
	//               date: court?.date,
	//               court: court?.court,
	//               startTime: court.startTime,
	//               endTime: court?.endTime,
	//               slots: generateTimeSlots(court?.startTime, court?.endTime),
	//             },
	//           ],
	//         })
	//       );
	//       setCourtSections(initialCourtSections);
	//     }
	//   }
	// }, [selectedClub, formProps?.values?.courts]);

	// Function to calculate slots based on the selected date and club's working hours
	const calculateSlots = (selectedDate: any, clubList: any, clubId: any, courtIndex: any, courtId:any) => {
		const club = clubList?.find((club: any) => club._id === clubId);

		if (!club) return [];
		// Extract day of the week from the selected date
		// Get the day of the week as "Monday", "Tuesday", etc.
		const dayOfWeek = dayjs.utc(selectedDate)?.format('dddd')?.toLowerCase();

		let selectedCourt = club?.courts?.find((x:any) => x?._id === courtId);

		if(!selectedCourt) return [];

		// Find the working hours for the selected day
		const workingHours = selectedCourt?.workingHours?.find(
			(day: any) => day.day.toLowerCase() === dayOfWeek
		);

		if (!workingHours) return [];

		// Generate slots based on working hours
		return generateTimeSlots(workingHours.startTime, workingHours.endTime);
	};

	// Function to handle time change
	const handleTimeChange =
		(courtIndex: number, field: keyof TimeField) => (event: SelectChangeEvent<string>) => {
			// Change event type to SelectChangeEvent<string>
			let newCourtSections: any = [...values?.courts];
			newCourtSections[courtIndex][field] = event.target.value;

			setFieldValue('courts', newCourtSections);
		};

	// Function to handle court change
	const handleCourtChange =
		(index: number, values?: any) => (event: SelectChangeEvent<string>) => {
			const newCourtSections = [...values?.courts];
			const newValue = event.target.value; // Access value from event.target.value
			// Remove the selected court from subsequent court sections
			for (let i = index + 1; i < newCourtSections?.length; i++) {
				newCourtSections[i].courts[0].court = '';
				newCourtSections[i].courts[0].slots = [];
			}
			newCourtSections[index].court = newValue;

			setFieldValue('courts', newCourtSections);
		};
	// Function to handle date change
	const handleDateChange = (index: number, values?: any) => (newValue: any) => {
		const newCourtSections = [...values?.courts];

		// Create a deep copy of the object at the specified index
		const courtSection = { ...newCourtSections[index] };

		const selectedDate = dayjs.utc(newValue)?.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

		// Update the date and slots properties on the deep copy
		courtSection.date = selectedDate;
		courtSection.slots = calculateSlots(selectedDate, clubsList, selectedClubId, index, newCourtSections?.[index]?.court);

		// Replace the original object with the updated one
		newCourtSections[index] = courtSection;

		setFieldValue('courts', newCourtSections);
	};

	const handleDatePickerOpen = (courtIndex: number) => {
		setDatePickerOpen((prevState) => {
			const newState: any = [...prevState]; // Create a copy of the previous state array
			newState[courtIndex] = !newState[courtIndex]; // Toggle the specific index (open/close)
			return newState; // Return the updated state array
		});
	};

	return (
		<>
			<FieldArray
				name="courts"
				render={(arrayHelpers: any) => (
					<div>
						{values?.courts?.map((courtSection: any, courtIndex: number) => {
							let selectedCourt:any = selectedClubObj?.courts?.find((court: any) => court?._id === courtSection?.court);
							let day = moment(courtSection?.date).format('dddd')?.toLowerCase();
							let checkClubOpenOrNot = selectedCourt?.workingHours?.find((x:any)=> x?.day === day)?.isChecked || false;

							let disableDates = selectedCourt ? selectedCourt?.workingHours?.filter((x:any)=> !x?.isChecked)?.map((x:any)=> moment().day(x?.day).day()): [];

							const slotEndTimeOptions = checkClubOpenOrNot ? [
									...(values?.courts?.[courtIndex]?.slots || []), // Ensure slots array is not null or undefined
									values?.courts?.[courtIndex]?.endTime, // Append the endTime
								].map((slot: any) => ({
									label: slot,
									value: slot,
								})):[];

							return (
								<Accordion
									style={{ boxShadow: 'none' }}
									defaultExpanded={true}
									key={courtIndex}
								>
									<StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Typography>{t('courtSchedule')}</Typography>
									</StyledAccordionSummary>
									<AccordionDetails>
										<Typography
											variant="body2"
											className="uppercase label"
											gutterBottom
											style={{ marginBottom: '1rem' }}
										>
											{t('court')}
											{/* Remove Court Section Button */}

											{values?.courts?.length > 1 && (
												<IconButton
													onClick={() => arrayHelpers.remove(courtIndex)}
													style={{ float: 'right' }}
												>
													<CloseIcon />
												</IconButton>
											)}
										</Typography>
										<Grid container spacing={2}>
											{/* Court Dropdown */}
											<Grid item xs={12}>
												<FormSelectField
													name={`courts.[${courtIndex}].court`}
													fullWidth
													value={
														values?.courts?.[courtIndex]?.court || ''
													}
													onChange={handleCourtChange(courtIndex, values)}
													options={
														clubsList
															?.find(
																(club: any) =>
																	club?._id === selectedClubId
															)
															?.courts?.filter((x:any)=> x?.status === 'active')?.map((court: any) => ({
																value: court?._id,
																label: court?.name,
															})) || []
													}
												></FormSelectField>
											</Grid>

											{/* Select Date */}
											<Grid item xs={12}>
												<Typography
													variant="body2"
													className="uppercase label"
												>
													{t('selectDate')}
												</Typography>
												<LocalizationProvider
													dateAdapter={AdapterDayjs}
													adapterLocale={
														localStorage.getItem('language') || 'en'
													}
												>
													<DatePicker
														onChange={handleDateChange(
															courtIndex,
															values
														)}
														timezone="UTC"
														value={
															values?.courts?.[courtIndex]?.date
																? dayjs.utc(
																		values?.courts?.[courtIndex]
																			?.date
																  )
																: null
														}
														format="DD/MM/YYYY"
														sx={{ width: '100%' }}
														disablePast
														minDate={dayjs.utc(startDate)}
														maxDate={dayjs.utc(endDate)}
														open={datePickerOpen[courtIndex] || false}
														onOpen={() =>
															setDatePickerOpen((prevState) => {
																const newState: any = [
																	...prevState,
																];
																newState[courtIndex] = true;
																return newState;
															})
														}
														onClose={() =>
															setDatePickerOpen((prevState) => {
																const newState: any = [
																	...prevState,
																];
																newState[courtIndex] = false;
																return newState;
															})
														}
														slotProps={{
															textField: {
																onClick: () =>
																	setDatePickerOpen(
																		(prevState) => {
																			const newState: any = [
																				...prevState,
																			];
																			newState[courtIndex] =
																				true;
																			return newState;
																		}
																	),
															},
														}}
														shouldDisableDate={(date) => {
															const day = date.day(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
															return disableDates?.includes(day) // Disable Sunday and Monday
														}}
													/>
												</LocalizationProvider>
												<ErrorMessage
													name={`courts.${courtIndex}.date`}
													component="div"
													className="error-message"
													render={(msg) => (
														<span
															className="MuiFormHelperText-root Mui-error"
															style={{
																color: '#d32f2f',
																marginRight: '10px',
																marginTop: '10px',
																fontSize: '0.75rem',
															}}
														>
															{msg}
														</span>
													)}
												/>
											</Grid>
											{/* Start Time and End Time */}
											<Grid item xs={5}>
												<Typography
													variant="body2"
													className="uppercase label"
												>
													{t('startTime')}
												</Typography>
												<FormSelectField
													fullWidth
													name={`courts.[${courtIndex}].startTime`}
													value={values?.courts?.[courtIndex]?.startTime}
													onChange={handleTimeChange(
														courtIndex,
														'startTime'
													)}
													options={
														checkClubOpenOrNot ? values?.courts?.[courtIndex]?.slots?.map(
															(slot: any) => ({
																label: slot,
																value: slot,
															})
														) : []
													}
												></FormSelectField>
											</Grid>
											{/* End Time Field */}
											<Grid item xs={5}>
												<Typography
													variant="body2"
													className="uppercase label"
												>
													{t('endTime')}
												</Typography>
												<FormSelectField
													fullWidth
													name={`courts.[${courtIndex}].endTime`}
													value={
														courtSection?.courts?.[courtIndex]?.endTime
													}
													onChange={handleTimeChange(
														courtIndex,
														'endTime'
													)}
													options={slotEndTimeOptions}
												></FormSelectField>
											</Grid>
										</Grid>
									</AccordionDetails>
									{/* Add Court Section Button */}
									<Divider>
										<AddCircleOutlineIcon
											onClick={() => {
												arrayHelpers.insert(courtIndex + 1, {
													court: '',
													date: '',
													startTime: '',
													endTime: '',
												});
											}}
											style={{ cursor: 'pointer' }}
										/>
									</Divider>
								</Accordion>
							);
						})}
					</div>
				)}
			></FieldArray>
		</>
	);
};

export default Availability;
