/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import {
  Button,
  Typography,
  IconButton,
  Box,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Radio,
  Avatar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";

import { AppDispatch } from "app/redux/store";
import { Deactivate } from "app/config/constants";
import { clubStatusUpdate } from "app/entities/Clubs/clubsApiService";
import { userStatusUpdate } from "app/entities/Users/usersApiService";
import { bookingStatusUpdate } from "app/entities/Bookings/bookingsApiService";
import { changeSessionPackageStatus } from "app/entities/SessionPackages/sessionPackagesApiService";
import { coachingLabels } from "app/config/constants";

interface DeactivateProductModalProps {
  id?: any;
  //status?: string;
  openModal?: any;
  onClose?: any;
  modalTxt?: any;
  entityName?: any;
  refreshGrid?: any;
  formData?: any;
  callback?: any;
}

const brownButtonStyle = {
  backgroundColor: "#333333",
  color: "white", // Text color
};

const DeactivateSessionPackageModal: React.FC<DeactivateProductModalProps> = ({
  openModal,
  onClose,
  id,
  //status,
  modalTxt,
  formData,
  entityName,
  refreshGrid,
  callback,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = useState(false);
  const [showButtonOptions, setShowButtonOptions] = useState(false);
  const [showCoachList, setShowCoachList] = useState(false);

  const { status } = formData;

  const [coachesList, setCoachesList] = useState([]);

  const [selectedCoach, setSelectedCoach] = useState<any>(null);

  const { t } = useTranslation();

  const handleConfirm = async () => {
    switch (entityName) {
      case "clubs":
        await clubStatusUpdate(id, status === "active" ? "inactive" : "active");
        handleClose();
        break;
      case "users":
        await userStatusUpdate(id, status === "active" ? "pending" : "active");
        handleClose();
        break;
      case "bookings":
        await bookingStatusUpdate(id, "cancelled");
        handleClose();
        break;
      default:
        break;
    }

    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
    refreshGrid();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleToggle = (value: string) => () => {
    if (selectedCoach === value) {
      setSelectedCoach(undefined);
    } else {
      setSelectedCoach(value);
    }
  };

  const handleChangeStatus = () => {
    dispatch(
      changeSessionPackageStatus(
        formData?._id,
        {
          status: formData?.status === "active" ? "inactive" : "active",
        },
        () => {
          if (typeof callback === "function") {
            callback();
          }
          onClose();
        }
      )
    );
  };

  return (
    <div>
      <Modal open={openModal} onClose={handleClose}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            textAlign: "center",
            left: "50%",
            width: "30%",
            borderRadius: "5px",
            transform: "translate(-50%, -50%)",
            padding: 50,
            background: "white",
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            sx={{
              position: "absolute",
              top: "15px",
              right: "24px",
              cursor: "pointer",
            }}
          >
            <CloseIcon />
          </IconButton>
          <div>
            <img width="100" src={Deactivate} />
          </div>
          <Typography
            style={{
              fontSize: "20px",
              fontWeight: 600,
              marginBottom: "1rem",
            }}
          >
            {formData?.status === "active" ? t("deactivate") : t("activate")} {t('sessionPackage')}
          </Typography>
          <Box className="status-change-description">
             {formData?.status === "active" ? t("sessionPackageDeactivateConfirmation") : t("sessionPackageActivateConfirmation")}
          </Box>

          <Box
            style={{
              marginTop: "2rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => {
                onClose();
              }}
              style={{ marginRight: "7.5px" }}
              className="capitalize"
              variant="outlined"
            >
              {t('cancel')}
            </Button>

            <Button
              onClick={() => {
                handleChangeStatus();
              }}
              style={brownButtonStyle}
              className="capitalize"
            >
              {status === "active" ? t("deactivate") : t("Activate")} {t('sessionPackage')}
            </Button>
          </Box>
        </div>
      </Modal>
    </div>
  );
};

export default DeactivateSessionPackageModal;
