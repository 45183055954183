import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dispatch, AnyAction } from "redux";
import { isEmpty } from "lodash";

import { CUSTOM_ERROR_MSG, HANDLE_ERROR, HTTP_CALL } from "../../config/http";

import {
  GET,
  PUT,
  POST,
  PATCH,
  MEMBERSHIPS_MANAGEMENT,
} from "../../config/API.constants";

import { showToast } from "../../shared/util/toastHelper";
import {
  membershipsReceived,
  membershipsRequested,
  updateMembershipData,
  updateUserMembershipData,
  singleMembershipReceived,
  userMembershipsReceived,
  userMembershipsRequested,
  membershipsReset,
} from "app/redux/membershipsReducer";
import { transformFilters } from "app/shared/util/buildQuery";

type AppDispatch = Dispatch<AnyAction>;

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const fetchMembershipsList =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    const qParams = {
      ...params,
      limit: params?.pageSize || 10,
      page: params?.page || 1,
      sort: params?.sort || "createdAt|DESC",
    };

    const filtersString: any = await transformFilters(qParams);
    const filteredSearchParams = new URLSearchParams(filtersString);

    try {
      dispatch(membershipsRequested());
      response = await HTTP_CALL(
        `${
          MEMBERSHIPS_MANAGEMENT.GET_ALL_SUBSCRIPTIONS
        }?${filteredSearchParams.toString()}`,
        GET
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      dispatch(membershipsReceived(response?.data));

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const getSingleMembership =
  (id?: string, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        `${MEMBERSHIPS_MANAGEMENT.GET_SUBSCRIPTION_DETAILS}/${id}`,
        GET,
        ""
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;
      dispatch(singleMembershipReceived(apiData));

      if (typeof callback === "function") {
        callback(apiData);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const createMembership =
  (values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        MEMBERSHIPS_MANAGEMENT.ADD_COACHING_SUBSCRIPTIONS,
        POST,
        "",
        values
      );

      showToast("Subscription created successfully", "Success", {
        position: toast.POSITION.TOP_RIGHT,
      });

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(response);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const updateSubscription =
  (id: string, values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    try {
      response = await HTTP_CALL(
        `${MEMBERSHIPS_MANAGEMENT.ADD_COACHING_SUBSCRIPTIONS}/${id}`,
        PUT,
        "",
        values
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (statusCode === 200) {
        showToast("Subscription updated successfully", "Success", {
          position: toast.POSITION.TOP_RIGHT,
        });

        if (!isEmpty(apiData)) {
          dispatch(updateMembershipData(apiData));

          if (typeof callback === "function") {
            callback(apiData);
          }

          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Update the coach status and cancel the sessions.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const changeMembershipStatus =
  (id: string, values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        `${MEMBERSHIPS_MANAGEMENT.ADD_COACHING_SUBSCRIPTIONS}/${id}`,
        PATCH,
        "",
        values
      );

      showToast(`Membership ${values?.status} successfully`, "Success", {
        position: toast.POSITION.TOP_RIGHT,
      });

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (!isEmpty(apiData)) {
        dispatch(updateMembershipData(apiData));
      }

      if (typeof callback === "function") {
        callback(response);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const fetchBenefits =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    const filterParamsObj = {
      ...params,
      limit: params?.pageSize || 1000,
      page: params?.page || 1,
    };

    const filteredSearchParams = new URLSearchParams(filterParamsObj);

    try {
      response = await HTTP_CALL(
        `${
          MEMBERSHIPS_MANAGEMENT.GET_BENEFIT
        }?${filteredSearchParams.toString()}`,
        GET
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const fetchUserMembershipsList =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    const qParams = {
      ...params,
      limit: params?.pageSize || 10,
      page: params?.page || 1,
      filters: "userType|customer",
      membership: true,
      sort: params?.sort || "updatedAt|DESC",
    };

    const filtersString: any = await transformFilters(qParams);
    const filteredSearchParams = new URLSearchParams(filtersString);

    try {
      dispatch(userMembershipsRequested());
      response = await HTTP_CALL(
        `${
          MEMBERSHIPS_MANAGEMENT.GET_ALL_USER_MEMBERSHIPS
        }?${filteredSearchParams.toString()}`,
        GET
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      dispatch(userMembershipsReceived(response?.data));

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const createUserMembership =
  (values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        MEMBERSHIPS_MANAGEMENT.ADD_USER_MEMBERSHIP,
        POST,
        "",
        values
      );

      showToast("User membership created successfully", "Success", {
        position: toast.POSITION.TOP_RIGHT,
      });

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(response);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const updateUserMembership =
  (id: string, values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    try {
      response = await HTTP_CALL(
        `${MEMBERSHIPS_MANAGEMENT.UPDATE_USER_MEMBERSHIP}/${id}`,
        PATCH,
        "",
        values
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (statusCode === 200) {
        showToast("User membership updated successfully", "Success", {
          position: toast.POSITION.TOP_RIGHT,
        });

        if (!isEmpty(apiData)) {
          dispatch(updateUserMembershipData(apiData));

          if (typeof callback === "function") {
            callback(apiData);
          }

          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const resetData = () => async (dispatch: AppDispatch) => {
  dispatch(membershipsReset());
};
