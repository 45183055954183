export interface IGeneralInformation {
  name: string;
  type: any;
  priority: number | null;
  description: string;
  status: string;
}

export const defaultGeneralInformationValue: Readonly<IGeneralInformation> = {
  name: "",
  type: [],
  status: "active",
  priority: null,
  description: "",
};
