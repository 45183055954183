import * as yup from "yup";
import { useCommonTranslations } from "./CommonTranslations";
import { VALIDATION } from "app/config/constants";

export const LoginValidationSchema = (screenType: string) => {
  // eslint-disable-next-line
  const { t } = useCommonTranslations();
  let validationData: any;
  switch (screenType) {
    case "Login":
      validationData = {
        username: yup
          .string()
          .email(`${t("invalidEmail")}`)
          .required(`${t("emailRequired")}`),
        password: yup.string().required("Password is required"),
      };
      break;
    case "Forgot Password":
      validationData = {
        username: yup
          .string()
          .email(`${t("invalidEmail")}`)
          .required(`${t("emailRequired")}`),
      };
      break;
    case "Verify OTP":
      validationData = {
        otp: yup.string().required("Otp is required"),
      };
      break;
    case "New Password":
      validationData = {
        createNewPassword: yup
          .string()
          .trim()
          .required("Confirm password is required")
          .matches(
            VALIDATION.PASSWORD_REGEX,
            "Min 8 Max 20 char’s length with at least 1 lowercase, 1 upper case, 1 special character, 1 number."
          ),
        confirmNewPassword: yup
          .string()
          .required("Confirm password is required")
          .oneOf(
            [yup.ref("createNewPassword"), "createNewPassword"],
            "Enter password and confirm new password does not match"
          ),
      };
      break;
    case "Change Password":
      validationData = {
        oldPassword: yup
          .string()
          .trim()
          .required("Old password is required")
          .matches(
            VALIDATION.PASSWORD_REGEX,
            "Min 8 Max 20 char’s length with at least 1 lowercase, 1 upper case, 1 special character, 1 number."
          ),
        newPassword: yup
          .string()
          .trim()
          .required("Confirm password is required")
          .matches(
            VALIDATION.PASSWORD_REGEX,
            "Min 8 Max 20 char’s length with at least 1 lowercase, 1 upper case, 1 special character, 1 number."
          ),
        confirmNewPassword: yup
          .string()
          .required("Confirm password is required")
          .oneOf(
            [yup.ref("newPassword"), "newPassword"],
            "Enter password and confirm new password does not match"
          ),
      };
      break;
  }

  return yup.object().shape(validationData);
};
