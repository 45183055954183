import * as yup from 'yup';
import { useCommonTranslations } from './CommonTranslations';
import { VALIDATION } from 'app/config/constants';

const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

export const AddOrganisationValidationSchema = () => {
	// eslint-disable-next-line
	const { t } = useCommonTranslations();

	return yup.object().shape({
		name: yup
			.string()
			.required(`${t('organisationNameRequired')}`)
			.min(3, `${t('minimum3LettersAllowed')}`)
			.max(25, `${t('maximum25LettersAllowed')}`),
        contactName: yup
			.string()
			.required(`${t('contactPersonRequired')}`)
			.min(3, `${t('minimum3LettersAllowed')}`)
			.max(25, `${t('maximum25LettersAllowed')}`)
			.test('alphabets-only', t('AlphabetsOnly'), (val) => {
				return val === undefined || VALIDATION.ALPHABETS_SPACE.test(val);
			}),
		email: yup
			.string()
			.email(`${t('invalidEmail')}`)
			.required(`${t('emailRequired')}`),
		countryCode: yup.string().required(`${t('countryCodeRequired')}`),
		phone: yup
			.string()
			.required(t('phoneNumberRequired'))
			.max(13, `${t('maximum13LettersAllowed')}`)
			.matches(/^\d+$/, t('invalidPhoneNumber')),
	});
};

export const AddCreditValidationSchema = () => {
	// eslint-disable-next-line
	const { t } = useCommonTranslations();

	return yup.object().shape({
		user: yup.string().required(`${t('userRequired')}`),
		packageid: yup.string().required(`${t('packageRequired')}`),
		//membership: yup.string().required(`${t('membershipRequired')}`),
		//cycleTypes: yup.string().required('Cycle type is required'),
		//category: yup.string().required(`${t("categoryRequired")}`),
		// amountPaid: yup
		// 	.string()
		// 	.test(
		// 		'is-decimal',
		// 		`${t('thePriceShouldbeDecimalwithMaximumTwoDigitsAfterComma')}`,
		// 		(val: any) => {
		// 			if (val !== undefined) {
		// 				return patternTwoDigisAfterComma.test(val);
		// 			}
		// 			return true;
		// 		}
		// 	)
		// 	.typeError('Enter a valid number'),
		// price: yup
		// 	.string()
		// 	.required('Paid Amount is required')
		// 	.test(
		// 		'is-decimal',
		// 		`${t('thePriceShouldbeDecimalwithMaximumTwoDigitsAfterComma')}`,
		// 		(val: any) => {
		// 			if (val !== undefined) {
		// 				return patternTwoDigisAfterComma.test(val);
		// 			}
		// 			return true;
		// 		}
		// 	)
		// 	.typeError('Enter a valid number'),
		// balance: yup
		// 	.string()
		// 	.required('Balance Amount is required')
		// 	.test(
		// 		'is-decimal',
		// 		`${t('thePriceShouldbeDecimalwithMaximumTwoDigitsAfterComma')}`,
		// 		(val: any) => {
		// 			if (val !== undefined) {
		// 				return patternTwoDigisAfterComma.test(val);
		// 			}
		// 			return true;
		// 		}
		// 	)
		// 	.typeError('Enter a valid number'),
	});
};
