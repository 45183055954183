// src/redux/coachingReducer.ts
import { createSlice } from '@reduxjs/toolkit';

const sessionPackageSlice = createSlice({
	name: 'sessionPackages',
	initialState: { data: [], loading: false, error: null },
	reducers: {
		sessionPackagesRequested: (state) => {
			state.loading = true;
			state.error = null;
		},
		sessionPackagesReceived: (state, action) => {
			state.loading = false;
			state.data = action.payload;
		},
		sessionPackagesRequestFailed: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},

		updateSessionPackagesData: (state: any, action) => {
			const updatedData = state?.data?.data?.map((x: any) =>
				x?._id === action?.payload?._id ? { ...x, ...action.payload, updated: true } : x
			);
			return {
				...state,
				loading: false,
				data: { ...state?.data, data: updatedData },
			};
		},
		singleSessionPackageRequested: (state) => {
			state.loading = true;
			state.error = null;
		},
		singleSessionPackageReceived: (state, action) => {
			state.loading = false;
			state.data = action.payload;
		},
		singleSessionPackageRequestFailed: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		sessionPackagesReset: (state) => {
			state.loading = false;
			state.data = [];
		},
	},
});

export const {
	sessionPackagesRequested,
	sessionPackagesReceived,
	sessionPackagesRequestFailed,
	singleSessionPackageRequested,
	singleSessionPackageReceived,
	singleSessionPackageRequestFailed,
	updateSessionPackagesData,
	sessionPackagesReset,
} = sessionPackageSlice.actions;

export default sessionPackageSlice.reducer;
