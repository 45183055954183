type Status =
  | "active"
  | "inactive"
  | "completed"
  | "inprogress"
  | "delete"
  | "pending"
  | "published"
  | "confirmed"
  | "cancelled"
  | "failed";

export const getStatusColor = (status: Status) => {
  const statusColorMap = {
    active: "#DCF2E6",
    inactive: "#FCE4E4",
    completed: "#27AE60",
    inprogress: "#FDF6E2",
    delete: "#000000",
    pending: "#EBEBEB",
    published: "#DDEBFC",
    confirmed: "#27AE60",
    failed: "#EB5757",
    cancelled: "#E6B4B6",
    // Add more status-color mappings as needed
  };

  return statusColorMap[status] || "#F2C94C"; // Default to "#F2C94C" if status is not found
};

export const getStatusFontColor = (status: any) => {
  const statusColorMap: any = {
    active: "#31B268",
    pending: "#9C9C9C",
    inactive: "#EB5757",
    cancelled: "#EB5757",
    inprogress: "#F2C94C",
    delete: "#5C9AF0",
  };

  return statusColorMap[status] || "#333"; // Default to "#333" if status is not found
};

export const getPurchaseProductStatusColor = (status: string) => {
  const statusColorMap: any = {
    placed: "#DCF2E6",
    confirmed: "#FCE4E4",
    completed: "#27AE60",
    failed: "#EB5757",
    cancelled: "#E6B4B6",
    pending: "#FFBF00",
    inprogress: "#FDF6E2",
  };

  return statusColorMap[status] || "#F2C94C";
};
