import React, { useState } from "react";
import { Box, Button, Grid, Typography, IconButton } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import { byteConverter } from "../../../../shared/util/byteConverter";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { showToast } from "../../../../shared/util/toastHelper";
import { uploadImageToS3 } from "app/shared/util/ImageUploadApiService";

interface ClubImagesProps {
  clubImagesData?: any;
  setClubImagesData?: React.Dispatch<React.SetStateAction<any>>;
  action?: string;
  formData?: any;
  hasValues?: boolean;
  setHasValues?: any;
  imgData?: any;
  setImgData?: any;
  formProps?: any;
}

const ClubImages: React.FC<ClubImagesProps> = ({
  formProps,
}) => {
  const { values, setFieldValue } = formProps
  const { t } = useTranslation();

  const displayFileName = (element: any) => {
    return element
      ?.split("/")
      ?.pop()
      ?.substring(element?.split("/")?.pop()?.indexOf("_") + 1)
      ?.replace(/%20/g, "_")
      ?.replace(/%28/g, "_")
      ?.replace(/%286/g, "_")
      ?.replace(/%29/g, "_")
      ?.replace(/[^\w\d.]+/g, "_") // Replace spaces and special characters (except dot) with underscores
      ?.replace(/_+/g, "_") // Replace consecutive underscores with a single underscore
      ?.replace(/^_+|_+$/g, "");
  };
  const [draggedFile, setDraggedFile] = useState<File[]>([]);
  const [isDragActive, setIsDragActive] = useState(false);

  const uploadImages = async (selectedFiles: any) => {
    try {
      const formData = new FormData();
      selectedFiles.forEach((file: any) => formData.append("files", file));

      const uploadImageAPIResponse = await uploadImageToS3(formData);
      return uploadImageAPIResponse;
    } catch (error) {
      // Handle error
      showToast(t("imageUploadedError"), "Error", {
        position: toast.POSITION.TOP_RIGHT,
      });
      throw error;
    }
  };

  const showToastMessage = (message: any, type: any) => {
    showToast(message, type, { position: toast.POSITION.TOP_RIGHT });
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    window.addEventListener(
      "dragover",
      function (e) {
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      "drop",
      function (e) {
        e.preventDefault();
      },
      false
    );
    setIsDragActive(true);
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    window.addEventListener(
      "dragover",
      function (e) {
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      "drop",
      function (e) {
        e.preventDefault();
      },
      false
    );
    setIsDragActive(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    window.addEventListener(
      "dragover",
      function (e) {
        e.preventDefault();
      },
      false
    );
    setIsDragActive(false);
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragActive(false);

    if (e.dataTransfer.files.length > 0) {
      const selectedFiles = Array.from(e.dataTransfer.files);
      setDraggedFile(selectedFiles);
      // Filter unwanted file extensions
      const allowedFileExtensions = ["jpg", "jpeg", "png"];
      const validFiles = selectedFiles.filter((file) => {
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        return fileExtension && allowedFileExtensions.includes(fileExtension);
      });

      if (validFiles.length !== selectedFiles.length) {
        // Display error message for invalid file extensions
        showToastMessage(
          "Invalid file format. Please select file format: JPG, JPEG or PNG.",
          "Error"
        );
        return;
      }

      try {
        const response = await uploadImages(selectedFiles);

        if (response.statusCode === 200) {
          const responseData = response?.data;
          const newAttachments = Array.isArray(responseData)
            ? responseData.map((ele: any) => ({ ...ele, imageUrl: ele?.Location?.Location }))
            : [];

          setFieldValue('attachments', [...(values?.attachments || []), ...(newAttachments || [])]);
          showToastMessage(t("imageUploadedSuccessfully"), "Success");
        } else {
          showToastMessage(t("imageUploadedError"), "Error");
        }
      } catch (error) {
        showToastMessage(t("imageUploadedError"), "Error");
      }
    }
  };

  const handleFileInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      // Filter unwanted file extensions
      const allowedFileExtensions = ["jpg", "jpeg", "png"];
      const validFiles = selectedFiles.filter((file) => {
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        return fileExtension && allowedFileExtensions.includes(fileExtension);
      });

      if (validFiles.length !== selectedFiles.length) {
        // Display error message for invalid file extensions
        showToastMessage(
          "Invalid file format. Please select file format: JPG, JPEG or PNG.",
          "Error"
        );
        return;
      }

      try {
        const response = await uploadImages(selectedFiles);

        if (response.statusCode === 200) {
          const responseData = response?.data;
          const newAttachments = Array.isArray(responseData)
            ? responseData.map((ele: any) => ({ ...ele, imageUrl: ele?.Location?.Location }))
            : [];

          setFieldValue('attachments', [...(values?.attachments || []), ...(newAttachments || [])]);
          showToastMessage(t("imageUploadedSuccessfully"), "Success");
        } else {
          showToastMessage(t("imageUploadedError"), "Error");
        }
      } catch (error) {
        showToastMessage(t("imageUploadedError"), "Error");
      }

      e.target.value = "";
    }
  };

  const handleImageRemove = (index: number) => {
    setFieldValue('attachments', values?.attachments?.filter((x: any, imageIndex: number) => index !== imageIndex))
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <input
        type="file"
        id="fileInput"
        accept="image/*"
        multiple
        style={{ display: "none" }}
        onChange={handleFileInputChange}
      />
      <label htmlFor="fileInput">
        <Button
          variant="outlined"
          component="span"
          sx={{
            width: "100%",
            minHeight: "189px",
            border: "1px solid #E0E0E0",
            padding: "51px 117px, 51px, 117px",
            display: "inline-block",
            textAlign: "center",
            borderRadius: "5px",
          }}
        >
          <CloudUploadIcon style={{ marginTop: "35px", color: "black" }} />
          <Typography
            variant="button"
            display="block"
            sx={{ fontWeight: "bold", color: "black" }}
          >
            {t("clickToUpload")}{" "}
            <span style={{ color: "grey" }}>{t("dragDrop")}</span>
          </Typography>
          <Typography variant="body2" display="block" sx={{ color: "black" }}>
            {t("youCanUpload")}
          </Typography>
        </Button>
      </label>
      <Box display="flex" flexWrap="wrap">
        {values?.attachments?.map((image: any, index: any) => (
          <Grid
            container
            spacing={2}
            style={{ marginTop: "10px" }}
            key={image[index]}
          >
            <Grid item xs={4}>
              <img
                src={image?.imageUrl || image}
                alt={`Uploaded ${index + 1}`}
                style={{ maxWidth: "100%", maxHeight: "180px" }}
              />
            </Grid>
            <Grid item xs={5}>
              {image?.originalname || displayFileName(image)}
              {image?.fileSize ? <Typography>{byteConverter(image?.fileSize)}</Typography> : null}
            </Grid>
            <Grid item xs={3}>
              <IconButton
                color="primary"
                onClick={() => handleImageRemove(index)}
                sx={{ float: "right" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))
        }
      </Box>
    </div>
  );
};

export default ClubImages;
