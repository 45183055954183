/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import {
	Modal,
	Box,
	Button,
	Divider,
	Paper,
	Slide,
	Grid,
	Typography,
	IconButton,
	FormHelperText,
	FormControlLabel,
	Radio,
	RadioGroup,
	Stack,
	FormControl,
	Select,
	MenuItem,
	Link,
} from '@mui/material';

import { Form, Formik, FormikProps } from 'formik';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import { useAuth } from '../../../../AuthContext';
import { RootState, AppDispatch } from '../../../redux/store';
import { defaultCreditValue } from '../../../model/organisation-model';
import { FormTextField, FormSelectField } from 'app/shared/atoms';
import {
	createCredit,
	updateCredit,
	fetchCreditsList,
	fetchOrganisationsList,
} from '../organisationsApiService';
import { fetchSessionPackagesList } from 'app/entities/SessionPackages/sessionPackagesApiService';
import { verifyCoupon } from 'app/entities/Coupons/couponsApiService';
import { UsersMultiSelect } from 'app/shared/molecules';
import { AddCreditValidationSchema } from 'app/shared/validations/OrganisationValidationSchema';
import { cycleTypes, BookingPaymentType } from 'app/config/constants';
import ConfirmDialog from 'app/shared/components/ConfirmDialog';
import { fetchClubsList } from 'app/entities/Clubs/clubsApiService';
import { AntSwitch } from 'app/shared/components/BlackSwitch';
import COUNTRY_CODES from 'app/shared/data/CountryCodes.json';
import { debounce } from 'lodash';
import { extractDialCode } from 'app/shared/components/CountryCodeExtractor';
import { checkPhoneNumberValidity } from 'app/entities/Users/usersApiService';

interface ModalFormProps {
	open: boolean;
	onClose: () => void;
	formData?: any;
	callback?: any;
	action?: any;
}

const brownButtonStyle = {
	backgroundColor: '#333333',
	color: 'white', // Text color
};

const BoldTypography = styled(Typography)(({ theme }) => ({
	fontWeight: 'bold', // Set the font weight to bold
}));

const CycleTypesRadio = ({ values, setFieldValue, membershipList, onChange, t }: any) => {
	const handleChange = (event: any) => {
		if (onChange) {
			onChange(parseInt(event.target.value));
		}
	};

	return (
		<>
			<RadioGroup
				aria-labelledby="radio-buttons-group-label"
				defaultValue={1}
				name="cycleTypes"
			>
				<Grid container spacing={2} alignItems="center" sx={{ justifyContent: 'flex-end' }}>
					{membershipList?.map((x: any, key: number) => (
						<Grid item xs={5} key={`${key}-key`}>
							<FormControlLabel
								value={x?.id}
								control={<Radio />}
								label={t(x?.label)}
								checked={values?.cycleTypes === x?.id}
								onChange={handleChange}
							/>
						</Grid>
					))}
				</Grid>
			</RadioGroup>
		</>
	);
};

const AddCreditModalForm: React.FC<ModalFormProps> = ({ open, onClose, formData, callback }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();
	const { user } = useAuth();
	const clubsList: any = useSelector((state: RootState) => state?.clubs?.clubsData);
	const sessionPackages: any = useSelector((state: RootState) => state?.sessionPackages?.data);

	const formikRef = useRef<FormikProps<any>>(null);
	const [confirmOpen, setConfirmOpen] = useState(false);

	const settingsData: any = localStorage.getItem('settings')
		? JSON.parse(localStorage.getItem('settings') as string)
		: null;
	let percentage = 0;

	const [editData, setEditData] = useState<any>();
	const [organisations, setOrganisations] = useState([]);
	const [membershipCycleTypes, setMembershipCycleTypes] = useState(cycleTypes);
	const [appliedCoupon, setAppliedCoupon] = useState<any>();
	const [showAddUserFields, setShowAddUserFields] = useState(false);

	useEffect(() => {
		if (open) {
			dispatch(
				fetchOrganisationsList(
					{
						pageSize: 1000,
						page: 1,
					},
					(data: any) => {
						setOrganisations(data);
					}
				)
			);
			dispatch(
				fetchSessionPackagesList({
					pageSize: 1000,
					page: 1,
				})
			);
			//if(isCoachPortal) {
			dispatch(fetchClubsList({ limit: 1000, page: 1 }));
			//}
		}

		return () => {
			setOrganisations([]);
		};
	}, [open, dispatch]);

	// Set all the form data used for building the request object to the API finally
	useEffect(() => {
		if (formData?._id) {
			let newData = {
				...formData,
				packageid: formData?.packageid,
				amountToPay:
					parseFloat(formData?.paidAmount) + parseFloat(formData?.balanceAmount || 0),
				...(formData?.type === 'corporate' && {
					corporate: formData?.corporate,
				}),
				...(formData?.type === 'user' && {
					user: formData?.user,
				})
			};

			setEditData(newData);
		}
	}, [formData?._id]);

	/**
	 * Handle Next on button click to load the next step if second page call the api
	 *
	 * @function
	 * @returns void
	 */
	const handleSubmit = async (values?: any, actions?: any) => {
		if (values?.type === 'corporate') delete values.user;
		else delete values.corporate;
		if (formData?._id) {
			delete values._id;

			dispatch(
				await updateCredit(formData?._id, { ...values }, () => {
					if (typeof callback === 'function') {
						callback();
					}
					onClose();
				})
			);
		} else {
			dispatch(
				await createCredit({ ...values }, () => {
					onClose();
					dispatch(fetchCreditsList({ page: 1 }));
				})
			);
		}

		actions.setSubmitting(false);
	};

	/**
	 * Handle close on button click
	 *
	 * @function
	 * @returns void
	 */
	const handleCloseModal = (event?: any, reason?: any) => {
		if (formikRef?.current?.dirty) {
			// Show confirmation dialog if there are unsaved changes
			setConfirmOpen(true);
		} else {
			onClose();
		}
	};

	// Handle close confirmation dialog
	const handleCancelClose = () => {
		setConfirmOpen(false);
	};

	// Handle confirm close action
	const handleConfirmClose = () => {
		setConfirmOpen(false);
		onClose();
	};

	const calculateAmount = (values: any, setFieldValue: any) => {
		let calBalanceAmount =
			parseFloat(values?.amountToPay) - parseFloat(values?.paidAmount || 0);

		setFieldValue('balanceAmount', parseFloat(calBalanceAmount?.toFixed(2)) || 0);
	};

	const handleSwitchChange = (objectKey: string, checked: boolean, setFieldValue: any) => {
		setFieldValue(`${objectKey}`, checked ? 'user' : 'corporate');
		setShowAddUserFields(false);
	};

	const handlePackageCount = async (event: any, setFieldValue?: any) => {
		setFieldValue('noOfPackages', parseInt(event?.target?.value));
	};

	const handleAddUserClick = () => {
		setShowAddUserFields(true);
	};

	const handlePhoneValidation = debounce(
		(val: any, field: string, values: any, setFieldValue: any) => {
			if (field === 'phone') setFieldValue('phone', val);

			if (values?.countryCode && val?.length >= 8) {
				const countryCode: any = extractDialCode(values?.countryCode);

				checkPhoneNumberValidity(
					{ countryCode: parseInt(countryCode), phone: val },
					(data: any) => {
						if (!data) {
							console.log('Phone validation error:', data);
							return;
						}
						// Valid phone number logic here (optional)
					}
				);
			}
		},
		100
	);

	return (
		<>
			<Modal
				open={open}
				onClose={handleCloseModal}
				closeAfterTransition
				slotProps={{
					backdrop: {
						style: {
							backgroundColor: 'rgba(0,0,0,0.5)',
						},
					},
				}}
			>
				<Slide in={open} direction="left">
					<Paper
						style={{
							position: 'absolute',
							right: '0',
							transform: 'translateY(-50%)',
							width: '35%',
							padding: '25px',
							height: '100vh',
							overflow: 'auto',
						}}
					>
						<Box>
							{/* Content of the sliding modal */}
							<BoldTypography variant="h6" style={{ marginBottom: '1rem' }}>
								{formData?._id
									? t('editCreditPurchase')
									: t('addNewCreditPurchase')}
							</BoldTypography>
							<Divider />
							<IconButton
								edge="end"
								color="inherit"
								onClick={handleCloseModal}
								aria-label="close"
								sx={{
									position: 'absolute',
									top: '15px',
									right: '24px',
									cursor: 'pointer',
								}}
							>
								<CloseIcon />
							</IconButton>
							<div>
								<Formik
									innerRef={formikRef}
									initialValues={formData?._id ? editData : defaultCreditValue}
									onSubmit={(values, actions) => {
										delete values.selectedSessionPackageObj;
										handleSubmit(
											{
												...values,
												paidAmount: parseFloat(values?.paidAmount),
												balanceAmount: parseFloat(values?.balanceAmount?.toFixed(2)),
												//createdBy: user?.userId,
											},
											actions
										);
									}}
									enableReinitialize
									//validationSchema={AddCreditValidationSchema()}
								>
									{({
										values,
										errors,
										setFieldValue,
										touched,
										...props
									}: any) => {
										return (
											<>
												<Form>
													<Grid
														container
														spacing={2}
														style={{ marginTop: '10px' }}
													>
														<Grid item xs={12}>
															<Grid
																container
																spacing={2}
																justifyContent={'space-between'}
																sx={{
																	marginTop: '1rem',
																	marginBottom: '1rem',
																}}
															>
																<Grid
																	item
																	xs={6}
																	className="label uppercase"
																	sx={{ fontSize: '15px' }}
																>
																	{t('doYouLikeToPurchaseFor')}
																</Grid>
																<Grid item xs={6}>
																	<Stack
																		direction="row"
																		spacing={1}
																		alignItems="center"
																		sx={{ float: 'right' }}
																	>
																		<Typography
																			sx={{
																				fontSize: '12px',
																			}}
																		>
																			{t('organisation')}
																		</Typography>
																		<AntSwitch
																			checked={
																				values?.type ===
																				'user'
																			}
																			onChange={(e) => {
																				handleSwitchChange(
																					'type',
																					e.target
																						.checked,
																					setFieldValue
																				);
																			}}
																			disabled={formData?._id}
																		/>
																		<Typography
																			sx={{
																				fontSize: '12px',
																			}}
																		>
																			{t('user')}
																		</Typography>
																	</Stack>
																</Grid>
															</Grid>
														</Grid>

														{values?.type === 'corporate' ? (
															<Grid item xs={12}>
																<Box>
																	<FormSelectField
																		label={t('corporate')}
																		name="corporate"
																		options={
																			organisations?.length >
																			0
																				? organisations?.map(
																						(x: any) =>
																							(x = {
																								...x,
																								label: x?.name,
																								value: x?._id,
																							})
																				  )
																				: []
																		}
																		onChangeCallback={(
																			value: any
																		) => {
																			setFieldValue(
																				'corporate',
																				value
																			);
																		}}
																		disabled={formData?._id}
																	/>
																</Box>
															</Grid>
														) : (
															<Grid item xs={12}>
																<Box>
																	<UsersMultiSelect
																		label={t('user')}
																		value={
																			values?.user
																				? values?.user
																				: []
																		} // Pass the value from Formik's state
																		onChange={(user: any) => {
																			setFieldValue(
																				'user',
																				user
																			);
																		}}
																		disabled={formData?._id}
																		isShowAdminUsers={false}
																		listAll={true}
																	/>
																</Box>
																<FormHelperText>
																	{touched?.user &&
																	errors?.user ? (
																		<span
																			className="MuiFormHelperText-root Mui-error"
																			style={{
																				color: '#d32f2f',
																			}}
																		>
																			{errors?.user}
																		</span>
																	) : (
																		''
																	)}
																</FormHelperText>
																{!showAddUserFields && !formData?._id && (
																	<Typography>
																		<Link
																			href="#"
																			onClick={() =>
																				handleAddUserClick()
																			}
																		>
																			+ {t('AddNewuser')}
																		</Link>
																	</Typography>
																)}
															</Grid>
														)}

														{/* First Name and Last Name */}
														{showAddUserFields && (
															<>
																<Grid
																	container
																	spacing={2}
																	style={{ marginLeft: '0px' }}
																>
																	<Grid item xs={6}>
																		{' '}
																		<BoldTypography variant="h6">
																			{t('newUser')}
																		</BoldTypography>
																	</Grid>
																	<Grid item xs={6}></Grid>
																	<Grid item xs={6}>
																		<FormTextField
																			name="firstName"
																			label={t('firstName')}
																		/>
																	</Grid>
																	<Grid item xs={6}>
																		<FormTextField
																			name="lastName"
																			label={t('lastName')}
																		/>
																	</Grid>
																</Grid>

																{/* Phone, Email, and Country Code */}
																<Grid
																	container
																	spacing={2}
																	style={{
																		marginLeft: '0px',
																		marginTop: '15px',
																	}}
																>
																	<Grid item xs={2}>
																		<FormSelectField
																			label={t('phone')}
																			name="countryCode"
																			options={COUNTRY_CODES?.map(
																				(x: any) =>
																					(x = {
																						...x,
																						label: x?.name,
																						value: x?.name,
																					})
																			)}
																			customWidth="240"
																			customMarginLeft="55"
																			onChangeCallback={(
																				val: any
																			) =>
																				handlePhoneValidation(
																					val,
																					'countryCode',
																					values,
																					setFieldValue
																				)
																			}
																		/>
																	</Grid>
																	<Grid item xs={4}>
																		<FormControl
																			fullWidth
																			sx={{
																				marginTop: '2.4rem',
																			}}
																		>
																			<FormTextField
																				name="phone"
																				// customCallback={(val:any)=>{
																				// 	setFieldValue("phone", val);
																				// 	if(values?.countryCode && val) {
																				// 		let countryCode:any = extractDialCode(values?.countryCode);
																				// 		checkPhoneNumberValidity({countyCode: parseInt(countryCode), phone: val}, (error:any)=>{
																				// 			if(error){
																				// 				return
																				// 			}
																				// 		});
																				// 	}
																				// }}
																				customCallback={(
																					val: any
																				) =>
																					handlePhoneValidation(
																						val,
																						'phone',
																						values,
																						setFieldValue
																					)
																				}
																			/>
																		</FormControl>
																	</Grid>
																	<Grid item xs={6}>
																		{' '}
																		<FormControl
																			fullWidth
																			sx={{
																				marginTop: '0.3rem',
																			}}
																		>
																			<FormTextField
																				name="email"
																				label={t('email')}
																			/>
																		</FormControl>
																	</Grid>
																</Grid>
																<Grid
																	container
																	spacing={2}
																	style={{
																		marginLeft: '0px',
																		marginTop: '5px',
																	}}
																>
																	<Grid item xs={12}>
																		{' '}
																		<FormControl fullWidth>
																			<FormTextField
																				name="playerLevel"
																				label={t(
																					'PlayerRange'
																				)}
																				required
																				customCallback={(
																					e: any
																				) => {
																					const inputValue =
																						e;
																					if (
																						inputValue ===
																							'' ||
																						/^\d+(\.\d{0,2})?$/.test(
																							inputValue
																						)
																					) {
																						// Update the field value
																						setFieldValue(
																							'playerLevel',
																							inputValue
																						);
																					}
																				}}
																			/>
																		</FormControl>
																	</Grid>
																</Grid>

																<Grid item xs={12}>
																	<Box
																		sx={{
																			cursor: 'pointer',
																			marginTop: '1rem',
																			fontSize: '14px',
																			fontWeight: 600,
																			marginLeft: '1rem',
																		}}
																		onClick={() =>
																			setShowAddUserFields(
																				false
																			)
																		}
																	>
																		{t('cancelUser')}
																	</Box>
																</Grid>
															</>
														)}

														<Grid item xs={12}>
															<Box>
																<FormSelectField
																	label={t('package')}
																	name="packageid"
																	options={
																		sessionPackages?.data
																			?.length > 0
																			? sessionPackages?.data
																					?.filter(
																						(x: any) =>
																							(values?.type ===
																								'corporate' &&
																								x?.isCorporateApplicable) ||
																							(values?.type ===
																								'user' &&
																								x?.isUserApplicable)
																					)
																					?.map(
																						(x: any) =>
																							(x = {
																								...x,
																								label: x?.name,
																								value: x?._id,
																							})
																					)
																			: []
																	}
																	onChangeCallback={(
																		value: any,
																		sessionPackageObj: any
																	) => {
																		let findSessionPackage: any =
																			sessionPackages?.data?.find(
																				(x: any) =>
																					x?._id === value
																			);

																		setFieldValue(
																			'selectedSessionPackageObj',
																			findSessionPackage
																		);

																		setFieldValue(
																			'packageid',
																			value
																		);

																		if (
																			findSessionPackage?.price &&
																			values?.noOfPackages
																		) {
																			setFieldValue(
																				'amountToPay',
																				values?.noOfPackages *
																					(findSessionPackage?.price ||
																						0)
																			);
																			setFieldValue(
																				'paidAmount',
																				values?.noOfPackages *
																					(findSessionPackage?.price ||
																						0)
																			);
																		}
																	}}
																	disabled={formData?._id}
																/>
															</Box>
														</Grid>
														<Grid item xs={12}>
															<FormTextField
																fullWidth
																label={t('countOfPackage')}
																name="noOfPackages"
																type="number"
																value={values?.noOfPackages}
																disabled={formData?._id}
																onChange={(
																	e: React.ChangeEvent<HTMLInputElement>
																) =>
																	handlePackageCount(
																		e,
																		setFieldValue
																	)
																}
																onBlur={(
																	e: React.FocusEvent<HTMLInputElement>
																) => {
																	const value = parseInt(
																		e.target.value,
																		10
																	);
																	let newValue =
																		isNaN(value) || value <= 0
																			? 1
																			: value;
																	setFieldValue(
																		'noOfPackages',
																		newValue
																	);
																	setFieldValue(
																		'amountToPay',
																		newValue *
																			(values
																				?.selectedSessionPackageObj
																				?.price || 0)
																	);
																	setFieldValue(
																		'paidAmount',
																		newValue *
																			(values
																				?.selectedSessionPackageObj
																				?.price || 0)
																	);
																}}
															/>
														</Grid>
														<Grid item xs={12}>
															<Grid container spacing={2}>
																<Grid item xs={6}>
																	<FormTextField
																		name="amountToPay"
																		label={t('amountToPay')}
																		fullWidth
																		customCallback={(value) => {
																			setFieldValue(
																				'amountToPay',
																				value
																			);
																			calculateAmount(
																				{
																					...values,
																					paidAmount:
																						value,
																				},
																				setFieldValue
																			);
																		}}
																		//disabled={true}
																		showPriceSymbol={true}
																	/>
																</Grid>{' '}
																<Grid item xs={6}>
																	<FormTextField
																		name="paidAmount"
																		label={t('paidAmount')}
																		fullWidth
																		customCallback={(value) => {
																			setFieldValue(
																				'paidAmount',
																				value
																			);
																			calculateAmount(
																				{
																					...values,
																					paidAmount:
																						value,
																				},
																				setFieldValue
																			);
																		}}
																		//disabled={formData?._id}
																		showPriceSymbol={true}
																	/>
																</Grid>{' '}
															</Grid>
														</Grid>
														<Grid item xs={12}>
															<Grid container spacing={2}>
																<Grid item xs={6}>
																	<FormTextField
																		name="balanceAmount"
																		label={t('balanceAmount')}
																		fullWidth
																		//disabled={formData?._id}
																		showPriceSymbol={true}
																	/>
																</Grid>

																<Grid item xs={6}>
																	<Grid
																		item
																		xs={
																			values?.paymentType ===
																			BookingPaymentType?.GYMLIB
																				? 6
																				: 12
																		}
																	>
																		<FormControl
																			fullWidth
																			style={{
																				marginTop: '15px',
																			}}
																		>
																			<Typography
																				variant="subtitle1"
																				gutterBottom
																				className="uppercase label"
																				sx={{
																					fontSize:
																						'12px',
																				}}
																			>
																				{t('paymentType')}
																			</Typography>
																			<Select
																				name="paymentType"
																				value={
																					values?.paymentType
																				}
																				onChange={(
																					e: any
																				) => {
																					//setPaymentType(e.target.value as string);

																					setFieldValue(
																						'paymentType',
																						e?.target
																							?.value ||
																							''
																					);

																					if (
																						values?.paymentType ===
																							BookingPaymentType?.GYMLIB ||
																						values?.paymentType ===
																							BookingPaymentType?.SUBSCRIPTION
																					) {
																						//setPaymentStatus(PaidOptions?.PAID);
																						//setFieldValue('', PaidOptions?.PAID)
																					}

																					//handleOnChange("paymentType", setFieldValue);
																				}}
																			>
																				<MenuItem
																					value={
																						BookingPaymentType?.CASH
																					}
																				>
																					{t('cash')}
																				</MenuItem>
																				<MenuItem
																					value={
																						BookingPaymentType?.CARD
																					}
																				>
																					{t(
																						'creditCard'
																					)}
																				</MenuItem>
																				{/* Add more player options */}
																			</Select>
																		</FormControl>
																	</Grid>
																</Grid>
															</Grid>
														</Grid>
													</Grid>

													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between',
														}}
													>
														<Grid
															container
															spacing={2}
															style={{ marginTop: '15px' }}
														>
															<Grid
																item
																xs={12}
																container
																justifyContent="flex-end"
															>
																<Button
																	variant="contained"
																	color="primary"
																	style={brownButtonStyle}
																	type="submit"
																>
																	{formData?._id
																		? t('saveChanges')
																		: t(
																				'saveNewCreditPurchase'
																		  )}
																</Button>
															</Grid>
														</Grid>
													</div>
												</Form>
											</>
										);
									}}
								</Formik>
							</div>
						</Box>
					</Paper>
				</Slide>
			</Modal>

			{/* Confirmation dialog for unsaved changes */}
			<ConfirmDialog
				open={confirmOpen}
				onClose={handleCancelClose}
				onConfirm={handleConfirmClose}
			/>
		</>
	);
};

export default AddCreditModalForm;
