export interface IGeneralInformation {
  profilePic?: string;
  firstName: string;
  lastName: string;
  countryCode: any;
  phone: any;
  email: string;
  club: string;
  category: string;
  groupCoachingPricePerHour: any;
  privateCoachingPricePerHour: any;
  description: string;
  completedSessions: 0;
  coachLevels: [];
  holidays: any;
  availability: any;
}

export const defaultGeneralInformationValue: Readonly<IGeneralInformation> = {
  profilePic: "",
  firstName: "",
  lastName: "",
  countryCode: "",
  phone: "",
  email: "",
  club: "",
  category: "",
  groupCoachingPricePerHour: "",
  privateCoachingPricePerHour: "",
  description: "",
  completedSessions: 0,
  coachLevels: [],
  holidays: [{ from: "", to: "" }],
  availability: [],
};
