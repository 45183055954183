import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dispatch, AnyAction } from "redux";
import { isEmpty } from "lodash";

import { CUSTOM_ERROR_MSG, HANDLE_ERROR, HTTP_CALL } from "../../config/http";
import { GET, POST, PATCH, TAGS_MANAGEMENT } from "../../config/API.constants";
import { transformFilters } from "app/shared/util/buildQuery";
import { showToast } from "../../shared/util/toastHelper";
import {
  tagsReceived,
  updateTagData,
  singleTagReceived,
  tagsRequested,
  tagsReset,
} from "app/redux/tagsReducer";

type AppDispatch = Dispatch<AnyAction>;

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const fetchTagsList =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    const qParams = {
      ...params,
      ...(params?.filters && { filters: params?.filters }),
      limit: params?.pageSize || 10,
      sort: params?.sort || "createdAt|DESC",
      //filters: params?.filters || "status|active",
    };
    // // Construct the filters object from the query parameter
    const filtersString: any = await transformFilters(qParams);
    const filteredSearchParams = new URLSearchParams(filtersString);

    try {
      dispatch(tagsRequested());

      response = await HTTP_CALL(
        `${TAGS_MANAGEMENT.GET_ALL_TAGS}?${filteredSearchParams.toString()}`,
        GET
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      dispatch(tagsReceived(response?.data));

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const getSingleTag =
  (id?: string, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        `${TAGS_MANAGEMENT.GET_TAG_DETAILS}/${id}`,
        GET,
        ""
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;
      dispatch(singleTagReceived(apiData));

      if (typeof callback === "function") {
        callback(apiData);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const manageTag =
  (values: any, id?: string, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      if (id) {
        // Update tag
        response = await HTTP_CALL(
          `${TAGS_MANAGEMENT.ADD_TAG}/${id}`,
          PATCH,
          "",
          values
        );

        showToast("Tag updated successfully", "Success", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        // Create tag
        response = await HTTP_CALL(TAGS_MANAGEMENT.ADD_TAG, POST, "", values);

        showToast("Tag created successfully", "Success", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(response);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          if (id) {
            dispatch(updateTagData({ ...values, _id: id }));
          }
          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Update the coach status and cancel the sessions.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const changeTagStatus =
  (id: string, values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        `${TAGS_MANAGEMENT.ADD_TAG}/${id}/status`,
        PATCH,
        "",
        values
      );

      showToast(`Tag ${values?.status} successfully`, "Success", {
        position: toast.POSITION.TOP_RIGHT,
      });

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (!isEmpty(apiData)) {
        dispatch(updateTagData({ ...values, _id: id }));
      }

      if (typeof callback === "function") {
        callback(response);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const resetData = () => async (dispatch: AppDispatch) => {
  dispatch(tagsReset());
};
