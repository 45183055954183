export interface IGeneralInformation {
  name: string;
  price: number | null;
  noOfCredits : number | any,
  noOfFreeCredits: number | any,
  status: string;
  isUserApplicable: boolean,
  isCorporateApplicable: boolean
}

export const defaultGeneralInformationValue: Readonly<IGeneralInformation> = {
  name: "",
  price: null,
  noOfCredits : null,
  noOfFreeCredits: null,
  status: "active",
  isUserApplicable: true,
  isCorporateApplicable: true
};
