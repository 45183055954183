import { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

export function GooglePlacesAutoComplete({
  callback,
  defaultValue,
  error,
}: any) {
  const [address, setAddress] = useState<any>();

  useEffect(() => {
    setAddress(defaultValue || undefined);
  }, [defaultValue]);

  const handleSelect = async (value: any) => {
    try {
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      const addressObj: any = {};
      addressObj.address1 = value;
      addressObj.location = {
        type: "Point",
        coordinates: [latLng?.lng, latLng?.lat],
      };

      if (results?.length) {
        results?.[0]?.address_components.forEach((component: any) => {
          if (component.types.includes("locality")) {
            addressObj.city = component.long_name;
          } else if (component.types.includes("administrative_area_level_1")) {
            addressObj.state = component.long_name;
          } else if (component.types.includes("country")) {
            addressObj.country = component.long_name;
          }
        });
        if (typeof callback === "function") callback(addressObj);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClear = () => {
    setAddress("");
    if (typeof callback === "function") callback({});
  };

  return (
    <div>
      <PlacesAutocomplete
        value={address || ""}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({
          getInputProps,
          suggestions,
          getSuggestionItemProps,
          loading,
        }: any) => (
          <div>
            <TextField
              {...getInputProps({ placeholder: "Enter address" })}
              fullWidth
              placeholder="Select address"
              InputProps={{
                endAdornment: address && (
                  <IconButton edge="end" onClick={handleClear}>
                    <ClearIcon />
                  </IconButton>
                ),
              }}
              error={error ? true : false}
            />
            <div>
              {loading ? (
                <div>Loading...</div>
              ) : (
                <List>
                  {suggestions.map((suggestion: any) => (
                    <ListItem
                      {...getSuggestionItemProps(suggestion)}
                      key={suggestion.placeId}
                      sx={{ cursor: "pointer" }}
                    >
                      <ListItemText primary={suggestion.description} />
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      {error && (
        <span
          className="MuiFormHelperText-root Mui-error"
          style={{
            color: "#d32f2f",
            fontSize: "0.75rem",
            position: "relative",
            top: "-1rem",
          }}
        >
          {error}
        </span>
      )}
    </div>
  );
}
