import { FC, useState } from "react";
import {
  Typography,
  Divider,
  Grid,
  IconButton,
  FormControlLabel,
  Checkbox,
  Radio,
  Select,
  MenuItem,
  RadioGroup,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { FieldArray, ErrorMessage } from "formik";
import { capitalize } from "lodash";
import "dayjs/locale/es"; // Import the Spanish locale
import "dayjs/locale/fr"; // Import the French locale
import "dayjs/locale/en"; // Import the English locale

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { coachingLabels } from "app/config/constants";

import FormGroup from "@mui/material/FormGroup";
interface ScheduleProps {
  page?: any;
  formProps?: any;
  timeSlots?: any;
}

const StyledAccordionSummary = styled(AccordionSummary)({
  "&.MuiAccordionSummary-root": {
    padding: 0,
  },
});

const StyledRadio = styled(Radio)({
  color: "#727272",
  "&.Mui-checked": {
    color: "#333333",
  },
});

const Schedule: FC<ScheduleProps> = ({ formProps }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<any>([]);
  const { errors, values, submitCount, touched, setFieldTouched } = formProps;

  const [datePickerOpen, setDatePickerOpen] = useState<any>([]);
  const [datePickerToOpen, setDatePickerToOpen] = useState<any>([]);

  /**
   * Handle the peak time change with the entered peak start and end time and the added rows of the same
   * @param {string} dayIndex - Day Index in the workingHours array
   * @param {number} [timingIndex] - Peak Index of the timing array
   * @param {string} field - Corresponding field to be assigned
   * @param {string} [value] - Corresponding value to be passed
   * @function
   * @returns updatedWorkingHours array with the updated values
   */
  const handleStartTimeChange = (
    dayIndex: number,
    timingIndex: number,
    field: string,
    value: string
  ) => {
    formProps.setFieldValue(
      `availability.${dayIndex}.timing.${timingIndex}.${field}`,
      value
    );
  };

  const handleRadioChange =
    (objectKey: string, formProps: any) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const val = (event.target as HTMLInputElement).value;
      formProps.setFieldValue(objectKey, val && val === "true" ? true : false);
    };

  const handleAccordianChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded((prevExpanded: any) =>
        isExpanded
          ? [...prevExpanded, panel]
          : prevExpanded.filter((item: any) => item !== panel)
      );
    };

  const renderTimingError = (dayIndex: number, timingIndex: number) => {
    const errVal = formProps.errors?.availability?.[dayIndex]?.timing?.find(
      (x: any) => x?.errorTimingIndex === timingIndex
    );

    return errVal?.start || errVal?.end || errVal?.commonError;
  };

  const handleDatePickerOpen = (index: number, field: string) => {
    setDatePickerOpen((prevState: any) => {
      // Ensure prevState[index] exists, initialize if it doesn't
      if (!prevState[index]) {
        prevState[index] = { from: false, to: false }; // Initialize with default values
      }

      const newState = [...prevState]; // Create a copy of prevState

      // Modify the desired property based on the field (from or to)
      if (field === "from") {
        newState[index].from = !newState[index].from; // Toggle from field
      } else if (field === "to") {
        newState[index].to = !newState[index].to; // Toggle to field
      }

      return newState; // Return the updated state
    });
  };

  return (
    <>
      <Typography
        variant="body2"
        className="uppercase label"
        gutterBottom
        style={{ marginBottom: "1rem" }}
      >
        {t("coachingDetails")}
      </Typography>

      <Typography variant="body2" gutterBottom className="uppercase label">
        {t("coachingLevel")}
      </Typography>

      <FormGroup>
        {" "}
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ marginBottom: "10px" }}
        >
          {coachingLabels?.map(({ label, id: labelId }) => (
            <Grid item xs={3} key={labelId}>
              <FormControlLabel
                key={labelId}
                control={
                  <Checkbox
                    // defaultChecked={
                    //   values?.coachLevels?.includes(labelId) || false
                    // }
                    checked={values?.coachLevels?.includes(labelId) || false}
                    onChange={(e) => {
                      //handleCheckboxChange(id);

                      e.stopPropagation();
                      const isChecked = e.target.checked;
                      let newCoachLevels;

                      if (isChecked) {
                        newCoachLevels = labelId
                          ? [...values?.coachLevels, labelId]
                          : [labelId];
                      } else {
                        newCoachLevels = values?.coachLevels?.filter(
                          (id: any) => id !== labelId
                        );
                      }

                      formProps.setFieldValue("coachLevels", newCoachLevels);
                    }}
                  />
                }
                label={label}
              />
            </Grid>
          ))}
          {submitCount > 1 && touched?.coachLevels && errors?.coachLevels && (
            <FormHelperText>
              <span
                className="MuiFormHelperText-root Mui-error"
                style={{ color: "#d32f2f", paddingLeft: "1rem" }}
              >
                {errors?.coachLevels}
              </span>
            </FormHelperText>
          )}
        </Grid>
      </FormGroup>

      <Typography variant="body2" className="uppercase label" gutterBottom>
        {t("daysTimes")}
      </Typography>

      <FormControlLabel
        control={
          <Checkbox
            defaultChecked={
              values?._id
                ? values?.availability?.length > 0 &&
                  values?.availability?.filter((x: any) => x?.isChecked)
                    ?.length === values?.availability?.length
                : false
            } // Add a new property isChecked to WorkingHours interface
            onChange={(e) => {
              const nwVal = values?.availability?.map(
                (x: any) =>
                  (x = {
                    ...x,
                    isChecked:
                      e.target.checked && x?.slots?.length > 0 ? true : false,
                  })
              );
              formProps.setFieldValue("availability", nwVal);
            }}
          />
        }
        label={t("selectAllDays")}
      />

      <Typography sx={{ marginBottom: '1rem', fontSize:'14px', fontWeight: 600}}>* {t("AvailablityOneHour")}</Typography>

      <FieldArray
        name="availability"
        render={(arrayHelpers: any) => (
          <div>
            {values?.availability?.map((x: any, dayIndex: number) => {
              const disableAccordion =
                values?.availability?.[dayIndex]?.slots?.length === 0;

              const markRedColor =
                touched?.availability?.[dayIndex]?.timing?.length > 0 &&
                errors?.availability?.[dayIndex]?.timing?.length > 0 &&
                submitCount;

              return (
                <Grid item key={`availability-[${dayIndex}]`} xs={12}>
                  <Divider />

                  <Accordion
                    //disabled={disableAccordion}
                    key={dayIndex}
                    elevation={0}
                    expanded={expanded?.includes(`panel-${dayIndex + 1}`)}
                    onChange={handleAccordianChange(`panel-${dayIndex + 1}`)}
                  >
                    <StyledAccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Grid item xs={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={x?.isChecked}
                              //defaultChecked={x?.isChecked}
                              onChange={(e) => {
                                e.stopPropagation();
                                formProps.setFieldValue(
                                  `availability.${dayIndex}.isChecked`,
                                  !x?.isChecked
                                );
                                if (!x?.isChecked) {
                                  setFieldTouched(
                                    `availability.${dayIndex}.timing`,
                                    false
                                  );
                                }
                              }}
                            />
                          }
                          //label={capitalize(x?.day)}
                          label={capitalize(`${t(x?.day)}`)}
                        />
                      </Grid>
                      {markRedColor ? (
                        <Grid item alignContent={"center"}>
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              paddingTop: "10px",
                              fontWeight: 600,
                            }}
                          >
                            {t("SetStartEndTime")}
                          </Typography>
                        </Grid>
                      ) : null}
                    </StyledAccordionSummary>

                    <AccordionDetails>
                      <FieldArray
                        name={`availability.${dayIndex}.timing`}
                        render={(timingArrayHelpers) => (
                          <div>
                            {x?.timing?.map(
                              (timing: any, timingIndex: number) => (
                                <div key={timingIndex}>
                                  <>
                                    {" "}
                                    {timingIndex === 0 && (
                                      <Grid
                                        container
                                        spacing={2}
                                        key={timing[timingIndex]}
                                        style={{ marginTop: "5px" }}
                                      >
                                        <Grid item xs={3}>
                                          <Typography>
                                            {t("startTime")}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Typography>
                                            {t("endTime")}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    )}
                                    <Grid
                                      container
                                      spacing={2}
                                      key={timing[timingIndex]}
                                      style={{ marginTop: "5px" }}
                                    >
                                      <Grid item xs={3}>
                                        {/* Dropdown for Start Time */}
                                        <Select
                                          //defaultValue={x.start}
                                          placeholder="HH:MM"
                                          value={timing.start}
                                          onChange={(e) => {
                                            // Update the start time for the specified day and timing index.
                                            handleStartTimeChange(
                                                dayIndex,
                                                timingIndex,
                                                "start",
                                                e?.target?.value
                                              )

                                              // Split the time string into hours and minutes
                                              let [hours, minutes] = e?.target?.value.split(':').map(Number);
                                              
                                              // Add 1 hour
                                              hours += 1;

                                              // Handle wrapping around for 24-hour format
                                              if (hours === 24) {
                                                  hours = 0; // Wrap around to 00:00
                                              }
                                              
                                              // Prepopulate the end time by adding 1 hour to the start time
                                              handleStartTimeChange(
                                                  dayIndex,
                                                  timingIndex,
                                                  "end",
                                                `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
                                              )
                                            }
                                          }
                                          fullWidth
                                          disabled={
                                            !values?.availability?.[dayIndex]
                                              ?.isChecked
                                          }
                                          displayEmpty
                                        >
                                          <MenuItem value="" disabled>
                                            HH:MM
                                          </MenuItem>

                                          {x?.slots?.map((option: any) => (
                                            <MenuItem
                                              key={option}
                                              value={option}
                                            >
                                              {option}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </Grid>

                                      <Grid item xs={3}>
                                        {/* Dropdown for End Time */}
                                        <Select
                                          placeholder="HH:MM"
                                          value={timing?.end}
                                          onChange={(e) =>
                                            handleStartTimeChange(
                                              dayIndex,
                                              timingIndex,
                                              "end",
                                              e.target.value
                                            )
                                          }
                                          fullWidth
                                          disabled={
                                            !values?.availability?.[dayIndex]
                                              ?.isChecked
                                          }
                                          displayEmpty
                                        >
                                          <MenuItem value="" disabled>
                                            HH:MM
                                          </MenuItem>
                                          {x?.slots?.map((option: any) => (
                                            <MenuItem
                                              key={option}
                                              value={option}
                                            >
                                              {option}
                                            </MenuItem>
                                          ))}
                                        </Select>{" "}
                                      </Grid>
                                      <Grid
                                        item
                                        xs={4}
                                        style={{
                                          paddingLeft: "4rem",
                                          paddingTop: 0,
                                        }}
                                      >
                                        <FormControl>
                                          <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            name={`availability.${dayIndex}.timing.${timingIndex}.isPrivate`}
                                            onChange={handleRadioChange(
                                              `availability.${dayIndex}.timing.${timingIndex}.isPrivate`,
                                              formProps
                                            )}
                                            defaultValue={
                                              values?.availability?.[dayIndex]
                                                ?.timing?.[timingIndex]
                                                .isPrivate
                                            }
                                            sx={{ marginTop: "1rem" }}
                                          >
                                            <FormControlLabel
                                              value={true}
                                              control={<StyledRadio />}
                                              label={t("privateCoaching")}
                                              disabled={
                                                !values?.availability?.[
                                                  dayIndex
                                                ]?.isChecked
                                              }
                                            />
                                            {/* <FormControlLabel
                                              value={false}
                                              control={<StyledRadio />}
                                              label="Public Coaching"
                                              disabled={
                                                !values?.availability?.[
                                                  dayIndex
                                                ]?.isChecked
                                              }
                                            /> */}
                                          </RadioGroup>
                                        </FormControl>
                                      </Grid>
                                      {values?.availability?.[dayIndex]?.timing
                                        ?.length > 1 && (
                                        <Grid item xs={2}>
                                          <IconButton
                                            onClick={() => {
                                              timingArrayHelpers.remove(
                                                timingIndex
                                              );
                                            }}
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        </Grid>
                                      )}
                                    </Grid>
                                    {/* Timing level validation */}
                                    <FormHelperText>
                                      <span
                                        className="MuiFormHelperText-root Mui-error"
                                        style={{ color: "#d32f2f" }}
                                      >
                                        {touched?.availability?.[dayIndex]
                                          ?.timing?.[timingIndex]
                                          ? renderTimingError(
                                              dayIndex,
                                              timingIndex
                                            )
                                          : null}
                                      </span>
                                    </FormHelperText>
                                    {values?.availability?.[dayIndex]?.timing
                                      ?.length ===
                                      timingIndex + 1 && (
                                      <>
                                        <Divider style={{ marginTop: "1rem" }}>
                                          {" "}
                                          <AddCircleOutlineIcon
                                            onClick={() => {
                                              timingArrayHelpers.push({
                                                start: "",
                                                end: "",
                                                isPrivate: true,
                                                slots: [],
                                              });
                                            }}
                                            style={{ cursor: "pointer" }}
                                          />
                                        </Divider>
                                      </>
                                    )}
                                  </>
                                </div>
                              )
                            )}
                          </div>
                        )}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            })}
          </div>
        )}
      ></FieldArray>

      <Typography
        variant="body2"
        gutterBottom
        style={{ fontSize: "16px", color: "#727272", marginTop: "2rem" }}
      >
        <span style={{ color: "#333333", fontWeight: 500 }}>
          {t("setHolidays")}
        </span>
        {t("optional")}
      </Typography>

      <FieldArray
        name="holidays"
        render={(arrayHelpers: any) => (
          <div>
            {values?.holidays?.map((x: any, index: number) => {
              return (
                <div key={`holidays${index}`}>
                  <Grid
                    container
                    spacing={2}
                    style={{ marginTop: "10px" }}
                    alignItems={"baseline"}
                  >
                    <Grid item xs={4}>
                      <Typography
                        variant="body2"
                        gutterBottom
                        className="uppercase label"
                      >
                        {t("fromDate")}
                      </Typography>

                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={localStorage.getItem("language") || "en"}
                      >
                        <DatePicker
                          value={x?.from ? dayjs(x?.from) : null}
                          onChange={(newValue: any, neww: any) => {
                            const formattedDate: any =
                              dayjs(newValue).format("YYYY-MM-DD") +
                              "T00:00:00+05:30";
                            //const formattedDate = dayjs(newValue).toISOString();
                            formProps.setFieldValue(
                              `holidays.${index}.from`,
                              formattedDate
                            );
                          }}
                          format="DD/MM/YYYY"
                          open={datePickerOpen[index] || false}
                          onOpen={() => {
                            const newDatePickerOpen = [...datePickerOpen];
                            newDatePickerOpen[index] = true;
                            setDatePickerOpen(newDatePickerOpen);
                          }}
                          onClose={() => {
                            const newDatePickerOpen = [...datePickerOpen];
                            newDatePickerOpen[index] = false;
                            setDatePickerOpen(newDatePickerOpen);
                          }}
                          slotProps={{
                            textField: {
                              onClick: () => {
                                const newDatePickerOpen = [...datePickerOpen];
                                newDatePickerOpen[index] = true;
                                setDatePickerOpen(newDatePickerOpen);
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                      <ErrorMessage
                        name={`holidays.${index}.from`}
                        component="div"
                        className="error-message"
                        render={(msg) => (
                          <span
                            className="MuiFormHelperText-root Mui-error"
                            style={{
                              color: "#d32f2f",
                              marginRight: "10px",
                              marginTop: "10px",
                              fontSize: "0.75rem",
                            }}
                          >
                            {msg}
                          </span>
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant="body2"
                        className="uppercase label"
                        gutterBottom
                      >
                        {t("toDate")}
                      </Typography>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={localStorage.getItem("language") || "en"}
                      >
                        <DatePicker
                          value={x?.to ? dayjs(x?.to) : null}
                          onChange={(newValue: any) => {
                            const formattedDate: any =
                              dayjs(new Date(newValue)).format("YYYY-MM-DD") +
                              "T23:59:59+05:30";

                            //const formattedDate = dayjs(newValue).toISOString();

                            formProps.setFieldValue(
                              `holidays.${index}.to`,
                              formattedDate
                            );
                          }}
                          format="DD/MM/YYYY"
                          // open={datePickerToOpen}
                          // onOpen={() => setDatePickerToOpen(true)}
                          // onClose={() => setDatePickerToOpen(false)}
                          // slotProps={{
                          //   textField: {
                          //     onClick: () => setDatePickerToOpen(true),
                          //   },
                          // }}
                          open={datePickerToOpen[index] || false}
                          onOpen={() => {
                            const newDatePickerOpen = [...datePickerToOpen];
                            newDatePickerOpen[index] = true;
                            setDatePickerToOpen(newDatePickerOpen);
                          }}
                          onClose={() => {
                            const newDatePickerOpen = [...datePickerToOpen];
                            newDatePickerOpen[index] = false;
                            setDatePickerToOpen(newDatePickerOpen);
                          }}
                          slotProps={{
                            textField: {
                              onClick: () => {
                                const newDatePickerOpen = [...datePickerToOpen];
                                newDatePickerOpen[index] = true;
                                setDatePickerToOpen(newDatePickerOpen);
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                      <ErrorMessage
                        name={`holidays.${index}.to`}
                        component="div"
                        className="error-message"
                        render={(msg) => (
                          <span
                            className="MuiFormHelperText-root Mui-error"
                            style={{
                              color: "#d32f2f",
                              marginRight: "10px",
                              marginTop: "10px",
                              fontSize: "0.75rem",
                            }}
                          >
                            {msg}
                          </span>
                        )}
                      />
                    </Grid>
                    <Grid item xs={4} position={"relative"}>
                      {values?.holidays?.length !== 1 && (
                        <IconButton
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                          style={{ position: "absolute", top: "2rem" }}
                        >
                          <CloseIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>

                  {values.holidays.length === index + 1 && (
                    <Grid
                      container
                      spacing={2}
                      justifyContent={"center"}
                      style={{ marginTop: "10px" }}
                    >
                      <Grid item xs={8} textAlign={"center"}>
                        {" "}
                        <AddCircleOutlineIcon
                          onClick={() =>
                            arrayHelpers.insert(index + 1, {
                              from: null,
                              to: null,
                            })
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </Grid>
                      <Grid item xs={4}></Grid>
                    </Grid>
                  )}
                </div>
              );
            })}
          </div>
        )}
      ></FieldArray>
    </>
  );
};

export default Schedule;
