// src/redux/coachingReducer.ts
import { createSlice } from "@reduxjs/toolkit";

const organisationSlice = createSlice({
  name: "organisations",
  initialState: { data: [], credits: [], loading: false, error: null },
  reducers: {
    organisationsRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    organisationsReceived: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    // organisationsRequestFailed: (state, action) => {
    //   state.loading = false,      
    //   state.error = action.payload;
    // },
    updateOrganisationData: (state: any, action) => {
      const updatedData = state?.data?.data?.map((x: any) =>
        x?._id === action?.payload?._id
          ? { ...x, ...action.payload, updated: true }
          : x
      );

      return {
        ...state,
        loading: false,
        data: { ...state?.data, data: updatedData },
      };
    },
    singleOrganisationRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    singleOrganisationReceived: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    singleCreditsReceived: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    singleOrganisationRequestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    creditsRequested: (state) => {
      state.loading = true;
      state.error = null;
    },
    creditsReceived: (state, action) => {
      state.loading = false;
      state.credits = action.payload;
    },
    creditsRequestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateCreditsData: (state: any, action) => {
      const updatedData = state?.credits?.data?.map((x: any) =>
        x?._id === action?.payload?._id
          ? { ...x, ...action.payload, updated: true }
          : x
      );

      return {
        ...state,
        loading: false,
        data: { ...state?.data, data: updatedData },
      };
    },
    organisationsReset: (state) => {
      state.loading = false;
      state.data = [];
      state.credits = [];
    },
  },
});

export const {
  organisationsRequested,
  organisationsReceived,
  //organisationsRequestFailed,
  singleOrganisationRequested,
  singleOrganisationReceived,
  singleCreditsReceived,
  singleOrganisationRequestFailed,
  updateOrganisationData,
  updateCreditsData,
  creditsRequested,
  creditsReceived,
  creditsRequestFailed,
  organisationsReset
} = organisationSlice.actions;

export default organisationSlice.reducer;
