/* eslint-disable react-hooks/exhaustive-deps */
import { CouponType } from "app/config/constants";

import { forEach } from "lodash";

export const calculateAmount = (quantity: any, price: any) => {
  return parseFloat(price) * parseInt(quantity);
};

export const couponAppliedAfterCalculateTotal = (
  values: any,
  couponData: any,
  setFieldValue?: any
) => {
  const productsListLength = values?.products?.length;

  let manipulateData = values?.orderDetails?.map((element: any) => {
    return {
      ...element,
      orderList: element?.orderList?.map((element1: any) => {
        if (couponData?.type === CouponType.FLAT_OFF) {
          let couponValue: any = couponData?.value / productsListLength;
          couponValue = couponData?.value / element?.orderList?.length || 0;

          const subTotal =
            parseFloat(element1?.unitPrice) * parseInt(element1?.quantity) -
            parseFloat(couponValue);

          const taxPercentage = element?.taxPercentage;

          const taxAmount = taxPercentage
            ? subTotal * (taxPercentage / 100)
            : 0;

          return {
            ...element1,
            subTotal,
            taxAmount,
            totalAmount: taxAmount + subTotal,
          };
        } else {
          let couponPercentage: any = couponData?.value / productsListLength;

          couponPercentage = couponPercentage / element?.orderList?.length || 0;

          let reduceCouponAmount =
            (couponPercentage / 100) * element1?.unitPrice;

          const subTotal =
            parseFloat(element1?.unitPrice) * parseInt(element1?.quantity) -
            reduceCouponAmount;

          const taxPercentage = element?.taxPercentage;

          const taxAmount = taxPercentage
            ? subTotal * (taxPercentage / 100)
            : 0;

          return {
            ...element1,
            subTotal,
            taxAmount,
            totalAmount: taxAmount + subTotal,
          };
        }
      }),
    };
  });
  setFieldValue("couponOrderDetails", manipulateData);
};

export const changeSizeAndVariant = (
  selectedVal: any,
  product: any,
  index: number,
  sizeIndex: number,
  values: any,
  setFieldValue: any
) => {
  const selectedVariantObj = values?.orderDetails?.[index]?.variants?.find(
    (v: any) => v?._id === selectedVal
  );
  const { unitPrice, size } = selectedVariantObj;

  const quantity =
    values?.orderDetails?.[index]?.orderList?.[sizeIndex]?.quantity;

  setFieldValue(
    `orderDetails.${index}.orderList.${sizeIndex}.unitPrice`,
    unitPrice
  );

  setFieldValue(`orderDetails.${index}.orderList.${sizeIndex}.size`, size);

  setFieldValue(
    `orderDetails.${index}.orderList.${sizeIndex}.name`,
    product?.name || ""
  );
  setFieldValue(
    `orderDetails.${index}.orderList.${sizeIndex}.attachments`,
    product?.attachments || []
  );

  if (quantity && unitPrice) {
    const subTotal = calculateAmount(quantity, unitPrice);

    const taxPercentage = product?.taxPercentage;

    const taxAmount = taxPercentage ? subTotal * (taxPercentage / 100) : 0;

    const totalAmount = subTotal + taxAmount;

    setFieldValue(
      `orderDetails.${index}.orderList.${sizeIndex}.subTotal`,
      subTotal
    );
    setFieldValue(
      `orderDetails.${index}.orderList.${sizeIndex}.taxPercentage`,
      taxPercentage || 0
    );

    setFieldValue(
      `orderDetails.${index}.orderList.${sizeIndex}.taxAmount`,
      taxAmount || 0
    );

    setFieldValue(
      `orderDetails.${index}.orderList.${sizeIndex}.totalAmount`,
      totalAmount || 0
    );
  }
};

export const changeQuantity = (
  quantity: any,
  product: any,
  index: number,
  sizeIndex: number,
  values: any,
  setFieldValue: any
) => {
  let unitPrice =
    values?.orderDetails?.[index]?.orderList?.[sizeIndex]?.unitPrice || 0;

  if (!unitPrice) {
    unitPrice = values?.orderDetails?.[index]?.variants?.[0]?.unitPrice;
  }

  let subTotal = calculateAmount(quantity, unitPrice);

  const taxPercentage =
    values?.orderDetails?.[index]?.taxPercentage ||
    values?.orderDetails?.[index]?.orderList?.[sizeIndex]?.taxPercentage;

  const taxAmount = taxPercentage ? subTotal * (taxPercentage / 100) : 0;

  const totalAmount = subTotal + taxAmount;

  if (quantity) {
    setFieldValue(
      `orderDetails.${index}.orderList.${sizeIndex}.unitPrice`,
      unitPrice
    );

    setFieldValue(
      `orderDetails.${index}.orderList.${sizeIndex}.subTotal`,
      subTotal
    );

    setFieldValue(
      `orderDetails.${index}.orderList.${sizeIndex}.taxAmount`,
      taxAmount
    );

    setFieldValue(
      `orderDetails.${index}.orderList.${sizeIndex}.taxPercentage`,
      taxPercentage
    );

    setFieldValue(
      `orderDetails.${index}.orderList.${sizeIndex}.totalAmount`,
      totalAmount
    );
  }
};

export const calculateTotal = (values: any) => {
  let total = 0;
  values?.orderDetails?.forEach((element: any) => {
    element?.orderList?.forEach((element1: any) => {
      total += element1?.subTotal;
    });
  });

  return total || 0;
};

export const calculateSubtotalTax = (values: any, appliedCoupon?: any) => {
  //const newVariants: any = [];
  const couponManipulateOrderDetails: any = [];
  const manipulateOrderDetails: any = [];

  forEach(values?.orderDetails, (element: any) => {
    if (element?.orderList) {
      forEach(element.orderList, (element1: any) => {
        let newData = {
          ...element1,
          product: element?._id,
          quantity: parseInt(element1?.quantity),
          taxPercentage: element1?.taxPercentage || 0,
        };
        if (!element?.isMultipleVariantsAvailable && !element1?.variant) {
          newData.variant = element?.variants?.[0]?._id;
        }
        manipulateOrderDetails.push(newData);
      });
    }
  });

  // const productPriceToShow = minBy(
  //   newVariants,
  //   (item: any) => item?.unitPrice
  // )?.unitPrice;

  const holdActualResult = manipulateOrderDetails?.reduce(
    (accumulator: any, currentObject: any) => {
      accumulator.actualTotalOfSubTotalAmount += parseFloat(
        currentObject.subTotal
      );
      accumulator.actualTotalTaxAmount += parseFloat(currentObject.taxAmount);
      return accumulator;
    },
    {
      actualTotalOfSubTotalAmount: 0,
      actualTotalTaxAmount: 0,
    }
  );

  let result;
  //Coupon applied calculation
  if (appliedCoupon) {
    forEach(values?.couponOrderDetails, (element: any) => {
      if (element?.orderList) {
        forEach(element.orderList, (element1: any) => {
          let newData = {
            ...element1,
            product: element?._id,
            quantity: parseInt(element1?.quantity),
            taxPercentage: element1?.taxPercentage || 0,
          };
          if (!element?.isMultipleVariantsAvailable && !element1?.variant) {
            newData.variant = element?.variants?.[0]?._id;
          }
          couponManipulateOrderDetails.push(newData);
        });
      }
    });

    result = couponManipulateOrderDetails?.reduce(
      (accumulator: any, currentObject: any) => {
        accumulator.totalOfSubTotalAmount += parseFloat(currentObject.subTotal);
        accumulator.totalTaxAmount += parseFloat(currentObject.taxAmount);
        return accumulator;
      },
      {
        totalOfSubTotalAmount: 0,
        totalTaxAmount: 0,
      }
    );
  } else {
    result = manipulateOrderDetails?.reduce(
      (accumulator: any, currentObject: any) => {
        accumulator.totalOfSubTotalAmount += parseFloat(currentObject.subTotal);
        accumulator.totalTaxAmount += parseFloat(currentObject.taxAmount);
        return accumulator;
      },
      {
        totalOfSubTotalAmount: 0,
        totalTaxAmount: 0,
      }
    );
  }

  return {
    ...result,
    ...holdActualResult,
    actualTotalPayableAmount:
      parseFloat(holdActualResult?.actualTotalOfSubTotalAmount || 0) +
      parseFloat(holdActualResult?.actualTotalTaxAmount || 0),
    totalPayableAmount: appliedCoupon
      ? parseFloat(result?.totalOfSubTotalAmount) +
        parseFloat(result?.totalTaxAmount)
      : calculateTotal(values) + parseFloat(result?.totalTaxAmount),
  };
};
