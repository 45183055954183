import COUNTRY_CODES from "../data/CountryCodes.json";

const getCountryNameByDialCode = (dialCode: string): string | undefined => {
  const country = COUNTRY_CODES?.find(
    (c: any) => c?.dial_code === Number(dialCode)
  );
  return country ? country?.name : undefined;
};

export default getCountryNameByDialCode;
