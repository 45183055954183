export interface IGeneralInformation {
  name: string;
  category: string;
  price: number | null;
  status: string;
  numberOfCoachingSessions: number | null;
  productDetails: string;
}

export const defaultGeneralInformationValue: Readonly<IGeneralInformation> = {
  name: "",
  category: "",
  price: null,
  status: "active",
  numberOfCoachingSessions: null,
  productDetails: "",
};
