// src/redux/store.ts
import { configureStore } from "@reduxjs/toolkit";
import clubsReducer from "./clubsReducer";
import usersReducer from "./usersReducer";
import bookingsReducer from "./bookingsReducer";
import coachingReducer from "./coachingReducer";
import couponsReducer from "./couponsReducer";
import dashboardReducer from "./dashboardReducer";
import feedReducer from "./feedReducer";
import membershipsReducer from "./membershipsReducer";
import tournamentsReducer from "./tournamentsReducer";
import sessionReducer from "./sessionReducer";
import sessionPackagesReducer from "./sessionPackagesReducer";
import productsReducer from "./productsReducer";
import extrasReducer from "./extrasReducer";
import tagsReducer from "./tagsReducer";
import configurationsReducer from "./configurationsReducer";
import organisationsReducer from "./organisationsReducer";

const store = configureStore({
  reducer: {
    clubs: clubsReducer,
    clubsFeatures: clubsReducer,
    users: usersReducer,
    bookings: bookingsReducer,
    extras: extrasReducer,
    coaching: coachingReducer,
    coupons: couponsReducer,
    dashboard: dashboardReducer,
    feeds: feedReducer,
    memberships: membershipsReducer,
    tournaments: tournamentsReducer,
    sessions: sessionReducer,
    sessionPackages: sessionPackagesReducer,
    products: productsReducer,
    tags: tagsReducer,
    configurations: configurationsReducer,
    organisations: organisationsReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
