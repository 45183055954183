import React from "react";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import COUNTRY_CODES from "../../../../shared/data/CountryCodes.json";
import { GooglePlacesAutoComplete } from "app/shared/molecules/GooglePlacesAutoComplete";
import { FormTextField, FormSelectField } from "app/shared/atoms";
import { sortBy } from "lodash";

interface GeneralInformationProps {
  features?: any;
  type?: any;
  //generalInformationData?: any;
  //handleChange?: any;
  hasValues?: boolean;
  setHasValues?: any;
  //setGeneralInformationData?: any;
  action?: any;
  formProps?: any;
  categories?: any;
}

const featureName = (feature: any): string => {
  const language = localStorage.getItem("language");
  return (
    (language === "fr" && feature?.fr_title) ||
    (language === "es" && feature?.sp_title) ||
    feature?.title ||
    ""
  );
};

const GeneralInformation: React.FC<GeneralInformationProps> = ({
  features,
  formProps,
  categories,
}) => {
  const { errors, values, touched, setFieldValue } = formProps;
  const featureList = [...features];
  const { t } = useTranslation();

  const businessOptions = [
    { label: `${t("padelClub")}`, value: "padel" },
    { label: `${t("tennisClub")}`, value: "tennis" },
    { label: `${t("sportsClub")}`, value: "sports" },
  ];

  const handleFeaturesAvailableChange = (event: SelectChangeEvent<any>) => {
    const selectedFeatureId = event?.target?.value as string[];
    setFieldValue("features", selectedFeatureId);
  };

  return (
    <div>
      <FormTextField
        name="name"
        label={t("clubName")}
        customCallback={(value) => {
          const capitalizedValue =
            value?.charAt(0)?.toUpperCase() + value?.slice(1);
          setFieldValue("name", capitalizedValue);
        }}
        value={values?.name}
      />

      <Box sx={{ marginTop: "1rem", marginBottom: "1.2rem" }}>
        <Typography
          gutterBottom
          className="uppercase label"
          sx={{ marginBottom: "1rem" }}
        >
          {t("address")}
        </Typography>

        <GooglePlacesAutoComplete
          defaultValue={values?.address1}
          callback={(data: any) => {
            setFieldValue("city", data?.city || "");
            setFieldValue("state", data?.state || "");
            setFieldValue("country", data?.country || "");
            setFieldValue("address1", data?.address1 || "");
            setFieldValue("location", data?.location || "");
          }}
          error={errors?.address1 && touched?.address1 ? errors?.address1 : ""}
        />
      </Box>

      <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
        <Grid item xs={4}>
          <FormTextField fullWidth label={t("city")} name="city" />
        </Grid>
        <Grid item xs={4}>
          <FormTextField fullWidth label={t("state")} name="state" />
        </Grid>
        <Grid item xs={4}>
          <FormTextField fullWidth label={t("country")} name="country" />
        </Grid>
      </Grid>
      <FormTextField name="email" label={t("email")} />

      <Grid container spacing={2}>
        <Grid item xs={5} style={{ marginTop: "18px" }}>
          <FormSelectField
            label={t("phone")}
            name="countryCode"
            options={COUNTRY_CODES?.map(
              (x: any) =>
                (x = {
                  ...x,
                  label: x?.name,
                  value: x?.name,
                })
            )}
            value={values?.countryCode}
            defaultValue={values?.countryCode}
            customWidth="240"
          />
        </Grid>
        <Grid item xs={7}>
          <FormControl fullWidth sx={{ marginTop: "3.4rem" }}>
            <FormTextField name="phone" />
          </FormControl>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: "1rem", marginBottom: "1.5rem" }}>
        <FormControl fullWidth>
          <FormSelectField
            label={t("businessType")}
            name="type"
            options={businessOptions}
            value={values?.type}
          />
        </FormControl>
      </Box>
      <FormControl fullWidth style={{ marginTop: "0px" }}>
        <FormSelectField
          label={t("category")}
          name="category"
          options={sortBy(categories, "name")
            ?.filter(
              (x: any) => x?.status === "active" || values?.category === x?._id
            )
            .map((category: any) => ({
              ...category,
              label: category.name,
              value: category._id,
            }))}
          value={values?.category ? values?.category : undefined}
        />
      </FormControl>
      <FormControl fullWidth>
        <Typography
          variant="body2"
          gutterBottom
          style={{ marginTop: "15px" }}
          className="uppercase label"
        >
          {t("featuresAvailable")}
        </Typography>
        <Select
          name="features"
          value={values?.features || []}
          onChange={handleFeaturesAvailableChange}
          style={{ marginTop: "15px" }}
          multiple={true}
        >
          {Array.isArray(featureList) && featureList?.length !== 0 ? (
            featureList
              ?.sort((a, b) => a?.title?.localeCompare(b.title))
              ?.map((feature: any) => (
                <MenuItem key={feature._id} value={feature._id}>
                  <Checkbox
                    checked={
                      Array.isArray(values?.features) &&
                      values?.features?.includes(feature._id)
                    }
                  />
                  {featureName(feature)}
                </MenuItem>
              ))
          ) : (
            <MenuItem value="No Data">{t("noData")}</MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
};

export default GeneralInformation;
