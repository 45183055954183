/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import {
  Modal,
  Box,
  Button,
  Divider,
  Paper,
  Slide,
  Grid,
  Typography,
  IconButton,
  FormControl,
  Stack,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/es"; // Import the Spanish locale
import "dayjs/locale/fr"; // Import the French locale
import "dayjs/locale/en"; // Import the English locale

import { Form, Formik, ErrorMessage, FormikProps } from "formik";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import { AppDispatch } from "../../../redux/store";
import { defaultGeneralInformationValue } from "../../../model/coupon-model";
import { FormTextField, FormSelectField } from "app/shared/atoms";
import {
  createCoupon,
  updateCoupon,
  fetchCouponsList,
} from "../couponsApiService";
import { useAuth } from "../../../../AuthContext";
import { CouponType, CouponUse } from "app/config/constants";
import { ClubsMultiSelect } from "app/shared/molecules";
import { AntSwitch } from "app/shared/components/BlackSwitch";
import { enumtoArrayConverter } from "app/shared/util/common";
import { AddCouponValidationSchema } from "app/shared/validations/CouponValidationSchema";
import ConfirmDialog from "app/shared/components/ConfirmDialog"; 
import { SubmitButton } from "app/shared/molecules";

interface ModalFormProps {
  open: boolean;
  onClose: () => void;
  formData?: any;
  callback?: any;
  action?: any;
}

const brownButtonStyle = {
  backgroundColor: "#333333",
  color: "white", // Text color
};

const BoldTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold", // Set the font weight to bold
}));

const AddCouponModalForm: React.FC<ModalFormProps> = ({
  open,
  onClose,
  formData,
  callback,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const formikRef = useRef<FormikProps<any>>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [editData, setEditData] = useState<any>();
  const { user, profileData } = useAuth();
  const [datePickerFromOpen, setDatePickerFromOpen] = useState(false);
  const [datePickerToOpen, setDatePickerToOpen] = useState(false);

  // Set all the form data used for building the request object to the API finally
  useEffect(() => {
    if (formData?._id) {
      setEditData({
        ...formData,
        clubs: formData?.clubs?.map((x: any) => x?._id || x),
      });
    }
  }, [formData]);

  /**
   * Handle Next on button click to load the next step if second page call the api
   *
   * @function
   * @returns void
   */
  const handleSubmit = async (values?: any, actions?: any) => {
    try {
      if (formData?._id) {
        delete values._id; 
          const response = await dispatch(updateCoupon(formData?._id, { ...values }));
  
        if (response) {
          if (typeof callback === "function") {
            callback(); 
          }
            onClose();
        }
      } else {
        const response = await dispatch(createCoupon({ ...values }));
        if (response) {
          onClose(); 
          dispatch(fetchCouponsList({ page: 1 })); 
        }
      }
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      actions.setSubmitting(false); 
    }
  };

  /**
   * Handle close on button click
   *
   * @function
   * @returns void
   */
  const handleCloseModal = (event?: any, reason?: any) => {
    if (formikRef?.current?.dirty) {
      // Show confirmation dialog if there are unsaved changes
      setConfirmOpen(true);
    } else {
      onClose();
    }
  };

    // Handle close confirmation dialog
    const handleCancelClose = () => {
      setConfirmOpen(false);
    };
  
    // Handle confirm close action
    const handleConfirmClose = () => {
      setConfirmOpen(false);
      onClose();
    };

  const handleDateChange =
    (objectKey: string, setFieldValue: any) => (date: Dayjs | null) => {
      if (date) {
        const formattedDate = dayjs(date).format("YYYY-MM-DD");
        setFieldValue(objectKey, formattedDate);
      } else {
        setFieldValue(objectKey, null);
      }
    };

  const handleSwitchChange = (
    objectKey: string,
    checked: boolean,
    setFieldValue: any
  ) => {
    setFieldValue(`${objectKey}`, checked ? "active" : "inactive");
  };

  return (
    <><Modal
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: "rgba(0,0,0,0.5)",
          },
        },
      }}
    >
      <Slide in={open} direction="left">
        <Paper
          style={{
            position: "absolute",
            right: "0",
            transform: "translateY(-50%)",
            width: "35%",
            padding: "25px",
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Box>
            {/* Content of the sliding modal */}
            <BoldTypography variant="h6" style={{ marginBottom: "1rem" }}>
              {formData?._id ? t("editCoupon") : t("addNewCoupon")}
            </BoldTypography>
            <Divider />
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
              sx={{
                position: "absolute",
                top: "15px",
                right: "24px",
                cursor: "pointer",
              }}
            >
              <CloseIcon />
            </IconButton>
            <div>
              <Formik
              innerRef={formikRef}
                initialValues={
                  formData?._id
                    ? editData
                    : profileData?.userType === "club_admin"
                    ? {
                        ...defaultGeneralInformationValue,
                        clubs: profileData?.clubs || [],
                      }
                    : defaultGeneralInformationValue
                }
                onSubmit={(values, actions) => {
                  let newValue = {
                    ...values,
                    value: parseFloat(values?.value) || 0,
                    usageCount: parseInt(values?.usageCount) || 0,
                    clubs: values?.clubs || [],
                    createdBy: user?.userId,
                  };
                  if (
                    newValue?.cycles === null ||
                    newValue?.cycles === undefined ||
                    newValue?.cycles === ""
                  ) {
                    delete newValue.cycles;
                  } else if (newValue?.cycles) {
                    newValue = {
                      ...newValue,
                      cycles: parseInt(newValue?.cycles),
                    };
                  } else if (formData?.cycles && !newValue?.cycles) {
                    newValue.cycles = 0;
                  }
                  handleSubmit(newValue, actions);
                }}
                enableReinitialize
                validationSchema={AddCouponValidationSchema(
                  profileData?.userType
                )}
              >
                {({ values, setFieldValue, errors, touched, isSubmitting }: any) => {
                  return (
                    <>
                      <Form>
                        <Grid
                          container
                          spacing={2}
                          style={{ marginTop: "10px" }}
                        >
                          {" "}
                          <Grid item xs={12}>
                            <FormTextField
                              name="name"
                              label={t("couponName")}
                              fullWidth
                              disabled={formData?._id}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormSelectField
                              label={t("type")}
                              name="type"
                              options={enumtoArrayConverter(CouponType)}
                              disabled={formData?._id}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormTextField
                              name="value"
                              label={t("value")}
                              placeholder="Enter value"
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {CouponType.PERCANTAGE === values?.type
                                      ? `%`
                                      : `€`}
                                  </InputAdornment>
                                ),
                              }}
                              showPercentageSymbol={
                                CouponType.PERCANTAGE === values?.type
                              }
                              showPriceSymbol={
                                CouponType.FLAT_OFF === values?.type
                              }
                              disabled={formData?._id}
                            />
                          </Grid>
                          {/* <Grid item xs={12}>
                            <FormSelectField
                              label="Category"
                              name="category"
                              placeHolder="Select category"
                              options={enumtoArrayConverter(CouponCategory)}
                            />
                          </Grid> */}
                          <Grid item xs={12}>
                            <FormSelectField
                              label={t("couponUse")}
                              name="couponUse"
                              placeHolder="Select use"
                              options={enumtoArrayConverter(CouponUse)?.map(
                                (x: any) =>
                                  (x = {
                                    ...x,
                                    label:
                                      x?.value === "renting"
                                        ? "Eligible for Extras"
                                        : "Eligible for Membership",
                                  })
                              )}
                              disabled={formData?._id}
                            />
                          </Grid>
                          {CouponUse.ELIGIBLE_FOR_MEMBERSHIP ===
                            values?.couponUse && (
                            <Grid item xs={12}>
                              <FormTextField
                                name="cycles"
                                placeholder="Enter billing cycles"
                                label={`${t("allowedNumberOfCycles")} ${t(
                                  "optional"
                                )}`}
                                fullWidth
                              />
                            </Grid>
                          )}{" "}
                          <Grid item xs={12}>
                            <FormTextField
                              name="usageCount"
                              placeholder="Enter usage count"
                              label={t("couponUsageCount")}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                {" "}
                                <Typography
                                  sx={{
                                    flex: 1,
                                    fontSize: "12px",
                                    textTransform: "uppercase",
                                  }}
                                  className="label"
                                >
                                  {t("fromDate")}
                                </Typography>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  adapterLocale={
                                    localStorage.getItem("language") || "en"
                                  }
                                >
                                  <FormControl
                                    fullWidth
                                    style={{ marginTop: "10px" }}
                                  >
                                    <DatePicker
                                      onChange={handleDateChange(
                                        "fromDate",
                                        setFieldValue
                                      )}
                                      format="DD/MM/YYYY"
                                      value={
                                        values?.fromDate
                                          ? dayjs(
                                              values?.fromDate,
                                              "YYYY-MM-DD"
                                            )
                                          : null
                                      }
                                      open={datePickerFromOpen}
                                      onOpen={() => setDatePickerFromOpen(true)}
                                      onClose={() =>
                                        setDatePickerFromOpen(false)
                                      }
                                      slotProps={{
                                        textField: {
                                          onClick: () =>
                                            setDatePickerFromOpen(true),
                                        },
                                      }}
                                    />
                                  </FormControl>
                                </LocalizationProvider>
                                <ErrorMessage
                                  name={`fromDate`}
                                  component="div"
                                  className="error-message"
                                  render={(msg: any) => (
                                    <span
                                      className="MuiFormHelperText-root Mui-error"
                                      style={{
                                        color: "#d32f2f",
                                        marginRight: "10px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {msg}
                                    </span>
                                  )}
                                />
                              </Grid>{" "}
                              <Grid item xs={6}>
                                {" "}
                                <Typography
                                  sx={{
                                    flex: 1,
                                    fontSize: "12px",
                                    textTransform: "uppercase",
                                  }}
                                  className="label"
                                >
                                  {t("toDate")}
                                </Typography>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  adapterLocale={
                                    localStorage.getItem("language") || "en"
                                  }
                                >
                                  <FormControl
                                    fullWidth
                                    style={{
                                      marginTop: "10px",
                                    }}
                                  >
                                    <DatePicker
                                      onChange={handleDateChange(
                                        "toDate",
                                        setFieldValue
                                      )}
                                      format="DD/MM/YYYY"
                                      value={
                                        values?.toDate
                                          ? dayjs(values?.toDate, "YYYY-MM-DD")
                                          : null
                                      }
                                      open={datePickerToOpen}
                                      onOpen={() => setDatePickerToOpen(true)}
                                      onClose={() => setDatePickerToOpen(false)}
                                      slotProps={{
                                        textField: {
                                          onClick: () =>
                                            setDatePickerToOpen(true),
                                        },
                                      }}
                                    />
                                  </FormControl>
                                </LocalizationProvider>
                                <ErrorMessage
                                  name={`toDate`}
                                  component="div"
                                  className="error-message"
                                  render={(msg: any) => (
                                    <span
                                      className="MuiFormHelperText-root Mui-error"
                                      style={{
                                        color: "#d32f2f",
                                        marginRight: "10px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {msg}
                                    </span>
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Box style={{ marginBottom: "25px" }}>
                              <ClubsMultiSelect
                                label="Club"
                                multiple={true}
                                value={values?.clubs ? values?.clubs : []} // Pass the value from Formik's state
                                onChange={(clubs: any) => {
                                  setFieldValue("clubs", clubs);
                                }}
                                error={touched?.clubs && errors?.clubs}
                              />
                              {touched?.clubs && errors?.clubs && (
                                <ErrorMessage
                                  name={`clubs`}
                                  component="div"
                                  className="error-message"
                                  render={(msg: any) => (
                                    <span
                                      className="MuiFormHelperText-root Mui-error"
                                      style={{
                                        color: "#d32f2f",
                                        marginRight: "10px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {errors?.clubs}
                                    </span>
                                  )}
                                />
                              )}
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sx={{ marginTop: "1rem", marginBottom: "2rem" }}
                        >
                          <Grid
                            container
                            spacing={2}
                            justifyContent={"space-between"}
                          >
                            <Grid
                              item
                              xs={6}
                              className="label uppercase"
                              sx={{ fontSize: "15px" }}
                            >
                              {t("couponStatus")}
                            </Grid>
                            <Grid item xs={6}>
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                sx={{ float: "right" }}
                              >
                                <Typography sx={{ fontSize: "12px" }}>
                                  Inactive
                                </Typography>
                                <AntSwitch
                                  checked={values?.status === "active"}
                                  onChange={(e) => {
                                    handleSwitchChange(
                                      "status",
                                      e.target.checked,
                                      setFieldValue
                                    );
                                  }}
                                />
                                <Typography sx={{ fontSize: "12px" }}>
                                  Active
                                </Typography>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            style={{ marginTop: "15px" }}
                          >
                            <Grid
                              item
                              xs={12}
                              container
                              justifyContent="flex-end"
                            >
                              <SubmitButton
                                type="submit"
                                disabled={isSubmitting}
                              >
                                {formData?._id
                                  ? t("saveChanges")
                                  : t("saveNewCoupon")}
                              </SubmitButton>
                            </Grid>
                          </Grid>
                        </div>
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </div>
          </Box>
        </Paper>
      </Slide>
    </Modal>
    
    {/* Confirmation dialog for unsaved changes */}
    <ConfirmDialog
        open={confirmOpen}
        onClose={handleCancelClose}
        onConfirm={handleConfirmClose}
      />
    </>
  );
};

export default AddCouponModalForm;
