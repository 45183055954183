import React, { useState, useEffect } from "react";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Divider,
  Radio,
  RadioGroup,
  InputLabel,
} from "@mui/material";

import FormHelperText from "@mui/material/FormHelperText";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import StyledCustomTextField from "app/shared/components/StyledTextField";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  policyHours,
  categoryBySex,
  enrolmentType,
  features,
  tournamentVisibility,
  tournamentType,
} from "app/config/constants";
import FormGroup from "@mui/material/FormGroup";
import "dayjs/locale/es"; // Import the Spanish locale
import "dayjs/locale/fr"; // Import the French locale
import "dayjs/locale/en"; // Import the English locale

dayjs.extend(utc);
dayjs.extend(timezone);

interface GeneralInformationProps {
  generalInformationData?: any;
  handleChange?: any;
  hasValues?: boolean;
  setHasValues?: any;
  setGeneralInformationData?: any;
  action?: any;
  formProps?: any;
  clubsList?: any;
}

const GeneralInformation: React.FC<GeneralInformationProps> = ({
  formProps,
  clubsList,
  action,
  generalInformationData,
}) => {
  const { values, errors, touched, setFieldValue } = formProps;
  const [dateError, setDateError] = React.useState(false);
  const [regDateError, setRegDateError] = React.useState(false);
  // State to hold the initial features data from the API response
  const [initialFeatures, setInitialFeatures] = useState<string[]>([]);
  const { t } = useTranslation();
  const [settingsData, setSettingsData] = useState<any>(null);
  const [datePickerStartOpen, setDatePickerStartOpen] = React.useState(false);
  const [datePickerEndOpen, setDatePickerEndOpen] = React.useState(false);
  const [datePickerDeadlineOpen, setDatePickerDeadlineOpen] =
    React.useState(false);

  useEffect(() => {
    const localSettings = localStorage.getItem("settings");
    if (localSettings) {
      setSettingsData(JSON.parse(localSettings));
    }
  }, []);

  // Determine the default cancellation period based on the form mode
  let defaultCancellationPeriod = "";
  if (action === "edit") {
    // During edit mode, set the default value to the existing cancellation period
    defaultCancellationPeriod =
      values?.cancellationPeriodInHours ||
      settingsData?.timeLimitToCancelTournament ||
      "";
  } else {
    // During create mode, use the settings data
    defaultCancellationPeriod = settingsData?.timeLimitToCancelTournament || "";
  }

  let genderValue = "";
  if (action === "edit" && generalInformationData?.gender) {
    formProps.setFieldValue("gender", generalInformationData?.gender);
    genderValue = values?.gender || generalInformationData?.gender;
  }

  useEffect(() => {
    // Check if generalInformationData and its features field exist
    if (generalInformationData && generalInformationData?.features) {
      // Filter features array to match with generalInformationData.features
      const matchedFeatures = generalInformationData?.features?.filter(
        (feature: any) => features?.includes(feature)
      );
      // Set the initialFeatures state with matched features
      setInitialFeatures(matchedFeatures);
    }
  }, [generalInformationData]);

  const handleOnChange = (objectKey: string) => (e: any) => {
    const inputValue = e.target.value;
    if (objectKey === "fee") {
      if (/^$|^\d*\.?\d{0,2}$/.test(inputValue)) {
        formProps.setFieldValue(`${objectKey}`, inputValue || "");
      }
    } else if (objectKey === "name") {
      const capitalizedValue =
        inputValue?.charAt(0)?.toUpperCase() + inputValue?.slice(1);
      formProps.setFieldValue("name", capitalizedValue);
    } else {
      formProps.setFieldValue(`${objectKey}`, e?.target?.value || "");
    }
  };

  const handleDropdownChange = (objectKey: string) => (e: any) => {
    const selectedValue = e?.target?.value;
    formProps.setFieldValue(objectKey, selectedValue);
    // If the dropdown is for clubs, set the first selected club id as the value
    if (objectKey === "clubs") {
      const selectedClubId = selectedValue;
      formProps.setFieldValue("clubs", selectedClubId);
      formProps.setFieldValue(
        "attachments",
        clubsList?.find((club: any) => club._id === selectedValue)?.attachments
      );
    } else if (objectKey === "tournamentVisibility") {
      if (e?.target?.value === "private") {
        formProps.setFieldValue("isPublic", false);
      } else {
        formProps.setFieldValue("isPublic", true);
      }
    }
  };

  const parseDateWithEndFormat = (d: any) => {
    // Parse the end date to a Date object
    let endDateObj = new Date(d);

    // Set the time to 23:59:59.999
    endDateObj.setUTCHours(23, 59, 59, 999);

    // Update the payload with the new formatted end date
    return endDateObj.toISOString();
  };

  const handleDateChange = async (date: Date | null, fieldName: string) => {
    if (date) {
      if (!dayjs.isDayjs(date)) {
        // Handle the case where value is not a valid dayjs object
        return false; // or handle the error appropriately
      }
      // Get the date for one day later
      let formattedDate;
      if (fieldName === "endDate" || fieldName === "registrationDeadline") {
        formattedDate = parseDateWithEndFormat(
          dayjs.utc(date).endOf("day").format()
        );
      } else {
        formattedDate = dayjs.utc(date).startOf("day").format();
      }
      setFieldValue(fieldName, formattedDate);
    }
  };

  // Function to check if endDate is higher than startDate
  const validateDates = () => {
    if (values?.startDate && values?.endDate) {
      const startDate = new Date(values?.startDate);
      const endDate = new Date(values?.endDate);
      const regDeadline = new Date(values?.registrationDeadline);
      if (endDate < startDate) {
        setDateError(true);
      } else {
        setDateError(false);
      }

      // Use isAfter method to check if the registration deadline is after the start and end dates
      if (regDeadline <= startDate || regDeadline < endDate) {
        // If registration deadline is after start date and before end date, remove the error
        setRegDateError(false);
      } else {
        // Otherwise, show error
        setRegDateError(true);
      }
    }
  };
  // Call validateDates whenever startDate or endDate changes
  useEffect(() => {
    validateDates();
  }, [values?.startDate, values?.endDate, values?.registrationDeadline]);

  const handleRadioChange =
    (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (fieldName === "isLevelRestriction") {
        if (event.target.value === "Yes") {
          formProps.setFieldValue(fieldName, true);
        } else {
          formProps.setFieldValue(fieldName, false);
        }
      } else if (fieldName === "gender") {
        if (event.target.value === categoryBySex?.MALE) {
          formProps.setFieldValue(fieldName, categoryBySex?.MALE);
        } else if (event.target.value === categoryBySex?.FEMALE) {
          formProps.setFieldValue(fieldName, categoryBySex?.FEMALE);
        } else {
          formProps.setFieldValue(fieldName, categoryBySex?.ALL);
        }
      } else {
        formProps.setFieldValue(fieldName, event.target.value);
      }
    };

  const handleCheckboxChange =
    (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event?.target?.checked;
      const labelId = event?.target?.value;

      // Check if features exists and is an array, otherwise initialize it as an empty array
      const currentFeatures = Array?.isArray(values?.features)
        ? values?.features
        : [];

      let newOptionalResources;

      if (isChecked) {
        // Add the selected value to the features array
        newOptionalResources = labelId
          ? [...currentFeatures, labelId]
          : [...currentFeatures];
      } else {
        // Remove the unselected value from the features array
        newOptionalResources = currentFeatures?.filter(
          (id: any) => id !== labelId
        );
      }

      // Set the new array of optional resources to the features field
      formProps.setFieldValue("features", newOptionalResources);
    };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Typography
              variant="body2"
              className="uppercase label"
              gutterBottom
            >
              {t("tournamentName")}
            </Typography>
            <StyledCustomTextField
              fullWidth
              name="name"
              value={values?.name}
              onChange={handleOnChange("name")}
              margin="normal"
              error={errors?.name}
              helperText={errors?.name}
              style={{ marginTop: "0px" }}
              autoComplete="off"
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: "-5px" }}>
        <Grid item xs={6}>
          <Typography variant="body2" className="uppercase label" gutterBottom>
            {t("startDate")}
          </Typography>
          {/* Date Picker */}
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={localStorage.getItem("language") || "en"}
          >
            <FormControl fullWidth>
              <DatePicker
                value={values?.startDate ? dayjs(values?.startDate) : null}
                timezone="UTC"
                onChange={(newValue: any) => {
                  setFieldValue("startDate", newValue);
                  handleDateChange(newValue, "startDate"); // Assuming handleDateChange expects a Date object
                }}
                format="DD/MM/YYYY"
                minDate={dayjs?.utc()?.add(5, "day")}
                disablePast
                open={datePickerStartOpen}
                onOpen={() => setDatePickerStartOpen(true)}
                onClose={() => setDatePickerStartOpen(false)}
                slotProps={{
                  textField: {
                    onClick: () => setDatePickerStartOpen(true),
                  },
                }}
              />
            </FormControl>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" className="uppercase label" gutterBottom>
            {t("endDate")}
          </Typography>
          {/* Date Picker */}
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={localStorage.getItem("language") || "en"}
          >
            <FormControl fullWidth error={dateError}>
              <DatePicker
                value={values?.endDate ? dayjs(values?.endDate) : null}
                timezone="UTC"
                onChange={(newValue: any) => {
                  setFieldValue("endDate", newValue);
                  handleDateChange(newValue, "endDate");
                }}
                format="DD/MM/YYYY"
                minDate={
                  values?.startDate
                    ? dayjs(values?.startDate).utc()
                    : dayjs?.utc()?.add(5, "day")
                }
                disablePast
                open={datePickerEndOpen}
                onOpen={() => setDatePickerEndOpen(true)}
                onClose={() => setDatePickerEndOpen(false)}
                slotProps={{
                  textField: {
                    onClick: () => setDatePickerEndOpen(true),
                  },
                }}
              />
              {dateError && (
                <FormHelperText>
                  <span
                    className="MuiFormHelperText-root Mui-error"
                    style={{ color: "#d32f2f", marginLeft: "1rem" }}
                  >
                    End date must be higher than the start date
                  </span>
                </FormHelperText>
              )}{" "}
              {/* Display validation error */}
            </FormControl>
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginTop: "5px" }}>
        <Grid item xs={12}>
          <Typography variant="body2" className="uppercase label" gutterBottom>
            {t("registeredDeadline")}
          </Typography>
          {/* Date Picker */}
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={localStorage.getItem("language") || "en"}
          >
            <FormControl fullWidth error={regDateError}>
              <DatePicker
                value={
                  values?.registrationDeadline
                    ? dayjs(values?.registrationDeadline)
                    : null
                }
                timezone="UTC"
                onChange={(newValue: any) => {
                  setFieldValue("registrationDeadline", newValue);
                  handleDateChange(newValue, "registrationDeadline");
                }}
                format="DD/MM/YYYY"
                minDate={dayjs
                  .utc(values?.startDate)
                  .subtract(
                    settingsData?.timeLimitToParticipateTournament,
                    "day"
                  )}
                maxDate={dayjs.utc(values?.endDate)}
                disablePast
                open={datePickerDeadlineOpen}
                onOpen={() => setDatePickerDeadlineOpen(true)}
                onClose={() => setDatePickerDeadlineOpen(false)}
                slotProps={{
                  textField: {
                    onClick: () => setDatePickerDeadlineOpen(true),
                  },
                }}
              />
              {regDateError && (
                <FormHelperText>
                  <span
                    className="MuiFormHelperText-root Mui-error"
                    style={{ color: "#d32f2f", marginLeft: "1rem" }}
                  >
                    Registration Deadline must be less than or equal to
                    startDate and less than endDate
                  </span>
                </FormHelperText>
              )}{" "}
            </FormControl>
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: "5px" }}>
        <Grid item xs={12}>
          <Typography variant="body2" className="uppercase label" gutterBottom>
            {t("tournamentType")}
          </Typography>
          <Select
            name="type"
            value={values?.type ? values?.type : ""}
            onChange={handleDropdownChange("type")}
            defaultValue=""
            fullWidth
            error={Boolean(errors?.type)} // Set error prop based on whether there's an error
          >
            {Array.isArray(tournamentType) && tournamentType?.length !== 0 ? (
              tournamentType?.map((type: any) => (
                <MenuItem key={type?.label} value={type?.label}>
                  {type?.label !== "" ? type?.label : t("noName")}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="No Data">{t("noData")}</MenuItem>
            )}
          </Select>
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginTop: "5px" }}>
        <Grid item xs={12}>
          <Typography variant="body2" className="uppercase label" gutterBottom>
            {t("participationFee")}
          </Typography>
          {/* Date Picker */}
          <StyledCustomTextField
            fullWidth
            name="fee"
            value={values?.fee}
            onChange={handleOnChange("fee")}
            onFocus={(e: any) => {
              if (e.target.value === "0") {
                e.target.value = "";
                handleOnChange("fee")(e); // Update state
              }
            }}
            margin="normal"
            error={errors?.fee}
            helperText={errors?.fee}
            style={{ marginTop: "0px" }}
            autoComplete="off"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: "-5px" }}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Typography
              variant="body2"
              className="uppercase label"
              gutterBottom
            >
              {t("venueClubs")}
            </Typography>
            <Select
              name="clubs"
              value={values?.clubs ? values?.clubs : ""}
              onChange={handleDropdownChange("clubs")}
              defaultValue=""
              error={Boolean(errors?.clubs)}
              renderValue={(selected) => {
                if (Array.isArray(selected)) {
                  return selected
                    .map((selectedValue) => {
                      const club = clubsList?.find(
                        (club: any) => club._id === selectedValue
                      );
                      return club?.name || t("noName");
                    })
                    .join(", ");
                }
                // For single selection, find the club name based on the ID
                const club = clubsList?.find(
                  (club: any) => club._id === selected
                );
                return club?.name || selected || t("noData");
              }}
            >
              {Array.isArray(clubsList) && clubsList?.length !== 0 ? (
                clubsList
                  ?.filter((x: any) => x?.status === "active")
                  ?.map((club: any) => (
                    <MenuItem key={club?._id} value={club?._id}>
                      <Checkbox
                        checked={
                          Array.isArray(values?.club) &&
                          values?.club.includes(club?._id)
                        }
                      />
                      {club?.name !== "" ? club?.name : t("noName")}
                    </MenuItem>
                  ))
              ) : (
                <MenuItem value="No Data">{t("noData")}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {/* <Grid container spacing={2} style={{ marginTop: "5px" }}>
        <Grid item xs={12}>
          <Typography variant="body2" gutterBottom className="uppercase label">
            {t("level")}
          </Typography>

          <FormGroup>
            {" "}
            <Grid
              container
              spacing={2}
              alignItems="center"
              style={{ marginBottom: "10px" }}
            >
              {Array.isArray(tournamentLevels) &&
                tournamentLevels?.map(({ label, id: labelId }) => (
                  <Grid item xs={3} key={labelId}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values?.levels?.includes(labelId) || false}
                          onChange={(e) => {
                            e.stopPropagation();
                            const isChecked = e.target.checked;
                            let newTournamentLevels;

                            if (isChecked) {
                              newTournamentLevels = labelId
                                ? [...(values?.levels || []), labelId] // Ensure values?.coachLevels is an array
                                : [labelId];
                            } else {
                              newTournamentLevels = values?.levels?.filter(
                                (id: any) => id !== labelId
                              );
                            }

                            formProps.setFieldValue(
                              "levels",
                              newTournamentLevels
                            );
                          }}
                        />
                      }
                      label={label}
                    />
                  </Grid>
                ))}

              {errors?.level && (
                <FormHelperText>
                  <span
                    className="MuiFormHelperText-root Mui-error"
                    style={{ color: "#d32f2f", marginLeft: "1rem" }}
                  >
                    {errors?.level}
                  </span>
                </FormHelperText>
              )}
            </Grid>
          </FormGroup>
        </Grid>
      </Grid> */}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth style={{ marginTop: "5px" }}>
            <Typography
              variant="body2"
              gutterBottom
              className="uppercase label"
            >
              {t("tournamentCancellationPolicy")}
            </Typography>
            <Select
              name="cancellationPeriodInHours"
              value={
                values?.cancellationPeriodInHours
                  ? values?.cancellationPeriodInHours
                  : defaultCancellationPeriod
              }
              onChange={handleDropdownChange("cancellationPeriodInHours")}
              style={{ marginTop: "5px" }}
              error={errors?.cancellationPeriodInHours}
            >
              {/* {settingsData?.timeLimitToCancelTournament && (
                <MenuItem value={settingsData?.timeLimitToCancelTournament}>
                  {settingsData.timeLimitToCancelTournament} hrs
                </MenuItem>
              )} */}
              {policyHours
                ?.sort((a: any, b: any) => {
                  // Assuming a and b are strings representing time in HH:MM format
                  return a?.name?.localeCompare(b?.name);
                })
                ?.map((policyHour: any) => (
                  <MenuItem key={policyHour} value={policyHour}>
                    {policyHour} hrs
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ marginTop: "15px" }}>
        <FormControl fullWidth>
          <Typography variant="body2" className="uppercase label" gutterBottom>
            {t("description")} {t("optional")}
          </Typography>
          <TextareaAutosize
            value={values?.description}
            onChange={handleOnChange("description")}
            minRows={5}
            style={{ marginTop: "5px" }}
          />
          <FormHelperText>
            {errors?.description ? (
              <span
                className="MuiFormHelperText-root Mui-error"
                style={{ color: "#d32f2f" }}
              >
                {errors?.description}
              </span>
            ) : (
              ""
            )}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12} style={{ marginTop: "15px" }}>
        <FormControl fullWidth>
          <Typography variant="body2" className="uppercase label" gutterBottom>
            {t("prizeDetails")}
          </Typography>
          <TextareaAutosize
            name="prizeDetails"
            value={values?.prizeDetails}
            onChange={handleOnChange("prizeDetails")}
            minRows={5}
            style={{ marginTop: "5px" }}
          />
          <FormHelperText>
            {errors?.prizeDetails ? (
              <span
                className="MuiFormHelperText-root Mui-error"
                style={{ color: "#d32f2f" }}
              >
                {errors?.prizeDetails}
              </span>
            ) : (
              ""
            )}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: "5px" }}>
        <Grid item xs={6}>
          <InputLabel>{t("categoryBySex")}</InputLabel>
        </Grid>
        <Grid item xs={6}>
          <RadioGroup
            row
            aria-label="duration"
            name="gender"
            value={values?.gender ? values?.gender : genderValue}
            onChange={handleRadioChange("gender")}
          >
            <FormControlLabel
              value={categoryBySex?.MALE}
              control={<Radio />}
              label={categoryBySex?.MALE?.replace(/\b\w/g, (c) =>
                c.toUpperCase()
              )}
            />
            <FormControlLabel
              value={categoryBySex?.FEMALE}
              control={<Radio />}
              label={categoryBySex?.FEMALE?.replace(/\b\w/g, (c) =>
                c.toUpperCase()
              )}
            />
            <FormControlLabel
              value={categoryBySex?.ALL}
              control={<Radio />}
              label={categoryBySex?.ALL?.replace(/\b\w/g, (c) =>
                c.toUpperCase()
              )}
            />
          </RadioGroup>
          <FormHelperText>
            {touched?.gender && errors?.gender ? (
              <span
                className="MuiFormHelperText-root Mui-error"
                style={{ color: "#d32f2f" }}
              >
                {errors?.gender}
              </span>
            ) : (
              ""
            )}
          </FormHelperText>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom className="uppercase label">
            {t("tournamentVisibility")}
          </Typography>
          <FormControl fullWidth style={{ marginTop: "5px" }}>
            <Select
              name="tournamentVisibility"
              value={
                values?.tournamentVisibility
                  ? values?.tournamentVisibility
                  : "public"
              }
              onChange={handleDropdownChange("tournamentVisibility")}
              error={Boolean(errors?.tournamentVisibility)}
              defaultValue="public"
            >
              {Array.isArray(tournamentVisibility) &&
              tournamentVisibility?.length !== 0 ? (
                tournamentVisibility?.map((visibilityType: any) => (
                  <MenuItem key={visibilityType?.id} value={visibilityType?.id}>
                    {visibilityType?.label !== ""
                      ? visibilityType?.label
                      : t("noName")}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="No Data">{t("noData")}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom className="uppercase label">
            {t("enrolmentType")}
          </Typography>
          <FormControl fullWidth style={{ marginTop: "5px" }}>
            <Select
              name="enrollmentType"
              onChange={handleDropdownChange("enrollmentType")}
              error={Boolean(errors?.enrollmentType)}
              value={["Doubles"]}
              multiple
              disabled
              fullWidth
            >
              {Array.isArray(enrolmentType) && enrolmentType?.length !== 0 ? (
                enrolmentType?.map((enrolment: any) => (
                  <MenuItem key={enrolment?.id} value={enrolment?.id}>
                    {enrolment?.label !== "" ? enrolment?.label : t("noName")}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="No Data">{t("noData")}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {/* <Grid container spacing={2} style={{ marginTop: "5px" }}>
        <Grid item xs={6}>
          <InputLabel>{t("levelRestriction")}</InputLabel>
        </Grid>
        <Grid item xs={6}>
          <RadioGroup
            row
            aria-label="levelRestriction"
            name="levelRestriction"
            value={
              values?.isLevelRestriction
                ? levelRestriction?.YES
                : levelRestriction?.NO
            }
            defaultValue={levelRestriction?.YES}
            onChange={handleRadioChange("isLevelRestriction")}
          >
            <FormControlLabel
              value={levelRestriction?.YES}
              control={<Radio />}
              label={levelRestriction?.YES}
            />
            <FormControlLabel
              value={levelRestriction?.NO}
              control={<Radio />}
              label={levelRestriction?.NO}
            />
          </RadioGroup>
        </Grid>
          </Grid> */}
      <Grid container spacing={2} style={{ marginTop: "-5px" }}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <Typography
              variant="body2"
              className="uppercase label"
              gutterBottom
            >
              {t("minimumAmountOfTeams")}
            </Typography>
            <StyledCustomTextField
              fullWidth
              name="minTeams"
              value={values?.minTeams}
              onChange={handleOnChange("minTeams")}
              onFocus={(e: any) => {
                if (e.target.value === "0") {
                  e.target.value = "";
                  handleOnChange("minTeams")(e); // Update state
                }
              }}
              margin="normal"
              error={errors?.minTeams}
              helperText={errors?.minTeams}
              style={{ marginTop: "0px" }}
              autoComplete="off"
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <Typography
              variant="body2"
              className="uppercase label"
              gutterBottom
            >
              {t("maximumAmountOfTeams")}
            </Typography>
            <StyledCustomTextField
              fullWidth
              name="maxTeams"
              value={values?.maxTeams}
              onChange={handleOnChange("maxTeams")}
              onFocus={(e: any) => {
                if (e.target.value === "0") {
                  e.target.value = "";
                  handleOnChange("maxTeams")(e); // Update state
                }
              }}
              margin="normal"
              error={errors?.maxTeams}
              helperText={errors?.maxTeams}
              style={{ marginTop: "0px" }}
              autoComplete="off"
            />
          </FormControl>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: "10px" }} />
      <Grid container spacing={2} style={{ marginTop: "15px" }}>
        <Grid item xs={12}>
          <Typography variant="body2" gutterBottom className="uppercase label">
            {t("optionalResources")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormGroup>
            {Array.isArray(features) &&
              features?.length >= 4 &&
              features?.slice(0, 4).map((label) => (
                <Grid item key={label}>
                  <FormControlLabel
                    key={label}
                    control={
                      <Checkbox
                        checked={values?.features?.includes(label) || false}
                        onChange={handleCheckboxChange("features")}
                        value={label}
                      />
                    }
                    label={t(label)}
                  />
                </Grid>
              ))}
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup>
            {Array.isArray(features) &&
              features?.length >= 4 &&
              features?.slice(4)?.map((label) => (
                <Grid item key={label}>
                  <FormControlLabel
                    key={label}
                    control={
                      <Checkbox
                        checked={values?.features?.includes(label) || false}
                        onChange={handleCheckboxChange("features")}
                        value={label}
                      />
                    }
                    label={t(label)}
                  />
                </Grid>
              ))}
          </FormGroup>
        </Grid>
      </Grid>
    </div>
  );
};

export default GeneralInformation;
