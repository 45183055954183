import { Dispatch, AnyAction } from "redux";
import { CUSTOM_ERROR_MSG, HANDLE_ERROR, HTTP_CALL } from "../../config/http";
import {
  GET,
  TOURNAMENTS_MANAGEMENT,
  PUT,
  POST,
  PATCH,
} from "../../config/API.constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isEmpty } from "lodash";
import { transformFilters } from "app/shared/util/buildQuery";
import {
  tournamentsReceived,
  tournamentsRequested,
} from "../../redux/tournamentsReducer";
import { showToast } from "../../shared/util/toastHelper";

type AppDispatch = Dispatch<AnyAction>;

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const fetchTournamentsList =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    try {
      const qParams = {
        ...params,
        limit: params?.pageSize ? params?.pageSize : 10,
        sort: params?.sort || "createdAt|DESC",
      };
      // // Construct the filters object from the query parameter
      const filtersString: any = await transformFilters(qParams);
      const filteredSearchParams = new URLSearchParams(filtersString);

      if (!params?.isFilter) {
        dispatch(tournamentsReceived([]));
        dispatch(tournamentsRequested());
      }

      response = await HTTP_CALL(
        `${
          TOURNAMENTS_MANAGEMENT.API_END_POINTS.GET_ALL_TOURNAMENTS
        }?${filteredSearchParams.toString()}`,
        GET,
        "",
        params
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      dispatch(tournamentsReceived(response?.data));

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Update Tournament status
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const tournamentStatusUpdate = async (id: any, status?: any) => {
  const tournamentStatusUpdateApi =
    TOURNAMENTS_MANAGEMENT.API_END_POINTS.STATUS_UPDATE;
  let response: any = {};
  const params = {
    status: status,
  };
  try {
    response = await HTTP_CALL(
      `${tournamentStatusUpdateApi}/${id}`,
      PATCH,
      "",
      params
    );
    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (!isEmpty(apiData)) {
        showToast("Tournament status updated successfully", "Success", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        showToast("No Data", "Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};

/**
 * Fetches tournament detail data based on the tournament id from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const fetchTournamentDetailFromApi = async (
  id: any,
  apiName: string
) => {
  let response: any = {};
  try {
    response = await HTTP_CALL(`${apiName}/${id}`, GET);
    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (!isEmpty(apiData)) {
        return apiData;
      } else {
        showToast("No Data", "Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const createTournament =
  (values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        TOURNAMENTS_MANAGEMENT.API_END_POINTS.ADD_TOURNAMENT_DETAILS,
        POST,
        "",
        values
      );

      showToast("Tournament created successfully", "Success", {
        position: toast.POSITION.BOTTOM_CENTER,
      });

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(response);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Update data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const updateTournament =
  (id: string, values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    try {
      response = await HTTP_CALL(
        `${TOURNAMENTS_MANAGEMENT.API_END_POINTS.UPDATE_TOURNAMENT}/${id}`,
        PUT,
        "",
        values
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (statusCode === 200) {
        showToast("Tournament updated successfully", "Success", {
          position: toast.POSITION.BOTTOM_CENTER,
        });

        if (!isEmpty(apiData)) {
          if (typeof callback === "function") {
            callback(apiData);
          }

          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Publish Tournament status
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const tournamentPublish = async (id: any) => {
  const tournamentPublishApi =
    TOURNAMENTS_MANAGEMENT.API_END_POINTS.PUBLISH_TOURNAMENT;
  let response: any = {};
  try {
    response = await HTTP_CALL(
      `${tournamentPublishApi}/${id}/publish`,
      PATCH,
      ""
    );
    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (!isEmpty(apiData)) {
        showToast("Tournament published successfully", "Success", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        showToast("No Data", "Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};

/**
 * Cancel Tournament status
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const tournamentCancel = async (id: any, status?: any) => {
  const tournamentCancelApi =
    TOURNAMENTS_MANAGEMENT.API_END_POINTS.CANCEL_TOURNAMENT;
  let response: any = {};
  try {
    response = await HTTP_CALL(
      `${tournamentCancelApi}/${id}/cancel`,
      PATCH,
      ""
    );
    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (!isEmpty(apiData)) {
        showToast("Tournament cancelled successfully", "Success", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        showToast("No Data", "Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};

/**
 * Verify License Number
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const tournamentVerifyLicenseNumber = async (
  id: any,
  teamId: any,
  userId: any
) => {
  const tournamentVerifyLicenseNumberApi =
    TOURNAMENTS_MANAGEMENT.API_END_POINTS.VERIFY_LICENSE_NUMBER;
  let response: any = {};
  try {
    response = await HTTP_CALL(
      `${tournamentVerifyLicenseNumberApi}/${id}/${teamId}/user/${userId}`,
      PATCH,
      ""
    );
    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (!isEmpty(apiData)) {
        showToast("License number verified successfully", "Success", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else {
        showToast("No Data", "Error", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};

/**
 * Approve or Decline Player
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const playerApproveDecline = async (
  id: any,
  teamId: any,
  userId: any,
  status?: any
) => {
  const playerApproveDeclineApi =
    TOURNAMENTS_MANAGEMENT.API_END_POINTS.APPROVE_DECLINE_PLAYER;
  let response: any = {};
  const params = {
    status: status,
  };
  try {
    response = await HTTP_CALL(
      `${playerApproveDeclineApi}/${id}/${teamId}/user/${userId}`,
      PATCH,
      "",
      params
    );
    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (!isEmpty(apiData)) {
        const msg = status === "cancelled" ? "declined" : "approved";
        showToast(`Player ${msg} successfully`, "Success", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else {
        showToast("No Data", "Error", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};
