export interface IClubDetail {
  _id?: any;
  phone?: any;
  name?: any;
  email?: any;
  countryCode?: any;
}

export const defaultClubDetailValue: Readonly<IClubDetail> = {
  _id: undefined,
  phone: 0,
  name: "",
  email: "",
  countryCode: 0,
};

export interface ICourtDetail {
  _id?: any;
  offPeakHourPrice?: any;
  name?: any;
  onPeakHourPrice?: any;
  playersAllowed?: any;
}

export const defaultCourtDetailValue: Readonly<ICourtDetail> = {
  _id: undefined,
  offPeakHourPrice: 0,
  name: "",
  onPeakHourPrice: 0,
  playersAllowed: 0,
};

export interface IParticipants {
  user?: any;
  position?: any;
  phone?: any;
  lastName?: any;
  isOrganiser?: any;
  group?: any;
  firstName?: any;
  email?: any;
  countryCode?: any;
  playerLevel?: any;
  gymlibCode?: any;
  status?: any;
  [key: string]: any;
}

export const defaultParticipantsValue: Readonly<IParticipants> = {
  user: "",
  position: 0,
  lastName: "",
  group: "",
  isOrganiser: true,
  firstName: "",
  email: "",
  countryCode: "",
  phone: 0,
  playerLevel: 0.0,
  status: "pending",
  gymlibCode: "",
};

export interface IUserDetail {
  _id?: any;
  firstName?: any;
  lastName?: any;
  profilePic?: any;
  playerLevel?: any;
}

export const defaultUserDetailValue: Readonly<IUserDetail> = {
  _id: undefined,
  firstName: "",
  lastName: "",
  profilePic: "",
  playerLevel: 0.0,
};

export interface IPlayers {
  countryCode?: any;
  firstName?: any;
  group?: any;
  isOrganiser?: any;
  lastName?: any;
  phone?: any;
  position?: any;
  user?: any;
  participants?: any;
  playerLevel?: any;
  paymentType?: any;
  gymlibCode?: any;
}

export const defaultPlayersValue: Readonly<IPlayers> = {
  countryCode: "",
  firstName: "",
  group: "",
  isOrganiser: false,
  lastName: "",
  phone: 0,
  position: 1,
  user: "",
  participants: [],
  playerLevel: 0.0,
  paymentType: "",
  gymlibCode: "",
};

export interface IMatches {
  a?: any;
  b?: any;
  winner?: any;
}

export const defaultMatchesValue: Readonly<IMatches> = {
  a: 0,
  b: 0,
  winner: "",
};

export interface IMatchResults {
  noOfMatches?: any;
  winner?: any;
  matches?: IMatches[];
}

export const defaultMatchResultsValue: Readonly<IMatchResults> = {
  noOfMatches: 0,
  winner: "",
  matches: [],
};

export interface IRecurringBooking {
  type?: any;
  days?: any;
  startDate?: any;
  endDate?: any;
}

export const defaultRecurringBookingValue: Readonly<IRecurringBooking> = {
  type: "",
  days: [],
  startDate: "",
  endDate: "",
};

export interface IBooking {
  _id?: any;
  bookingType?: any;
  club?: any;
  clubDetail?: IClubDetail;
  court?: any;
  courtDetail?: ICourtDetail;
  createdByAdmin?: any;
  date?: any;
  endTime?: any;
  isPublic?: any;
  matchPreference?: any;
  matchType?: any;
  notes?: any;
  playersAllowed?: any;
  createdAt?: any;
  participants?: any;
  paymentStatus?: any;
  paymentType?: any;
  slots?: any;
  startTime?: any;
  status?: any;
  updatedAt?: any;
  user?: any;
  userDetail?: IUserDetail;
  paidAmount?: any;
  balanceAmount?: any;
  userName?: any;
  duration?: any;
  gender?: any;
  bookingMinutes?: any;
  amountPrice?: any;
  isRecurringBooking?: any;
  recurringBooking?: IRecurringBooking;
  gymlibCode?: any;
}

export const defaultBookingValue: Readonly<IBooking> = {
  _id: undefined,
  bookingType: "",
  club: "",
  clubDetail: {},
  court: "",
  courtDetail: {},
  createdByAdmin: true,
  date: "",
  endTime: "",
  isPublic: true,
  matchPreference: "",
  matchType: "",
  notes: [],
  playersAllowed: 4,
  createdAt: "",
  participants: [],
  paymentStatus: "",
  paymentType: "",
  slots: [],
  startTime: "",
  status: "",
  updatedAt: "",
  user: "",
  userDetail: {},
  paidAmount: 0,
  balanceAmount: 0,
  userName: "",
  duration: 90,
  gender: "",
  bookingMinutes: 90,
  amountPrice: 0,
  isRecurringBooking: false,
  recurringBooking: {},
  gymlibCode: "",
};
