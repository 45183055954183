export interface IGeneralInformation {
  date: string;
  time: any;
  isPublic: any;
  paidAmount: string;
  balanceAmount: string;
  user: any;
  firstName: string;
  lastName: string;
  countryCode: any;
  phone: any;
  email: string;
  bookingId: any;
  paymentType?:any;
  bookingMinutes?:any;
}

export const defaultGeneralInformationValue: Readonly<IGeneralInformation> = {
  date: "",
  time: "",
  isPublic: "0",
  paidAmount: "",
  balanceAmount: "",
  user: "",
  firstName: "",
  lastName: "",
  countryCode: "",
  phone: "",
  email: "",
  bookingId: "",
  paymentType: "",
  bookingMinutes: 60
};
