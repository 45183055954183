/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import {
  Modal,
  Box,
  Button,
  Divider,
  Paper,
  Slide,
  Grid,
  Typography,
  IconButton,
  TextField,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { Form, Formik, FormikProps } from "formik";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { sortBy } from "lodash";

import { useAuth } from "../../../../AuthContext";
import { AppDispatch } from "../../../redux/store";
import { defaultGeneralInformationValue } from "../../../model/product-model";
import {
  createProduct,
  updateProduct,
  fetchProductsList,
} from "../productsApiService";
import { fetchTagsList } from "app/entities/Tags/tagsApiService";
import { AUTHORITIES } from "app/config/constants";
import ConfirmDialog from "app/shared/components/ConfirmDialog"; 
import { SubmitButton } from "app/shared/molecules";

interface ModalFormProps {
  open: boolean;
  onClose: () => void;
  type?: any;
  action?: any;
  formData?: any;
  page?: any;
  refreshClubsGrid?: any;
  refresh?: any;
  setRefresh?: any;
  callback?: any;
}

const brownButtonStyle = {
  backgroundColor: "#333333",
  color: "white", // Text color
};

const BoldTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold", // Set the font weight to bold
}));

const StyledTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "black", // Change the label color when focused
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "black", // Change the outline border color when focused
  },
  "& .MuiTextField-root": {
    marginTop: 0, // Set top margin to 0
    marginBottom: 0, // Set bottom margin as needed
  },
});

const AddProductModalForm: React.FC<ModalFormProps> = ({
  open,
  onClose,
  formData,
  callback,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const formikRef = useRef<FormikProps<any>>(null);

  const { user } = useAuth();
  const [editData, setEditData] = useState<any>();
  const [categories, setCategories] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);


  const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required(`${t("productNameRequired")}`)
      .min(1, `${t("minimumOneLettersRequired")}`)
      .max(50, `${t("maximum50LettersAllowed")}`),
    price: yup
      .number()
      .required(`${t("priceRequired")}`)
      .test(
        "is-decimal",
        `${t("thePriceShouldbeDecimalwithMaximumTwoDigitsAfterComma")}`,
        (val: any) => {
          if (val !== undefined) {
            return patternTwoDigisAfterComma.test(val);
          }
          return true;
        }
      )
      .typeError("Enter a valid number"),
    category: yup.string().required(`${t("categoryRequired")}`),
    productDetails: yup
      .string()
      .min(1, `${t("minimumOneLettersRequired")}`)
      .max(250, `${t("maximum250LettersAllowed")}`),
    numberOfCoachingSessions: yup
      .number()
      .required(t("numberOfCoachingSessionsRequired")),
  });

  useEffect(() => {
    if (open)
      dispatch(
        fetchTagsList(
          {
            filter_status: "active",
            filter_type: "coaching-product",
            pageSize: 1000,
          },
          (response) => {
            let categories: any = sortBy(response, "name");
            setCategories(categories || []);
          }
        )
      );
  }, [open, dispatch]);

  // Set all the form data used for building the request object to the API finally
  useEffect(() => {
    if (formData?._id) {
      setEditData({
        ...formData,
        category: formData?.category?._id,
      });
    }
  }, [formData]);

  /**
   * Handle Next on button click to load the next step if second page call the api
   *
   * @function
   * @returns void
   */
  const handleSubmit = async (values?: any, actions?: any) => {
    try {
      if (formData?._id) {
        delete values._id; 
          const response = await dispatch(updateProduct(formData?._id,  { ...values }));
  
        if (response) {
          if (typeof callback === "function") {
            callback(); 
          }
            onClose();
        }
      } else {
        const response = await dispatch(createProduct({ ...values }));
        if (response) {
          onClose(); 
          dispatch(fetchProductsList({ page: 1 })); 
        }
      }
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      actions.setSubmitting(false); 
    }
  };


  /**
   * Handle close on button click
   *
   * @function
   * @returns void
   */
  const handleCloseModal = (event?: any, reason?: any) => {
    if (formikRef?.current?.dirty) {
      // Show confirmation dialog if there are unsaved changes
      setConfirmOpen(true);
    } else {
      onClose();
    }
  };

    // Handle close confirmation dialog
    const handleCancelClose = () => {
      setConfirmOpen(false);
    };
  
    // Handle confirm close action
    const handleConfirmClose = () => {
      setConfirmOpen(false);
      onClose();
    };


  const handleOnChange =
    (objectKey: string, setFieldValue?: any) => (e: any) => {
      const val = e?.target?.value;
      setFieldValue(`${objectKey}`, val);
    };

  return (
    <><Modal
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: "rgba(0,0,0,0.5)",
          },
        },
      }}
    >
      <Slide in={open} direction="left">
        <Paper
          style={{
            position: "absolute",
            right: "0",
            transform: "translateY(-50%)",
            width: "35%",
            padding: "25px",
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Box>
            {/* Content of the sliding modal */}
            <BoldTypography variant="h6" style={{ marginBottom: "1rem" }}>
              {formData?._id ? t("editNewProduct") : t("addNewProduct")}
            </BoldTypography>
            <Divider />
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
              sx={{
                position: "absolute",
                top: "15px",
                right: "24px",
                cursor: "pointer",
              }}
            >
              <CloseIcon />
            </IconButton>
            <div>
              <Formik
                innerRef={formikRef}
                initialValues={
                  formData?._id ? editData : defaultGeneralInformationValue
                }
                onSubmit={(values, actions) => {
                  let newValue = {
                    ...values,
                    price: parseFloat(values?.price),
                    numberOfCoachingSessions: parseFloat(
                      values?.numberOfCoachingSessions
                    ),
                    createdBy: user?.userId,
                  };
                  if (values?.productDetails === "") {
                    delete newValue.productDetails;
                  }
                  handleSubmit(newValue, actions);
                }}
                enableReinitialize
                validationSchema={validationSchema}
              >
                {({ values, errors, setFieldValue, isSubmitting, touched }: any) => {
                  return (
                    <>
                      <Form>
                        <Grid
                          container
                          spacing={2}
                          style={{ marginTop: "10px" }}
                        >
                          {" "}
                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              gutterBottom
                              className="uppercase label"
                            >
                              {t("productName")}
                            </Typography>
                            <StyledTextField
                              fullWidth
                              name="name"
                              value={values?.name || ""}
                              onChange={handleOnChange("name", setFieldValue)}
                              margin="normal"
                              placeholder={t("name")}
                              error={errors?.name}
                              helperText={errors?.name}
                              disabled={
                                user?.userType === AUTHORITIES.club_admin
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography className="uppercase label">
                              {t("price")}
                            </Typography>
                            <StyledTextField
                              fullWidth
                              name="price"
                              value={values?.price || ""}
                              onChange={handleOnChange("price", setFieldValue)}
                              margin="normal"
                              placeholder={t("price")}
                              error={errors?.price}
                              helperText={errors?.price}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    €
                                  </InputAdornment>
                                ),
                                inputMode: "numeric",
                              }}
                              disabled={
                                user?.userType === AUTHORITIES.club_admin
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              gutterBottom
                              className="uppercase label"
                            >
                              {t("numberOfCoachingSessions")}
                            </Typography>
                            <StyledTextField
                              fullWidth
                              name="numberOfCoachingSessions"
                              value={values?.numberOfCoachingSessions || ""}
                              onChange={handleOnChange(
                                "numberOfCoachingSessions",
                                setFieldValue
                              )}
                              margin="normal"
                              placeholder={t("numberOfCoachingSessions")}
                              error={errors?.numberOfCoachingSessions}
                              helperText={errors?.numberOfCoachingSessions}
                              disabled={
                                user?.userType === AUTHORITIES.club_admin
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            {" "}
                            <FormControl
                              fullWidth
                              style={{ marginTop: "20px" }}
                            >
                              <Typography
                                variant="body2"
                                className="uppercase label"
                                gutterBottom
                              >
                                {t("category")}
                              </Typography>
                              <Select
                                name="category"
                                value={values?.category ? values?.category : ""}
                                onChange={handleOnChange(
                                  "category",
                                  setFieldValue
                                )}
                                style={{ marginTop: "15px" }}
                                disabled={
                                  user?.userType === AUTHORITIES.club_admin
                                }
                              >
                                {categories?.length !== 0 ? (
                                  categories?.map((category: any) => (
                                    <MenuItem
                                      key={category?._id}
                                      value={category?._id}
                                    >
                                      {category?.name !== ""
                                        ? category?.name
                                        : t("noName")}
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem value="No Data">
                                    {t("noData")}
                                  </MenuItem>
                                )}
                              </Select>
                              <FormHelperText>
                                {errors?.category ? (
                                  <span
                                    className="MuiFormHelperText-root Mui-error"
                                    style={{ color: "#d32f2f" }}
                                  >
                                    {t("typeRequired")}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <Typography
                                variant="body2"
                                className="uppercase label"
                                gutterBottom
                              >
                                {t("productDetails")} {t("optional")}
                              </Typography>
                              <TextareaAutosize
                                value={values?.productDetails}
                                onChange={handleOnChange(
                                  "productDetails",
                                  setFieldValue
                                )}
                                minRows={5}
                                style={{ marginTop: "15px" }}
                                disabled={
                                  user?.userType === AUTHORITIES.club_admin
                                }
                              />
                              <FormHelperText>
                                {errors?.productDetails ? (
                                  <span
                                    className="MuiFormHelperText-root Mui-error"
                                    style={{ color: "#d32f2f" }}
                                  >
                                    {errors?.productDetails}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                        {user?.userType !== AUTHORITIES.club_admin && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid
                              container
                              spacing={2}
                              style={{ marginTop: "15px" }}
                            >
                              <Grid
                                item
                                xs={12}
                                container
                                justifyContent="flex-end"
                              >
                                <SubmitButton
                                  type="submit"
                                  disabled={isSubmitting}
                                >
                                  {formData?._id
                                    ? t("saveChanges")
                                    : t("saveNewProduct")}
                                </SubmitButton>
                              </Grid>
                            </Grid>
                          </div>
                        )}
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </div>
          </Box>
        </Paper>
      </Slide>
    </Modal>
    
    {/* Confirmation dialog for unsaved changes */}
    <ConfirmDialog
  open={confirmOpen}
  onClose={handleCancelClose}
  onConfirm={handleConfirmClose}
/></>
  );
};

export default AddProductModalForm;
