import { Dispatch, AnyAction } from "redux";
import { isEmpty } from "lodash";

import { CUSTOM_ERROR_MSG, HANDLE_ERROR, HTTP_CALL } from "../../config/http";

import {
  GET,
  TRANSACTIONS_MANAGEMENT,
} from "../../config/API.constants";

import { transformFilters } from "app/shared/util/buildQuery";

type AppDispatch = Dispatch<AnyAction>;

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const fetchTransactionsList =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    const qParams = {
      ...params,
      //limit: params?.pageSize || 10,
      //page: params?.page || 1,
      sort: params?.sort || "createdAt|DESC",
    };

    const filtersString: any = await transformFilters(qParams);
    const filteredSearchParams = new URLSearchParams(filtersString);

    try {
      response = await HTTP_CALL(
        `${
          TRANSACTIONS_MANAGEMENT.GET_ALL_TRANSACTIONS
        }?${filteredSearchParams.toString()}`,
        GET
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

