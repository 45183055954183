import React from "react";
import { Box, Pagination, Select, MenuItem } from "@mui/material";
import { styled } from "@mui/system";
import { times } from "lodash";
import { useTranslation } from "react-i18next";

const StyledSelect = styled(Select)`
  background: #5e636614;
  border-radius: 8px;
  min-height: 23px;
  height: 23px;
  color: #8b8d97;
  font-size: 12px;
  fieldset {
    border-width: 0;
  }
`;

const StyledPagination = styled(Pagination)`
  .MuiPaginationItem-textPrimary.Mui-selected,
  .MuiPaginationItem-textPrimary.Mui-selected:hover {
    background-color: rgb(51, 51, 51);
  }
`;

const CustomPagination = ({
  defaultPageSize = 10,
  defaultPage = 0,
  dataCount,
  totalPages: propTotalPages = 0,
  handlePageChange,
  handlePageSizeChange,
  currentRecords,
}: any) => {
  const { t } = useTranslation();

  const dataLength = currentRecords?.length || 0;

  const secondValue = (defaultPage - 1) * defaultPageSize + dataLength;

  const totalPages = dataCount > 0 ? propTotalPages : 0;

  const pageItems = times(totalPages, (index: number) => (
    <MenuItem key={index} value={index + 1}>
      {index + 1}
    </MenuItem>
  ));

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      className="justify-center lg:justify-between"
      style={{ marginTop: "2rem", fontSize: "14px" }}
    >
      <Box>
        <StyledSelect
          defaultValue={defaultPageSize || 10}
          onChange={(e) => {
            handlePageSizeChange({ pageSize: e?.target?.value });
          }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
        </StyledSelect>
        <span style={{ marginLeft: "10px", color: "#A6A8B1" }}>
          {t("itemsPerPage")}
        </span>
      </Box>

      <Box
        //className="hidden lg:block"
        style={{ marginLeft: "10px", color: "#666666" }}
      >
        {dataCount > 0
          ? `${
              defaultPage === 1
                ? dataLength > 0
                  ? defaultPage
                  : 0
                : (defaultPage - 1) * defaultPageSize + 1
            } - ${secondValue} of ${dataCount} ${t("items")}`
          : ""}
      </Box>

      <Box marginLeft={"auto"}>
        <Box>
          <StyledSelect
            defaultValue={defaultPage}
            value={defaultPage || 1}
            onChange={(e) => {
              handlePageChange({
                page: e?.target?.value,
                pageSize: defaultPageSize,
              });
            }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {pageItems}
          </StyledSelect>
          <span style={{ marginLeft: "10px", color: "#666666" }}>
            of {totalPages} {t("pages")}
          </span>
        </Box>
      </Box>
      <Box style={{ marginLeft: "10px" }}>
        <StyledPagination
          page={parseInt(defaultPage) || 1}
          count={totalPages}
          color="primary"
          onChange={(event: React.ChangeEvent<unknown>, page: number) => {
            handlePageChange({ page: page, pageSize: defaultPageSize });
          }}
        />
      </Box>
    </Box>
  );
};

export default CustomPagination;
