import * as yup from "yup";
import { useCommonTranslations } from "./CommonTranslations";

export const AddExtraProductValidationSchema = (showSizeOption: boolean) => {
  // eslint-disable-next-line
  const { t } = useCommonTranslations();

  return yup.object().shape({
    name: yup
      .string()
      .required(`${t("productNameRequired")}`)
      .min(1, `${t("minimumOneLettersRequired")}`)
      .max(50, `${t("maximum50LettersAllowed")}`),
    clubs: yup.array().min(1, "Minimum 1 club required").required("required"),
    description: yup
      .string()
      .min(1, `${t("minimumOneLettersRequired")}`)
      .max(5000, `${t("maximum5000LettersAllowed")}`),
    variants: yup.array().of(
      yup.object().shape({
        ...(showSizeOption
          ? {
              availableSizes: yup.array().of(
                yup.object().shape({
                  size: yup.string().required("Size is required"),
                  // availableUnits: yup
                  //   .number()
                  //   .required("Quantity is required")
                  //   .positive("Quantity must be a positive number")
                  //   .integer("Quantity must be an integer"),
                  availableUnits: yup
                    .number()
                    .required("Quantity is required")
                    .test(
                      "is-non-negative-integer",
                      "Quantity must be a positive number",
                      (value) => Number.isInteger(value) && value >= 0
                    ),
                  unitPrice: yup
                    .number()
                    .required("Price is required")
                    .positive("Price must be a positive number"),
                })
              ),
            }
          : {
              // unitPrice: yup.number().required("Price is required"),
              // availableUnits: yup
              //   .number()
              //   .required("Quantity is required")
              //   .positive("Quantity must be a positive number")
              //   .integer("Quantity must be an integer"),
              availableSizes: yup.array().of(
                yup.object().shape({
                  // availableUnits: yup
                  //   .number()
                  //   .required("Quantity is required")
                  //   .positive("Quantity must be a positive number")
                  //   .integer("Quantity must be an integer"),
                  availableUnits: yup
                    .number()
                    .required("Quantity is required")
                    .test(
                      "is-non-negative-integer",
                      "Quantity must be a positive number",
                      (value) => Number.isInteger(value) && value >= 0
                    ),
                  unitPrice: yup
                    .number()
                    .required("Price is required")
                    .positive("Price must be a positive number"),
                })
              ),
            }),
      })
    ),
  });
};

export const AddUserPurchaseValidationSchema = (showSizeOption: boolean) => {
  // eslint-disable-next-line
  const { t } = useCommonTranslations();

  return yup.object().shape({
    club: yup.string().required(`${t("clubRequired")}`),
    orderDate: yup.string().required(`${t("orderDateRequired")}`),
    user: yup.string().required(`${t("userRequired")}`),
    products: yup
      .array()
      .required(`${t("productRequired")}`)
      .min(1, `${t("minimumOneProductRequired")}`),
    orderDetails: yup.array().of(
      yup.object().shape({
        isMultipleVariantsAvailable: yup.boolean(),
        orderList: yup.array().of(
          yup.object().shape({
            // variant: yup
            //   .string()
            //   .when(
            //     ["../isMultipleVariantsAvailable"],
            //     (isMultipleVariantsAvailable, schema) => {
            //       return isMultipleVariantsAvailable
            //         ? schema.required("Variant is required")
            //         : schema.notRequired();
            //     }
            //   ),
            quantity: yup
              .number()
              .required("Quantity is required")
              .positive("Quantity must be positive")
              .integer("Quantity must be an integer"),
            totalAmount: yup
              .number()
              .required("Total amount is required")
              .positive("Total amount must be positive"),
          })
        ),
      })
    ),
    paymentType: yup.string().required(`${t("paymentTypeRequired")}`),
  });
};

export const AddServiceValidationSchema = () => {
  // eslint-disable-next-line
  const { t } = useCommonTranslations();

  return yup.object().shape({
    name: yup
      .string()
      .required(`${t("serviceNameRequired")}`)
      .min(1, `${t("minimumOneLettersRequired")}`)
      .max(50, `${t("maximum50LettersAllowed")}`),
    price: yup
      .number()
      .required("Price is required")
      .positive("Price must be a positive number")
      .test(
        "is-decimal",
        "Price must have at most two decimal places",
        (value: any) =>
          value === undefined ||
          value === null ||
          /^\d+(\.\d{1,2})?$/.test(value)
      ),
    description: yup
      .string()
      .min(1, `${t("minimumOneLettersRequired")}`)
      .max(5000, `${t("maximum5000LettersAllowed")}`),
  });
};
