import * as React from "react";
import { useEffect } from "react";
import {
  Typography,
  Divider,
  Grid,
  IconButton,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { FieldArray } from "formik";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import FormHelperText from "@mui/material/FormHelperText";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { generateTimeSlots } from "../../../../shared/util/GenerateTimeSlots";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Slots, ActionTypes } from "../../../../config/constants";
import _ from "lodash";
import { BlackSwitch } from "../../../../shared/components/BlackSwitch";

interface PeakHour {
  from: string;
  to: string;
}

interface WorkingHours {
  day: string;
  startTime: string;
  endTime: string;
  slots: string[];
  peakSlots: any[];
  peakHours: PeakHour[];
  isPeak: boolean;
  isChecked?: boolean;
  courtIndex?: any;
}

interface ScheduleProps {
  formData: any; // Add formData prop
  scheduleData?: any;
  handleChange?: any;
  hasValues?: boolean;
  setHasValues?: any;
  setScheduleData?: any;
  action?: any;
  editData?: any;
  page?: any;
  formProps?: any;
  courtIndex?: any;
  servicesList?: any;
  memoizedClubBasedSlots?: any;
}

const CoachSchedule: React.FC<ScheduleProps> = ({
  formData,
  scheduleData,
  handleChange,
  hasValues,
  setHasValues,
  setScheduleData,
  memoizedClubBasedSlots,
  action,
  editData,
  page,
  formProps,
  courtIndex,
  servicesList,
}) => {
  const { t } = useTranslation();
  const { setFieldValue, values } = formProps;
  const capitalize = (str: string) => _.capitalize(str?.toLowerCase());

  const defaultWorkingHour: WorkingHours = {
    day: "sunday",
    startTime: values?.minTimeSlot || "00:00",
    endTime: values?.maxTimeSlot || "00:00",
    slots: [],
    peakSlots: [],
    peakHours: [],
    isPeak: false,
    isChecked: false,
  };

  const daysOfWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const [errorMsg, setErrorMsg] = React.useState<{
    dayIndex?: number;
    peakIndex?: number;
    field?: string;
    message?: string;
  }>({});

  const getPeakTimeOptions = (
    startTime: string,
    endTime: string,
    excludeOptions: string[] = []
  ): string[] => {
    const startIndex = Slots?.SLOTS?.indexOf(startTime);
    const endIndex = Slots?.SLOTS?.indexOf(endTime);
    const availableOptions = Slots?.SLOTS?.slice(startIndex, endIndex + 1);

    return availableOptions.filter(
      (option: any) => !excludeOptions.includes(option)
    );
  };

  const isPeakExist =
    values?.courts?.[courtIndex]?.workingHours?.length !== 0
      ? values?.courts?.[courtIndex]?.workingHours?.some((wh: any) => wh.isPeak)
      : false;

  const initializeWorkingHours = () => {
    if (action === ActionTypes?.EDIT) {
      return editData || formData?.workingHours;
    } else if (
      isPeakExist === true ||
      scheduleData?.workingHours?.some((wh: WorkingHours) => wh.isChecked)
    ) {
      return scheduleData?.workingHours;
    } else {
      return daysOfWeek?.map((day) => ({
        ...defaultWorkingHour,
        day,
        isChecked: false,
        isPeak: false,
        peakHours: [],
        peakSlots: [],
      }));
    }
  };

  const [workingHours, setWorkingHours] = React.useState<any>(
    initializeWorkingHours
  );

  const [expanded, setExpanded] = React.useState<{
    [key: string]: string | false;
  }>(
    Object.fromEntries(
      daysOfWeek.map((day, index) => [day, index === 0 ? `panel1` : false])
    )
  );

  useEffect(() => {
    // workingHours?.forEach((ele: any, index: any) => {
    //   const startTimetoFormSlot = ele?.startTime;
    //   const endTimetoFormSlot = ele?.endTime;
    //   if (ele.isChecked === true) {
    //     ele.slots = generateTimeSlots(startTimetoFormSlot, endTimetoFormSlot);
    //   }
    //   if (ele.isPeak === true && ele.isChecked === true) {
    //     ele.peakSlots = calculatePeakSlots(index);
    //   }
    // });
  }, [workingHours]); // Empty dependence array to run it on mount for only once

  useEffect(() => {
    if (action === ActionTypes?.EDIT && editData?.length !== 0) {
      setWorkingHours(editData);
    } else if (action === ActionTypes?.EDIT && editData.length === 0) {
      setWorkingHours(
        daysOfWeek?.map((day) => ({ ...defaultWorkingHour, day }))
      );
    }
  }, [editData, action]); // Empty dependence array to run it on mount for only once

  // useEffect(() => {
  //   // Update scheduleData based on the latest formData
  //   const updatedScheduleData = {
  //     workingHours,
  //   };

  //   setScheduleData(updatedScheduleData);
  // }, [formData]);

  /**
   * Handle the add row when the plus sign is clicked
   *
   * @function
   * @returns void
   */

  const handleAddRow = (index: number) => {
    //const newWorkingHours = [...workingHours];
    //let newWorkingHours = values?.courts?.[courtIndex]?.workingHours;
    let newWorkingHours = _.cloneDeep(values?.courts?.[courtIndex]?.workingHours);
    const firstRow = newWorkingHours[0];
    const excludeOptions = [
      ...firstRow.peakHours.map((peakHour: any) => peakHour.from),
      ...firstRow.peakHours.map((peakHour: any) => peakHour.to),
    ];
    if (
      newWorkingHours[index].isPeak &&
      newWorkingHours[index].isChecked === true
    ) {
      newWorkingHours[index].peakHours.push({ from: "", to: "" });
      // Generate time options excluding specified options
      const timeOptions = generateTimeOptions(excludeOptions);

      // Update the dropdown options for the second-row peak start time and peak end time
      newWorkingHours[index].peakHours = newWorkingHours[index].peakHours.map(
        (peakHour: any) => ({
          from: peakHour.from,
          to: peakHour.to,
          // Assuming 'from' and 'to' are strings and you want to filter time options for them
          availableFromOptions: timeOptions,
          availableToOptions: timeOptions,
        })
      );

      // You may need to set these timeOptions in the state if you want to use them in the UI
      // setTimeOptions(timeOptions);
      //setWorkingHours(newWorkingHours);
      setFieldValue(`courts.[${courtIndex}].workingHours`, newWorkingHours);
    }
  };

  // Function to generate time options excluding specified options
  const generateTimeOptions = (excludeOptions: string[]): string[] => {
    return Slots?.SLOTS?.filter((option) => !excludeOptions.includes(option));
  };

  /**
   * Handle the delete row when the close sign is clicked
   *
   * @function
   * @returns void
   */
  const handleDeleteRow = (dayIndex: number, peakIndex: number) => {
    //let newWorkingHours = values?.courts?.[courtIndex]?.workingHours;
    let newWorkingHours = _.cloneDeep(values?.courts?.[courtIndex]?.workingHours);
    newWorkingHours[dayIndex]?.peakHours?.splice(peakIndex, 1);
    //setWorkingHours(newWorkingHours);
    // Update the state variable to assign the changed workingHours array
    // setScheduleData({
    //   workingHours: [...workingHours],
    // });
    setFieldValue(`courts.[${courtIndex}].workingHours`, newWorkingHours);
  };

  /**
   * Handle the Peak Hours switch change
   *
   * @function
   * @returns updatedWorkingHours array with the updated values
   */
  const handleSwitchChange = (
    courtIndex: number,
    index: number,
    checked: boolean
  ) => {
    //let updatedWorkingHours = [...values?.courts?.[courtIndex]?.workingHours];
    let updatedWorkingHours = _.cloneDeep(values?.courts?.[courtIndex]?.workingHours);
    updatedWorkingHours[index].isPeak = checked;

    // Reset peak hours when switch is toggled
    if (!checked) {
      updatedWorkingHours[index].peakHours = [];
      updatedWorkingHours[index].peakSlots = [];
    } else if (
      checked &&
      updatedWorkingHours[index].peakHours.length === 0 &&
      updatedWorkingHours[index].isPeak &&
      updatedWorkingHours[index].isChecked
    ) {
      // If peak hours are empty initially, add a row
      updatedWorkingHours[index]?.peakHours?.push({
        from: "",
        to: "",
      });
    }

    setFieldValue(`courts.[${courtIndex}].workingHours`, updatedWorkingHours);
  };

  /**
   * Handle the peak time change with the entered peak start and end time and the added rows of the same
   * @param {string} dayIndex - Day Index in the workingHours array
   * @param {number} [peakIndex] - Peak Index of the Peak hours array
   * @param {string} field - Corresponding field to be assigned
   * @param {string} [value] - Corresponding value to be passed
   * @function
   * @returns updatedWorkingHours array with the updated values
   */
  const handlePeakTimeChange = (
    courtIndex: number,
    dayIndex: number,
    peakIndex: number,
    field: string,
    value: string
  ) => {
    //let updatedWorkingHours = values?.courts?.[courtIndex]?.workingHours;
    let updatedWorkingHours = _.cloneDeep(values?.courts?.[courtIndex]?.workingHours);

    const currentPeakHour = updatedWorkingHours[dayIndex]?.peakHours[peakIndex];

    // Validate if the peak hour overlaps with other peak hours
    const overlap = updatedWorkingHours[dayIndex]?.peakHours?.some(
      (peak: any, index: number) => {
        if (index === peakIndex) return false; // Skip current peak hour
        const otherStartTime = moment(
          `2023-01-01 ${peak.from}`,
          "YYYY-MM-DD HH:mm"
        );
        const otherEndTime = moment(
          `2023-01-01 ${peak.to}`,
          "YYYY-MM-DD HH:mm"
        );
        const newStartTime = moment(
          `2023-01-01 ${field === "from" ? value : currentPeakHour.from}`,
          "YYYY-MM-DD HH:mm"
        );
        const newEndTime = moment(
          `2023-01-01 ${field === "to" ? value : currentPeakHour.to}`,
          "YYYY-MM-DD HH:mm"
        );
        return (
          (newStartTime.isSameOrAfter(otherStartTime) &&
            newStartTime.isBefore(otherEndTime)) ||
          (newEndTime.isAfter(otherStartTime) &&
            newEndTime.isSameOrBefore(otherEndTime))
        );
      }
    );

    if (overlap) {
      // Display an error message for overlapping peak hours
      // You can use a state variable to store the error message and display it in your UI
      // For example, you can set an error state variable and render it in your UI
      setErrorMsg({ dayIndex, peakIndex, field });
      return updatedWorkingHours[dayIndex]?.peakHours[peakIndex]; // Return previous state without updating
    } else {
      // Clear the error message if there is no overlap
      setErrorMsg({}); // Assuming you have a state variable for error message
    }

    // Check if 'to' time is less than 'from' time
    if (field === "to") {
      const startTime = moment(currentPeakHour.from, "HH:mm");
      const endTime = moment(value, "HH:mm");
      if (endTime.isBefore(startTime)) {
        return updatedWorkingHours[dayIndex]?.peakHours[peakIndex]; // Return previous state without updating
      }
    }

    // Clear the error message if the time range is valid
    setErrorMsg({});

    // Update peak hour fields based on the changed field (from/to)
    if (field === "from") {
      const from = moment(value, "HH:mm");
      const to = from.clone().add(60, "minutes").format("HH:mm");
      updatedWorkingHours[dayIndex].peakHours[peakIndex]["from"] = value;
      updatedWorkingHours[dayIndex].peakHours[peakIndex]["to"] = to;
    } else if (field === "to") {
      updatedWorkingHours[dayIndex].peakHours[peakIndex]["to"] = value;
    }

    // Update peak slots if necessary
    if (
      updatedWorkingHours[dayIndex]?.isChecked &&
      updatedWorkingHours[dayIndex]?.isPeak
    ) {
      updatedWorkingHours[dayIndex].peakSlots = calculatePeakSlots(dayIndex);
    }

    // Update the state with the modified working hours
    // setScheduleData({
    //   workingHours: [...updatedWorkingHours],
    // });

    setFieldValue(`courts.[${courtIndex}].workingHours`, [
      ...updatedWorkingHours,
    ]);
  };

  /**
   * Handle the time change with the entered start and end time
   * @param {string} dayIndex - Day Index in the workingHours array
   * @param {string} field - Corresponding field to be assigned
   * @param {string} [value] - Corresponding value to be passed
   * @function
   * @returns updatedWorkingHours array with the updated values
   */

  const handleTimeChange = (
    courtIndex: number,
    dayIndex: number,
    field: string,
    value: string
  ) => {
    //const updatedWorkingHours = [...values?.courts?.[courtIndex]?.workingHours];
    const updatedWorkingHours = _.cloneDeep(values?.courts?.[courtIndex]?.workingHours);

    // Validate that end time is not lesser than start time
    if (
      field === "endTime" &&
      value < updatedWorkingHours[dayIndex].startTime
    ) {
      // You can display an error message or handle the validation as needed
      alert("End time cannot be lesser than start time");
      return;
      //return values?.courts?.[courtIndex]?.workingHours?.[dayIndex];
    }

    updatedWorkingHours[dayIndex][field] = value;

    if (updatedWorkingHours?.[dayIndex]?.isChecked) {
      const startTimetoFormSlot = updatedWorkingHours?.[dayIndex]?.startTime;
      const endTimetoFormSlot = updatedWorkingHours?.[dayIndex]?.endTime;

      updatedWorkingHours[dayIndex].slots = generateTimeSlots(
        startTimetoFormSlot,
        endTimetoFormSlot
      );
    }

    // setScheduleData({
    //   workingHours: [...updatedWorkingHours],
    // });
    setFieldValue(`courts.[${courtIndex}].workingHours`, [
      ...updatedWorkingHours,
    ]);
  };

  /**
   * Calculate the peak time slots based on th entered start and end time
   *
   * @function
   * @returns newPeakSlots with the updated values
   */
  const calculatePeakSlots = (dayIndex: number) => {
    const day = values?.courts?.[courtIndex]?.workingHours[dayIndex];
    const newPeakSlots: string[] = [];

    day?.peakHours?.forEach((peakHour: any) => {
      const start = moment(`2023-01-01 ${peakHour?.from}`, "YYYY-MM-DD HH:mm");
      const end = moment(`2023-01-01 ${peakHour?.to}`, "YYYY-MM-DD HH:mm");

      // Adjust end time to the next day if it is less than the start time
      if (end.isBefore(start)) {
        end.add(1, "day");
      }
      while (start < end) {
        const slot = start?.format("HH:mm");
        newPeakSlots?.push(slot);
        start?.add(30, "minutes");
      }
    });
    return newPeakSlots;
  };

  /**
   * Handle the Accordian expansion or collapse
   *
   * @function
   * @returns void
   */
  const handleAccordianChange =
    (courtIndex: number, day: string, panel: string) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded((prevExpanded) => ({
        ...prevExpanded,
        [day]: isExpanded ? panel : false,
      }));
    };

  const arrangeSlots = async (updatedWorkingHours: any) => {
    return updatedWorkingHours?.map((ele: any, index: number) => {
      if (ele.isChecked) {
        ele.slots = generateTimeSlots(ele?.startTime, ele?.endTime);

        if (ele.isPeak) {
          ele.peakSlots = calculatePeakSlots(index);
        }
      }
      return ele;
    });
  };

  // Add a new function to handle Checkbox change
  const handleCheckboxChange = async (
    courtIndex: number,
    dayIndex: number,
    checked: boolean,
    setFieldValue: any
  ) => {
    const updatedWorkingHours = [...values?.courts?.[courtIndex]?.workingHours];

    if (checked) {
      // If checkbox is checked, use the modified defaultWorkingHour
      updatedWorkingHours[dayIndex] = {
        ...defaultWorkingHour,
        day: updatedWorkingHours?.[dayIndex]?.day,
        startTime: values?.minTimeSlot || "09:00",
        endTime: values?.maxTimeSlot || "23:30",
        isChecked: true,
      };
    } else {
      // If checkbox is unchecked, use the defaultWorkingHour without modification
      updatedWorkingHours[dayIndex] = {
        day: updatedWorkingHours?.[dayIndex]?.day,
        startTime: values?.minTimeSlot || "00:00",
        endTime: values?.maxTimeSlot || "00:00",
        slots: [],
        peakSlots: [],
        peakHours: [],
        isChecked: false,
        isPeak: false,
      };
    }

    // Check if at least one day is selected
    const atLeastOneDaySelected = updatedWorkingHours.some(
      (hour: any) => hour?.isChecked
    );

    // If no day is selected, show error message or handle the validation as needed
    if (!atLeastOneDaySelected) {
      // You can display an error message or handle the validation as needed
      alert("Minimum one day working hours required");

      setFieldValue(`courts.[${courtIndex}].workingHours`, updatedWorkingHours);
    } else {
      let addSlotsInWorkingHours = await arrangeSlots(updatedWorkingHours);

      setFieldValue(
        `courts.[${courtIndex}].workingHours`,
        addSlotsInWorkingHours
      );
    }
  };

  const handleValidValue = (value: any, options: any) => {
    return options.includes(value) ? value : options[0] || ""; // Use first available option or empty string
  };

  return (
    <div>
      <Typography
        className="uppercase label"
        sx={{ margin: "1rem 0 1rem", color: "#818181" }}
      >
        {t("schedule")}
      </Typography>
      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{ marginTop: "15px", marginBottom: "1rem" }}
      >
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom sx={{ fontWeight: 500 }}>
            {t("weekday")}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom sx={{ fontWeight: 500 }}>
            {t("startTime")}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom sx={{ fontWeight: 500 }}>
            {t("lastAvailableSlots")}
          </Typography>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>

      <FieldArray
        name={`courts.[${courtIndex}].workingHours`}
        render={(arrayHelpers: any) => (
          <div>
            {values?.courts?.[courtIndex]?.workingHours?.length > 0 &&
              values?.courts?.[courtIndex]?.workingHours?.map(
                (hour: any, dayIndex: number) => {
                  return hour ? (
                    <>
                      <div key={hour?.day + courtIndex + dayIndex + "schedule"}>
                        <Grid container style={{ marginBottom: "10px" }}>
                          <Grid item xs={3}> 
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={hour?.isChecked} // Add a new property isChecked to WorkingHours interface
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    handleCheckboxChange(
                                      courtIndex,
                                      dayIndex,
                                      e.target.checked,
                                      setFieldValue
                                    )
                                  }}
                                />
                              }
                              //label={`${t(capitalize(hour?.day)?.substring(0, 3))}`}
                              label={capitalize(`${t(hour?.day)}`)}
                            />
                          </Grid>

                          <Grid item xs={3}>
                            <Select
                              label={t("startTime")}
                              //value={hour?.startTime || values?.minTimeSlot}
                              value={handleValidValue(
                                hour?.startTime || values?.minTimeSlot,
                                memoizedClubBasedSlots.map(
                                  (option: any) => option.props.value
                                )
                              )}
                              onChange={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleTimeChange(
                                  courtIndex,
                                  dayIndex,
                                  "startTime",
                                  e?.target?.value as string
                                )
                              }}
                              displayEmpty
                              inputProps={{ "aria-label": "Select start time" }}
                              disabled={!hour?.isChecked}
                              sx={{ minWidth: "90px" }}
                            >
                              {memoizedClubBasedSlots}
                            </Select>
                          </Grid>
                          <Grid item xs={3}>
                            <Select
                              label={t("endTime")}
                              //value={hour?.endTime || values?.maxTimeSlot}
                              value={handleValidValue(
                                hour?.endTime || values?.maxTimeSlot,
                                memoizedClubBasedSlots.map(
                                  (option: any) => option.props.value
                                )
                              )}
                              onChange={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleTimeChange(
                                  courtIndex,
                                  dayIndex,
                                  "endTime",
                                  e?.target?.value as string
                                )
                              }}
                              displayEmpty
                              inputProps={{ "aria-label": "Select end time" }}
                              disabled={!hour?.isChecked}
                              sx={{ minWidth: "90px" }}
                            >
                              {memoizedClubBasedSlots}
                            </Select>
                          </Grid>
                          <Grid item xs={3}>
                            <FormControlLabel
                              control={
                                <BlackSwitch
                                  checked={hour?.isPeak}
                                  onChange={(e) =>
                                    handleSwitchChange(
                                      courtIndex,
                                      dayIndex,
                                      e?.target?.checked
                                    )
                                  }
                                  disabled={!hour?.isChecked}
                                />
                              }
                              label={t("addPeakHours")}
                            />
                          </Grid>
                        </Grid>
                        {hour?.isPeak && (
                          <Accordion
                            key={"Accordion" + hour?.day + dayIndex}
                            elevation={0}
                            expanded={
                              expanded[hour.day] === `panel${dayIndex + 1}`
                            }
                            onChange={handleAccordianChange(
                              courtIndex,
                              hour.day,
                              `panel${dayIndex + 1}`
                            )}
                            defaultExpanded
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon
                                  style={{
                                    transform:
                                      expanded[hour.day] ===
                                      `panel${dayIndex + 1}`
                                        ? "rotate(180deg)"
                                        : "rotate(0deg)", // Adjusted rotation value when expanded
                                  }}
                                />
                              }
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              style={{ padding: "0px" }}
                            >
                              {" "}
                              <Typography
                                sx={{
                                  width: "33%",
                                  flexShrink: 0,
                                  marginRight: "24px",
                                }}
                              >
                                {t("peakHours")}
                              </Typography>{" "}
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: "0px" }}>
                              {hour?.peakHours?.map(
                                (peakHour: any, peakIndex: any) => (
                                  <Grid
                                    container
                                    key={"peakIndex" + peakIndex}
                                    style={{ marginTop: "5px" }}
                                  >
                                    <Grid item xs={5}>
                                      <Typography>{t("peakFrom")}</Typography>
                                      {/* Dropdown for Peak Start Time */}
                                      <Select
                                        value={peakHour.from}
                                        onChange={(e) =>
                                          handlePeakTimeChange(
                                            courtIndex,
                                            dayIndex,
                                            peakIndex,
                                            "from",
                                            e?.target?.value
                                          )
                                        }
                                        fullWidth
                                      >
                                        {getPeakTimeOptions(
                                          hour.startTime,
                                          hour.endTime
                                        ).map((option, key: number) => (
                                          <MenuItem
                                            key={`peakHour-from-${key}`}
                                            value={option}
                                          >
                                            {option}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      {dayIndex === errorMsg?.dayIndex &&
                                        peakIndex === errorMsg?.peakIndex &&
                                        errorMsg?.field === "from" && (
                                          <FormHelperText sx={{ color: "red" }}>
                                            {`Overlap time slot not allowed with this Peak Hour FROM time on ${capitalize(
                                              hour.day
                                            )}`}
                                          </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={5}>
                                      <Typography>{t("peakTo")}</Typography>
                                      {/* Dropdown for Peak End Time */}
                                      <Select
                                        value={peakHour?.to}
                                        onChange={(e) =>
                                          handlePeakTimeChange(
                                            courtIndex,
                                            dayIndex,
                                            peakIndex,
                                            "to",
                                            e.target.value
                                          )
                                        }
                                        fullWidth
                                      >
                                        {getPeakTimeOptions(
                                          hour.startTime,
                                          hour.endTime
                                        ).map((option, key: number) => (
                                          <MenuItem
                                            key={`peakHour-to-${key}`}
                                            value={option}
                                          >
                                            {option}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      {dayIndex === errorMsg?.dayIndex &&
                                        peakIndex === errorMsg?.peakIndex &&
                                        errorMsg?.field === "to" && (
                                          <FormHelperText sx={{ color: "red" }}>
                                            {`Overlap time slot not allowed with this Peak Hour TO time on ${capitalize(
                                              hour?.day
                                            )}`}
                                          </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={1} sx={{ padding: "0px" }}>
                                      <IconButton
                                        onClick={() =>
                                          handleDeleteRow(dayIndex, peakIndex)
                                        }
                                        style={{ marginLeft: "17px" }}
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              )}
                              {hour?.isPeak ? (
                                <Divider>
                                  {" "}
                                  <AddCircleOutlineIcon
                                    onClick={() => handleAddRow(dayIndex)}
                                    style={{ cursor: "pointer" }}
                                  />
                                </Divider>
                              ) : (
                                <></>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        )}
                        <Divider
                          style={{ marginTop: "5px", marginBottom: "5px" }}
                        ></Divider>
                      </div>
                    </>
                  ) : null;
                }
              )}
          </div>
        )}
      ></FieldArray>
    </div>
  );
};

export default CoachSchedule;
