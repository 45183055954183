import * as yup from "yup";
import { useCommonTranslations } from "./CommonTranslations";

export const AddClubValidationSchema = (step: number) => {
  // eslint-disable-next-line
  const { t } = useCommonTranslations();

  // let validationData = {};

  // if (step === 1) {
  //   validationData = {
  //     courts: yup.array().of(
  //       yup.object().shape({
  //         name: yup
  //           .string()
  //           .required(t("courtNameRequired"))
  //           .min(1, `${t("minimumOneLettersRequired")}`)
  //           .max(15, `${t("maximum15LettersAllowed")}`),
  //         offPeakHourPrice: yup
  //           .number()
  //           .required(t("courtOffPeakHourPriceRequired"))
  //           .test(
  //             "is-decimal",
  //             t("invalidOffPeakHourPrice"),
  //             (value: any) =>
  //               value === undefined ||
  //               value === null ||
  //               /^\d+(\.\d{1,2})?$/.test(value)
  //           ),
  //         onPeakHourPrice: yup
  //           .number()
  //           .required(t("courtOnPeakHourPriceRequired"))
  //           .test(
  //             "is-decimal",
  //             t("invalidOnPeakHourPrice"),
  //             (value: any) =>
  //               value === undefined ||
  //               value === null ||
  //               /^\d+(\.\d{1,2})?$/.test(value)
  //           ),
  //         offPeakOneAndHalfHourPrice: yup
  //           .number()
  //           .required(t("courtOffPeakHourPriceRequired"))
  //           .test(
  //             "is-decimal",
  //             t("invalidOffPeakHourPrice"),
  //             (value: any) =>
  //               value === undefined ||
  //               value === null ||
  //               /^\d+(\.\d{1,2})?$/.test(value)
  //           ),
  //         onPeakOneAndHalfHourPrice: yup
  //           .number()
  //           .required(t("courtOnPeakHourPriceRequired"))
  //           .test(
  //             "is-decimal",
  //             t("invalidOnPeakHourPrice"),
  //             (value: any) =>
  //               value === undefined ||
  //               value === null ||
  //               /^\d+(\.\d{1,2})?$/.test(value)
  //           ),
  //         offPeakTwoHourPrice: yup
  //           .number()
  //           .required(t("courtOffPeakHourPriceRequired"))
  //           .test(
  //             "is-decimal",
  //             t("invalidOffPeakHourPrice"),
  //             (value: any) =>
  //               value === undefined ||
  //               value === null ||
  //               /^\d+(\.\d{1,2})?$/.test(value)
  //           ),
  //         onPeakTwoHourPrice: yup
  //           .number()
  //           .required(t("courtOnPeakHourPriceRequired"))
  //           .test(
  //             "is-decimal",
  //             t("invalidOnPeakHourPrice"),
  //             (value: any) =>
  //               value === undefined ||
  //               value === null ||
  //               /^\d+(\.\d{1,2})?$/.test(value)
  //           ),
  //         services: yup.array().of(
  //           yup.object().shape({
  //             price: yup
  //               .number()
  //               .required("Price is required")
  //               .positive("Price must be a positive number")
  //               .test(
  //                 "is-decimal",
  //                 "Price must have at most two decimal places",
  //                 (value: any) =>
  //                   value === undefined ||
  //                   value === null ||
  //                   /^\d+(\.\d{1,2})?$/.test(value)
  //               ),
  //           })
  //         ),
  //       })
  //     ),
  //   };
  // }
  // return yup.object().shape(validationData);

  let validationData = [
    yup.object().shape({
      name: yup
        .string()
        .required(`${t("clubNameRequired")}`)
        .min(3, `${t("minimum3LettersAllowed")}`)
        .max(25, `${t("maximum25LettersAllowed")}`),
      email: yup
        .string()
        .email(`${t("invalidEmail")}`)
        .required(`${t("emailRequired")}`),
      countryCode: yup.string().required(`${t("countryCodeRequired")}`),
      phone: yup
        .string()
        .required(t("phoneNumberRequired"))
        .max(13, `${t("maximum13LettersAllowed")}`)
        .matches(/^\d+$/, t("invalidPhoneNumber"))
        .test(
          "not-start-with-zero",
          t("phoneShouldNotStartWithZero"),
          (value:any) => value && !/^0/.test(value)
        ),
      address1: yup.string().required(t("addressRequired")),
      city: yup.string().required(t("cityRequired")),
      state: yup.string().required(t("stateRequired")),
      country: yup.string().required(t("countryRequired")),
      category: yup.string().required(`${t("categoryRequired")}`),
    }),
    yup.object().shape({
      courts: yup.array().of(
        yup.object().shape({
          name: yup
            .string()
            .required(t("courtNameRequired"))
            .min(1, `${t("minimumOneLettersRequired")}`)
            .max(15, `${t("maximum15LettersAllowed")}`),
            // .test('is-unique', t("courtNameMustBeUnique"), function (value) {
            //   const { path, createError } = this;
    
            //   if (!value) return true;
    
            //   // Convert to lowercase for case-insensitive uniqueness check
            //   const lowercaseName = value?.toLowerCase();
    
            //   // Access the array of courts via `this.options.context`
            //   const courts = this?.options?.context?.courts || [];

            //   // Filter to remove the current court from the comparison
            //   const otherCourts = courts?.filter((court: any) => court?.name && court?.name?.toLowerCase() !== lowercaseName);
    
            //   // Check if there are any courts with the same name
            //   const isUnique = !otherCourts?.some((court: any) => court?.name?.toLowerCase() === lowercaseName);
    
            //   return isUnique || createError({ path, message: t("courtNameMustBeUnique") });
            // }),
          offPeakHourPrice: yup
            .number()
            .required(t("courtOffPeakHourPriceRequired"))
            .test(
              "is-decimal",
              t("invalidOffPeakHourPrice"),
              (value: any) =>
                value === undefined ||
                value === null ||
                /^\d+(\.\d{1,2})?$/.test(value)
            ),
          onPeakHourPrice: yup
            .number()
            .required(t("courtOnPeakHourPriceRequired"))
            .test(
              "is-decimal",
              t("invalidOnPeakHourPrice"),
              (value: any) =>
                value === undefined ||
                value === null ||
                /^\d+(\.\d{1,2})?$/.test(value)
            ),
          offPeakOneAndHalfHourPrice: yup
            .number()
            .required(t("courtOffPeakHourPriceRequired"))
            .test(
              "is-decimal",
              t("invalidOffPeakHourPrice"),
              (value: any) =>
                value === undefined ||
                value === null ||
                /^\d+(\.\d{1,2})?$/.test(value)
            ),
          onPeakOneAndHalfHourPrice: yup
            .number()
            .required(t("courtOnPeakHourPriceRequired"))
            .test(
              "is-decimal",
              t("invalidOnPeakHourPrice"),
              (value: any) =>
                value === undefined ||
                value === null ||
                /^\d+(\.\d{1,2})?$/.test(value)
            ),
          offPeakTwoHourPrice: yup
            .number()
            .required(t("courtOffPeakHourPriceRequired"))
            .test(
              "is-decimal",
              t("invalidOffPeakHourPrice"),
              (value: any) =>
                value === undefined ||
                value === null ||
                /^\d+(\.\d{1,2})?$/.test(value)
            ),
          onPeakTwoHourPrice: yup
            .number()
            .required(t("courtOnPeakHourPriceRequired"))
            .test(
              "is-decimal",
              t("invalidOnPeakHourPrice"),
              (value: any) =>
                value === undefined ||
                value === null ||
                /^\d+(\.\d{1,2})?$/.test(value)
            ),
          services: yup.array().of(
            yup.object().shape({
              price: yup
                .number()
                .required("Price is required")
                .positive("Price must be a positive number")
                .test(
                  "is-decimal",
                  "Price must have at most two decimal places",
                  (value: any) =>
                    value === undefined ||
                    value === null ||
                    /^\d+(\.\d{1,2})?$/.test(value)
                ),
            })
          ),
        })
      ),
    }),
    yup.object().shape({}),
    yup.object().shape({
      cancellationPeriodInHours: yup
        .string()
        .required(t("cancellationHoursRequired")),
      bookingPolicyPeriodInHours: yup
        .string()
        .required(t("bookingPolicyPeriodInHoursRequired")),
      minBookingTime: yup
        .string()
        .required(t("minBookingTimeRequired")),
      maxBookingAvailableDays: yup
        .string()
        .required(t("maxBookingAvailableDaysRequired")),
    }),
    yup.object().shape({}),
  ];
  return validationData[step];
};

export const EditClubValidationSchema = (step = 0) => {
  // eslint-disable-next-line
  const { t } = useCommonTranslations();

  let validationData = {};

  if (step === 0) {
    validationData = {
      //user: yup.string().required("User is required"),
      club: yup.string().required("Club is required"),
      court: yup.string().required("Court is required"),
      balanceAmount: yup.string().required("Amount Balance is required"),
    };
  } else if (step === 1) {
    validationData = {
      email: yup
        .string()
        .email(`${t("invalidEmail")}`)
        .required(`${t("emailRequired")}`),
    };
  } else if (step === 2) {
    validationData = {};
  }

  return yup.object().shape(validationData);
};
