import moment from "moment";

/**
 * Handle the generate time slots for the start and end time
 *
 * @function
 * @returns timeSlots array
 */
export const generateTimeSlots = (startTime: any, endTime: any, includeEndTimeSlot=true) => {
  const timeSlots = [];
  const format = "HH:mm";

  // Convert the start and end times to moment objects
  const start = moment(startTime, format);
  const end = moment(endTime, format);

  // Iterate from the start time to the end time in 30-minute intervals
  const current = start.clone();
  while (current.isBefore(end) || (includeEndTimeSlot && current.isSameOrBefore(end))) {
    timeSlots.push(current.format(format));
    current.add(30, "minutes");
  }

  return timeSlots;
};

/**
 * Handle the generate time slots for the start and end time
 *
 * @function
 * @returns timeSlots array
 */
export const generateIntermediateTimeSlots = (startTime: any, endTime: any) => {
  let timeSlots = [];
  const format = "HH:mm";

  // Convert the start and end times to moment objects
  const start = moment(startTime, format);
  const end = moment(endTime, format);

  // Iterate from the start time to the end time in 30-minute intervals
  const current = start.clone();
  while (current.isSameOrBefore(end)) {
    timeSlots.push(current.format(format));
    current.add(30, "minutes");
  }

  return timeSlots?.length > 1 ? timeSlots.slice(0, -1) : timeSlots;
};
