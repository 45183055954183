/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import {
  Modal,
  Box,
  Button,
  Divider,
  Paper,
  Slide,
  Grid,
  Typography,
  IconButton,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { FormTextField } from "app/shared/atoms";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { AddServiceValidationSchema } from "app/shared/validations/ExtraValidationSchema";
import { showToast } from "app/shared/util/toastHelper";
import { uploadImageToS3 } from "app/shared/util/ImageUploadApiService";
import { toast } from "react-toastify";

import { Form, Formik, FormikProps } from "formik";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import {
  useLocationQuery,
  useLocationQuerySet,
} from "app/shared/util/useLocationQuery";
import { AppDispatch } from "../../../redux/store";
import { defaultServiceValue } from "../../../model/extra-model";
import {
  createExtraService,
  updateExtraService,
  fetchExtraProductsList,
} from "../extrasApiService";
import ConfirmDialog from "app/shared/components/ConfirmDialog";

interface ModalFormProps {
  open: boolean;
  onClose: () => void;
  type?: any;
  action?: any;
  formData?: any;
  page?: any;
  refreshClubsGrid?: any;
  refresh?: any;
  setRefresh?: any;
  callback?: any;
  searchParams?: any;
}

const brownButtonStyle = {
  backgroundColor: "#333333",
  color: "white", // Text color
};

const BoldTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold", // Set the font weight to bold
}));

const AddServiceModalForm: React.FC<ModalFormProps> = ({
  open,
  onClose,
  formData,
  callback,
}) => {
  const { t } = useTranslation();
  const searchParams = useLocationQuery();
  const setQuery = useLocationQuerySet();
  const dispatch = useDispatch<AppDispatch>();
  const formikRef = useRef<FormikProps<any>>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [editData, setEditData] = useState<any>();

  // Set all the form data used for building the request object to the API finally
  useEffect(() => {
    if (formData?._id) {
      setEditData(formData);
    }
  }, [formData]);

  const showToastMessage = (message: any, type: any) => {
    showToast(message, type, { position: toast.POSITION.TOP_RIGHT });
  };

  const uploadImages = async (selectedFiles: any) => {
    try {
      const formData = new FormData();
      selectedFiles.forEach((file: any) => formData.append("files", file));

      const uploadImageAPIResponse = await uploadImageToS3(formData);
      return uploadImageAPIResponse;
    } catch (error) {
      // Handle error
      showToast(t("imageUploadedError"), "Error", {
        position: toast.POSITION.TOP_RIGHT,
      });
      throw error;
    }
  };

  const handleFileInputChange =
    (setFieldValue?: any) => async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const selectedFiles = Array.from(e.target.files);

        try {
          const response = await uploadImages(selectedFiles);

          if (response.statusCode === 200 || response.statusCode === 201) {
            const responseData = response?.data;

            if (responseData?.length > 0) {
              setFieldValue("image", responseData?.[0]?.Location?.Location);
            }

            showToastMessage(t("imageUploadedSuccessfully"), "Success");
          } else {
            showToastMessage(t("imageUploadedError"), "Error");
          }
        } catch (error) {
          showToastMessage(t("imageUploadedError"), "Error");
        }

        e.target.value = "";
      }
    };

  const handleImageRemove = (setFieldValue?: any) => {
    setFieldValue(`image`, null);
  };

  const getImageOrigionalName = (imageUrl: string) => {
    const originalName: any = imageUrl?.split("/")?.pop();
    return decodeURIComponent(originalName);
  };

  /**
   * Handle Next on button click to load the next step if second page call the api
   *
   * @function
   * @returns void
   */
  const handleSubmit = async (values?: any, actions?: any) => {
    let newValue = {
      ...values,
      price: parseFloat(values?.price),
    };
    if (newValue?.description === "") {
      delete newValue.description;
    }
    if (formData?._id) {
      delete newValue._id;

      dispatch(
        await updateExtraService(formData?._id, newValue, () => {
          if (typeof callback === "function") {
            callback();
          }
          onClose();
        })
      );
    } else {
      dispatch(
        await createExtraService(newValue, () => {
          onClose();
          setQuery({ ...searchParams, page: 1 });
          dispatch(
            fetchExtraProductsList({
              ...searchParams,
              page: 1,
              filter_type: "service",
            })
          );
        })
      );
    }

    actions.setSubmitting(false);
  };

  /**
   * Handle close on button click
   *
   * @function
   * @returns void
   */
  const handleCloseModal = (event?: any, reason?: any) => {
    if (formikRef?.current?.dirty) {
      // Show confirmation dialog if there are unsaved changes
      setConfirmOpen(true);
    } else {
      onClose();
    }
  };

    // Handle close confirmation dialog
    const handleCancelClose = () => {
      setConfirmOpen(false);
    };
  
    // Handle confirm close action
    const handleConfirmClose = () => {
      setConfirmOpen(false);
      onClose();
    };

  const handleOnChange =
    (objectKey: string, setFieldValue?: any) => (e: any) => {
      const val = e?.target?.value;
      setFieldValue(`${objectKey}`, val);
    };

  return (
    <><Modal
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: "rgba(0,0,0,0.5)",
          },
        },
      }}
    >
      <Slide in={open} direction="left">
        <Paper
          style={{
            position: "absolute",
            right: "0",
            transform: "translateY(-50%)",
            width: "35%",
            padding: "25px",
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Box>
            {/* Content of the sliding modal */}
            <BoldTypography variant="h6" style={{ marginBottom: "1rem" }}>
              {formData?._id ? t("editNewService") : t("addNewService")}
            </BoldTypography>
            <Divider />
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
              sx={{
                position: "absolute",
                top: "15px",
                right: "24px",
                cursor: "pointer",
              }}
            >
              <CloseIcon />
            </IconButton>
            <div>
              <Formik
                innerRef={formikRef}
                initialValues={
                  formData?._id
                    ? editData
                    : {
                        ...defaultServiceValue,
                      }
                }
                onSubmit={(values, actions) => {
                  handleSubmit(values, actions);
                }}
                enableReinitialize
                validationSchema={AddServiceValidationSchema()}
              >
                {({ values, errors, setFieldValue, touched }: any) => {
                  return (
                    <>
                      <Form>
                        <Grid
                          container
                          spacing={2}
                          style={{ marginTop: "10px" }}
                        >
                          <Grid item xs={12}>
                            <FormTextField
                              name="name"
                              label={t("serviceName")}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <FormTextField
                              name={`price`}
                              label={t("price")}
                              showPriceSymbol={true}
                            />
                          </Grid>
                        </Grid>

                        {values?.image ? (
                          <Box display="flex" flexWrap="wrap">
                            <Grid
                              container
                              spacing={2}
                              style={{ marginTop: "10px" }}
                              key={values?.image}
                              alignItems={"center"}
                            >
                              <Grid item xs={4}>
                                <img
                                  src={values?.image}
                                  alt={`Uploaded `}
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "180px",
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={7}
                                style={{
                                  fontWeight: 600,
                                  wordBreak: "break-all",
                                }}
                              >
                                {getImageOrigionalName(values?.image)}
                              </Grid>
                              <Grid item xs={1}>
                                <IconButton
                                  color="primary"
                                  onClick={() =>
                                    handleImageRemove(setFieldValue)
                                  }
                                  sx={{ float: "right" }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Box>
                        ) : (
                          <Grid
                            container
                            spacing={2}
                            style={{ marginTop: "10px" }}
                          >
                            <Grid item xs={4}>
                              <div>
                                <input
                                  type="file"
                                  id="fileInput"
                                  accept="image/*"
                                  multiple={false}
                                  style={{ display: "none" }}
                                  onChange={handleFileInputChange(
                                    setFieldValue
                                  )}
                                />
                                <label htmlFor="fileInput">
                                  <Button
                                    variant="outlined"
                                    component="span"
                                    sx={{
                                      width: "100%",
                                      minHeight: "189px",
                                      border: "1px solid #E0E0E0",
                                      padding: "51px 117px, 51px, 117px",
                                      display: "inline-block",
                                      textAlign: "center",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <CloudUploadIcon
                                      style={{
                                        marginTop: "35px",
                                        color: "black",
                                      }}
                                    />
                                    <Typography
                                      variant="button"
                                      display="block"
                                      sx={{
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      {t("clickToUpload")}{" "}
                                      <span style={{ color: "grey" }}>
                                        {t("dragDrop")}
                                      </span>
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      display="block"
                                      sx={{ color: "black" }}
                                    >
                                      {t("youCanUpload")}
                                    </Typography>
                                  </Button>
                                </label>
                              </div>
                            </Grid>
                          </Grid>
                        )}

                        <Divider
                          sx={{
                            marginTop: "1.5rem",
                            marginBottom: "1rem",
                          }}
                        />

                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <Typography
                              className="uppercase label"
                              sx={{
                                marginTop: "1rem",
                                marginBottom: "1rem",
                                fontSize: "12px",
                              }}
                            >
                              {t("description")} {t("optional")}
                            </Typography>
                            <TextareaAutosize
                              value={values?.description}
                              onChange={handleOnChange(
                                "description",
                                setFieldValue
                              )}
                              minRows={5}
                            />
                            <FormHelperText>
                              {touched?.productDetails &&
                              errors?.productDetails ? (
                                <span
                                  className="MuiFormHelperText-root Mui-error"
                                  style={{ color: "#d32f2f" }}
                                >
                                  {errors?.productDetails}
                                </span>
                              ) : (
                                ""
                              )}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            style={{ marginTop: "15px" }}
                          >
                            <Grid
                              item
                              xs={12}
                              container
                              justifyContent="flex-end"
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                style={brownButtonStyle}
                                type="submit"
                              >
                                {formData?._id
                                  ? t("saveChanges")
                                  : t("saveNewService")}
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </div>
          </Box>
        </Paper>
      </Slide>
    </Modal>
    
    {/* Confirmation dialog for unsaved changes */}
    <ConfirmDialog
        open={confirmOpen}
        onClose={handleCancelClose}
        onConfirm={handleConfirmClose}
      />
    
    </>
  );
};

export default AddServiceModalForm;
