import React, { useEffect, useState, useRef } from 'react';
import {
	Modal,
	Box,
	Button,
	Divider,
	Paper,
	Slide,
	Grid,
	Typography,
	IconButton,
	Container,
	CssBaseline,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import CustomStepper from '../../../shared/components/CustomStepper';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';
import ClubImages from './Steps/ClubImages';
import GeneralInformation from './Steps/GeneralInformation';
import Courts from './Steps/Courts';
import PoliciesInstructions from './Steps/PoliciesInstructions';
import {
	IGeneralInformation,
	defaultGeneralInformationValue,
	ISchedule,
	defaultScheduleValue,
	IPoliciesInstructions,
	defaultPoliciesInstructionsValue,
	IClubImages,
	defaultClubImagesValue,
	ICourts,
	defaultCourtsValue,
} from '../../../model/club-model';
import { AddClubValidationSchema } from 'app/shared/validations/ClubValidationSchema';
import { POST, PUT, CLUBS } from '../../../config/API.constants';
import { CUSTOM_ERROR_MSG, HANDLE_ERROR, HTTP_CALL } from '../../../config/http';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToast } from '../../../shared/util/toastHelper';
import { useTranslation } from 'react-i18next';
import { ActionTypes } from '../../../config/constants';
import Loader from 'app/shared/components/Loader';
import { AppDispatch } from '../../../redux/store';
import { useDispatch } from 'react-redux';
import { fetchTagsList } from '../../Tags/tagsApiService';
import { fetchExtraProductsList } from '../../Extras/extrasApiService';
import { extractDialCode } from 'app/shared/components/CountryCodeExtractor';
import getCountryNameByDialCode from 'app/shared/components/CountryNameExtractor';
import ConfirmDialog from 'app/shared/components/ConfirmDialog';
import CourtStatusChangeModal from '../modals/CourtStatusChangeModal';
import { SubmitButton } from 'app/shared/molecules';

interface ModalFormProps {
	open: boolean;
	onClose: () => void;
	features?: any;
	type?: any;
	action?: any;
	formData?: any;
	page?: any;
	refreshClubsGrid?: any;
	refresh?: any;
	setRefresh?: any;
	setUpdatedData?: any;
}

interface ImageData {
	attachments: any[];
}

const brownButtonStyle = {
	backgroundColor: '#333333',
	color: 'white', // Text color
};

const brownBackNextButtonStyle = {
	backgroundColor: '#FFFFFF',
	color: '#333333', // Text color
};

const BoldTypography = styled(Typography)(({ theme }) => ({
	fontWeight: 'bold', // Set the font weight to bold
}));

const getButtonText = (loading: any, action: any, activeStep: any, steps: any, t: any) => {
	if (loading) {
		return <Loader loading={loading} />;
	}

	if (action === ActionTypes?.CREATE && activeStep === steps.length - 1) {
		return t('saveNewClub');
	}

	if (action === ActionTypes?.EDIT && activeStep === steps.length - 1) {
		return t('updateClub');
	}

	switch (activeStep) {
		case 0:
			return t('continueToCourts');
		case 1:
			return t('continueToPoliciesInstructions');
		case 2:
			return t('continueToAttachments');
		default:
			return t('saveNewClub');
	}
};

const AddClubModalForm: React.FC<ModalFormProps> = ({
	open,
	onClose,
	features,
	type,
	action,
	formData,
	page,
	refreshClubsGrid,
	refresh,
	setRefresh,
	setUpdatedData,
}) => {
	const dispatch = useDispatch<AppDispatch>();
	const [activeStep, setActiveStep] = useState(0); // Step active step
	const [hasValues, setHasValues] = useState(false); // Form validation value check variable
	const { t } = useTranslation();
	const formikRef = useRef<FormikProps<any>>(null);
	const [confirmOpen, setConfirmOpen] = useState(false);
  const [courtStatusModalOpen, setCourtStatusModalOpen] = useState(false);
	const [selectedCourtIndex, setSelectedCourtIndex] = useState<any>();
	const [submitCount, setSubmitCount] = useState(0);
	const settingsData: any = localStorage.getItem('settings')
		? JSON.parse(localStorage.getItem('settings') as string)
		: null;

	// Each form data default value created through the data models
	const [generalInformationData, setGeneralInformationData] = useState<IGeneralInformation>(
		defaultGeneralInformationValue
	);
	const [scheduleData, setScheduleData] = useState<ISchedule>(defaultScheduleValue);
	const [courtsData, setCourtsData] = useState<ICourts>(defaultCourtsValue);
	const [policiesInstructionsData, setPoliciesInstructionsData] = useState<IPoliciesInstructions>(
		defaultPoliciesInstructionsValue
	);

	const [clubImagesData, setClubImagesData] = useState<IClubImages>(defaultClubImagesValue);

	const [modifiedCourtsData, setModifiedCourtsData] = useState<ICourts>(
		action === ActionTypes?.EDIT
			? {
					noOfCourts: formData?.courts?.length,
					courts: formData?.courts,
			  }
			: defaultCourtsValue
	);

	const [editData, setEditData] = useState<any>(null);
	const [categories, setCategories] = useState([]);
	const [servicesList, setServices] = useState([]);

	const steps = [
		t('generalInformation'),
		t('courts'),
		t('policiesInstructions'),
		t('clubImages'),
	];

	const [imgData, setImgData] = useState<ImageData[]>([{ attachments: [] }]);
	const [loading, setLoading] = useState(false);

	// Set all the form data used for building the request object to the API finally
	useEffect(() => {
		setGeneralInformationData({
			...generalInformationData,
		});
		setPoliciesInstructionsData({
			...policiesInstructionsData,
		});
		setClubImagesData({
			...clubImagesData,
		});
	}, [activeStep]); // Empty dependency array ensures the effect runs only once on mount

	useEffect(() => {
		if (open) {
			dispatch(
				fetchTagsList(
					{
						//filter_status: "active",
						filter_type: 'membership',
						pageSize: 1000,
					},
					(response: any) => {
						setCategories(response || []);
					}
				)
			);
			dispatch(
				fetchExtraProductsList(
					{
						//filter_status: "active",
						filter_type: 'service',
						pageSize: 1000,
					},
					(response: any) => {
						setServices(response || []);
					}
				)
			);
		}
	}, [open, dispatch]);

	useEffect(() => {
		if (formData?._id && action === ActionTypes?.EDIT) {
			setEditData({
				...formData,
				noOfCourts: formData?.courts?.length,
				countryCode: getCountryNameByDialCode(formData?.countryCode),
				bookingPolicyPeriodInHours:
					formData?.bookingPolicyPeriodInHours === 0
						? '0'
						: formData?.bookingPolicyPeriodInHours,
				features:
					formData?.features?.filter((x: any) => x)?.map((x: any) => x?._id || x) || [],
			});

			if (activeStep === 0) {
				setGeneralInformationData({
					name: formData?.name,
					address1: formData?.address1,
					city: formData?.city,
					state: formData?.state,
					country: formData?.country,
					email: formData?.email,
					phone: formData?.phone,
					countryCode: getCountryNameByDialCode(formData?.countryCode),
					features: formData?.features,
					type: formData?.type,
					category: formData?.category,
				});
			} else if (activeStep === 1) {
				setCourtsData({
					noOfCourts: formData?.courts?.length,
					courts: formData?.courts,
				});
			} else if (activeStep === 2) {
				setPoliciesInstructionsData({
					cancellationPeriodInHours: formData?.cancellationPeriodInHours,
					bookingPolicyPeriodInHours:
						formData?.bookingPolicyPeriodInHours === 0
							? '0'
							: formData?.bookingPolicyPeriodInHours,
					instructions: formData?.instructions,
					maxBookingAvailableDays: formData?.maxBookingAvailableDays,
					minBookingTime: formData?.minBookingTime
				});
			} else if (activeStep === 3) {
				setClubImagesData({
					attachments: formData?.attachments,
				});
				setImgData([
					{
						attachments: formData?.attachments || [],
					},
				]);
			}
		}
	}, [formData, action]);

	/**
	 * Checks form data for the valid data.
	 *
	 * @function
	 * @returns {Boolean} Boolean value of hasValues whether the validity is succcess or not
	 */

	const checkRequiredData = () => {
		if (activeStep === 0) {
			if (generalInformationData.type === '') {
				generalInformationData.type = 'padel';
			}
			if (
				generalInformationData.name === '' ||
				generalInformationData.address1 === '' ||
				generalInformationData.email === '' ||
				generalInformationData.phone === '' ||
				!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.*[A-Z]$/i.test(generalInformationData?.email)
			) {
				setHasValues(true);
				return false;
			} else {
				setHasValues(false);
				return true;
			}
		} else if (activeStep === 1) {
			if (modifiedCourtsData?.noOfCourts === 0 || modifiedCourtsData?.courts?.length === 0) {
				setHasValues(true);
				return false;
			} else if (modifiedCourtsData?.courts?.length !== 0) {
				const isValid = modifiedCourtsData?.courts?.every((court: any) => {
					return (
						court?.name?.trim() !== '' &&
						court?.offPeakHourPrice !== null &&
						court?.onPeakHourPrice !== null &&
						court?.onPeakHourPrice !== 0 &&
						court?.offPeakHourPrice !== 0 &&
						court?.onPeakHourPrice !== '' &&
						court?.offPeakHourPrice !== '' &&
						/^\d+(\.\d{0,2})?$/.test(court?.offPeakHourPrice) &&
						/^\d+(\.\d{0,2})?$/.test(court?.onPeakHourPrice) &&
						!Number.isNaN(court?.onPeakHourPrice) &&
						!Number.isNaN(court?.offPeakHourPrice) &&
						court?.playersAllowed > 0
					);
				});
				setHasValues(!isValid); // Set hasValues based on validation result
				return isValid;
			}

			// Add other step validations if needed
			return true; // Default to true for other steps
		} else if (activeStep === 3) {
			if (
				policiesInstructionsData.cancellationPeriodInHours === 0 ||
				parseInt(policiesInstructionsData?.bookingPolicyPeriodInHours) === 0 ||
				policiesInstructionsData.maxBookingAvailableDays === '' ||
				policiesInstructionsData.maxBookingAvailableDays === 0 ||
				isNaN(parseInt(policiesInstructionsData.maxBookingAvailableDays))
			) {
				setHasValues(true);
				return false;
			} else {
				setHasValues(false);
				return true;
			}
		} else {
			setHasValues(false);
			return true;
		}
	};

	useEffect(() => {
		// Call for the checkRequiredData method for each form values when changed
		checkRequiredData();
	}, [generalInformationData, modifiedCourtsData, policiesInstructionsData, clubImagesData]); // Dependence array of all the forms

	const hasDuplicateNames = (courtsArray: any) => {
		if (!Array.isArray(courtsArray) || courtsArray?.length === 0) {
			return false; // No duplicates if the array is empty or not provided
		}

		const nameCount = courtsArray?.reduce((acc: any, court: any) => {
			const name = court?.name?.toLowerCase(); // Convert to lowercase for case-insensitive comparison
			if (name) {
				acc[name] = (acc[name] || 0) + 1;
			}
			return acc;
		}, {});

		// Check if any name appears more than once
		return Object?.values(nameCount)?.some((count: any) => count > 1);
	};

	/**
	 * Handle submit with all the form data collected in each step
	 *
	 * @function
	 * @returns void
	 */
	const handleSubmit = async (
		e: React.FormEvent,
		action: any,
		activeStep?: any,
		values?: any
	) => {
		let courtNameExists = hasDuplicateNames(values?.courts || []);
		if (courtNameExists) {
			showToast(t('Club name should be unique'), 'Error', {
				position: toast.POSITION.TOP_RIGHT,
			});
			return;
		} else {
		setSubmitCount(submitCount + 1);
		// Add club
		if (action === ActionTypes?.CREATE) {
			const createANewClubAPI = CLUBS.ADD_CLUB;
			let response: any = {};
			try {
				generalInformationData.countryCode = extractDialCode(values?.countryCode);
				const params = {
					...generalInformationData,
					...scheduleData,
					...policiesInstructionsData,
					...clubImagesData,
					...values,
					countryCode:
						extractDialCode(values?.countryCode) || generalInformationData.countryCode,
					features: values?.features?.map((x: any) => {
						const findFeature: any = features?.find((y: any) => y?._id === x);
						return {
							description: findFeature?.description,
							title: findFeature?.title,
							fr_title: findFeature?.fr_title,
							sp_title: findFeature?.sp_title,
							_id: findFeature?._id,
						};
					}),
					attachments: values?.attachments?.map((x: any) => x?.imageUrl || x) || [],
					courts: values?.courts?.map((x: any, key: number) => ({ ...x, priority: key })),
				};
				setLoading(true);
				response = await HTTP_CALL(createANewClubAPI, POST, '', params);
				if (response.data.statusCode === 200) {
					if (response.data.data !== null) {
						showToast(t('newClubAddedSuccessfully'), 'Success', {
							position: toast.POSITION.TOP_RIGHT,
						});
						onClose();
					}
					setLoading(false);
					setSubmitCount(0);
				} else {
					CUSTOM_ERROR_MSG(
						response.data.message ? response.data.message : response.statusText
					);
				}
			} catch (error: any) {
				HANDLE_ERROR(error);
			} finally {
				setLoading(false); // Set loading to false in case of an error or completion
			}
		} // Edit club
		else {
			const createANewClubAPI = CLUBS.ADD_CLUB;
			let response: any = {};
			let params: any = {};
			if (activeStep === 0) {
				params = {
					name: values?.name,
					address1: values?.address1,
					city: values?.city,
					state: values?.state,
					country: values?.country,
					email: values?.email,
					phone: values?.phone,
					countryCode: extractDialCode(values?.countryCode),
					type: values?.type,
					category: values?.category,
					features: values?.features?.map((x: any) => {
						const findFeature: any = features?.find((y: any) => y?._id === x);
						return {
							description: findFeature?.description,
							title: findFeature?.title,
							fr_title: findFeature?.fr_title,
							sp_title: findFeature?.sp_title,
							_id: findFeature?._id,
						};
					}),
				};
			} else if (activeStep === 1) {
				params = {
					courts: values?.courts?.map((x: any, key: number) => ({ ...x, priority: key })),
					noOfCourts: values?.noOfCourts,
					minTimeSlot: values?.minTimeSlot,
					maxTimeSlot: values?.maxTimeSlot,
				};
			} else if (activeStep === 2) {
				params = {
					cancellationPeriodInHours: values?.cancellationPeriodInHours,
					bookingPolicyPeriodInHours:
						values?.bookingPolicyPeriodInHours === '0'
							? parseInt(values?.bookingPolicyPeriodInHours)
							: values?.bookingPolicyPeriodInHours,
					instructions: values?.instructions,
					maxBookingAvailableDays: values?.maxBookingAvailableDays,
					minBookingTime: parseInt(values?.minBookingTime)
				};
			} else if (activeStep === 3) {
				params = {
					attachments: values?.attachments?.map((x: any) => x?.imageUrl || x) || [],
				};
			}

			// Add club
			if (action === ActionTypes?.CREATE) {
				const createANewClubAPI = CLUBS.ADD_CLUB;
				let response: any = {};
				try {
					setLoading(true);
					generalInformationData.countryCode = extractDialCode(values?.countryCode);
					const params = {
						...generalInformationData,
						...scheduleData,
						...policiesInstructionsData,
						...clubImagesData,
						...values,
						countryCode:
							extractDialCode(values?.countryCode) ||
							generalInformationData?.countryCode,
						features: values?.features?.map((x: any) => {
							const findFeature: any = features?.find((y: any) => y?._id === x);
							return {
								description: findFeature?.description,
								title: findFeature?.title,
								fr_title: findFeature?.fr_title,
								sp_title: findFeature?.sp_title,
								_id: findFeature?._id,
							};
						}),
						attachments: values?.attachments?.map((x: any) => x?.imageUrl || x) || [],
						courts: values?.courts?.map((x: any, key: number) => ({
							...x,
							priority: key,
						})),
					};

					response = await HTTP_CALL(createANewClubAPI, POST, '', params);
					if (response.data.statusCode === 200) {
						if (response.data.data !== null) {
							showToast(t('newClubAddedSuccessfully'), 'Success', {
								position: toast.POSITION.TOP_RIGHT,
							});
							onClose();
						}
						setLoading(false);
						setSubmitCount(0);
					} else {
						CUSTOM_ERROR_MSG(
							response.data.message ? response.data.message : response.statusText
						);
					}
				} catch (error: any) {
					HANDLE_ERROR(error);
				} finally {
					setLoading(false); // Set loading to false in case of an error or completion
				}
			} // Edit club
			else {
				const createANewClubAPI = CLUBS.ADD_CLUB;
				let response: any = {};
				let params: any = {};
				if (activeStep === 0) {
					params = {
						name: values?.name,
						address1: values?.address1,
						city: values?.city,
						state: values?.state,
						country: values?.country,
						email: values?.email,
						phone: values?.phone,
						countryCode: extractDialCode(values?.countryCode),
						type: values?.type,
						category: values?.category,
						features: values?.features?.map((x: any) => {
							const findFeature: any = features?.find((y: any) => y?._id === x);
							return {
								description: findFeature?.description,
								title: findFeature?.title,
								fr_title: findFeature?.fr_title,
								sp_title: findFeature?.sp_title,
								_id: findFeature?._id,
							};
						}),
					};
				} else if (activeStep === 1) {
					params = {
						courts: values?.courts?.map((x: any, key: number) => ({
							...x,
							priority: key,
						})),
						noOfCourts: values?.noOfCourts,
						minTimeSlot: values?.minTimeSlot,
						maxTimeSlot: values?.maxTimeSlot,
					};
				} else if (activeStep === 2) {
					params = {
						cancellationPeriodInHours: values?.cancellationPeriodInHours,
						bookingPolicyPeriodInHours:
							values?.bookingPolicyPeriodInHours === '0'
								? parseInt(values?.bookingPolicyPeriodInHours)
								: values?.bookingPolicyPeriodInHours,
						instructions: values?.instructions,
						maxBookingAvailableDays: values?.maxBookingAvailableDays,
					};
				} else if (activeStep === 3) {
					params = {
						attachments: values?.attachments?.map((x: any) => x?.imageUrl || x) || [],
					};
				}

				try {
					response = await HTTP_CALL(
						createANewClubAPI + '/' + (formData?._id || formData?.id),
						PUT,
						'',
						{ ...params }
					);
					if (response?.data?.statusCode === 200) {
						if (response?.data?.data !== null) {
							showToast(t('clubUpdatedSuccessfully'), 'Success', {
								position: toast.POSITION.TOP_RIGHT,
							});
							if (activeStep === 3) {
								onClose();
								setGeneralInformationData(defaultGeneralInformationValue);
								setScheduleData(defaultScheduleValue);
								setCourtsData(defaultCourtsValue);
								setPoliciesInstructionsData(defaultPoliciesInstructionsValue);
								setClubImagesData(defaultClubImagesValue);
								setActiveStep(0);
								if (page !== 'clubDetail') {
									refreshClubsGrid(0, 100);
								} else {
									setRefresh(true);
								}
							}
						}
					} else {
						CUSTOM_ERROR_MSG(
							response?.data?.message ? response?.data?.message : response?.statusText
						);
						setLoading(false);
					}
				} catch (error: any) {
					HANDLE_ERROR(error);
					setLoading(false);
				}
			}
		}
	}};

	/**
	 * Handle Next on button click to load the next step
	 *
	 * @function
	 * @returns void
	 */
	const handleNext = (e: React.FormEvent, action: any, activeStep?: any, values?: any) => {
		// if (activeStep === 2 && !checkRequiredData()) {
		//   // Do not proceed if there are validation errors in step 2
		//   showToast("Please fill all the court fields", "Error", {
		//     position: toast.POSITION.TOP_RIGHT,
		//   });
		//   return;
		// }
		let courtNameExists = hasDuplicateNames(values?.courts || []);

		if (activeStep === 1 && courtNameExists) {
			showToast(t('Club name should be unique'), 'Error', {
				position: toast.POSITION.TOP_RIGHT,
			});
			return;
		} else {
			if (action === ActionTypes?.CREATE) {
				setActiveStep((prevStep) => prevStep + 1);
			} else {
				setActiveStep((prevStep) => prevStep + 1);
				handleSubmit(e, ActionTypes?.EDIT, activeStep, values);
			}
		}

		// Set touched to true when moving from the courts step
		// if (activeStep === 2) {
		//   setTouched(true);
		// }
	};

	/**
	 * Handle back on button click to load the previous step
	 *
	 * @function
	 * @returns void
	 */
	const handleBack = () => {
		setActiveStep((prevStep) => prevStep - 1);
	};

	/**
	 * Handle close on button click
	 *
	 * @function
	 * @returns void
	 */
	const handleCloseModal = (event?: any, reason?: any) => {
		if (formikRef?.current?.dirty) {
			setConfirmOpen(true);
		} else {
			onClose();
			setGeneralInformationData(defaultGeneralInformationValue);
			setScheduleData(defaultScheduleValue);
			setCourtsData(defaultCourtsValue);
			setPoliciesInstructionsData(defaultPoliciesInstructionsValue);
			setClubImagesData(defaultClubImagesValue);
			setActiveStep(0);
			if (page !== 'clubDetail') {
				refreshClubsGrid(0, 100);
			} else {
				setRefresh(true);
			}
		}
	};

	// Handle close confirmation dialog
	const handleCancelClose = () => {
		setConfirmOpen(false);
	};

	// Handle confirm close action
	const handleConfirmClose = () => {
		setConfirmOpen(false);
		onClose();
	};

  const courtStatusChangeModal = (courtIndex?: any) => {
		setSelectedCourtIndex(courtIndex);
		setCourtStatusModalOpen(true);
	};

	/**
	 * Render the forms on each step
	 *
	 * @function
	 * @returns void
	 */
	const renderStep = (step: number, formProps: any) => {
		switch (step) {
			case 0:
				return (
					<GeneralInformation
						features={features}
						type={type}
						hasValues={hasValues}
						setHasValues={setHasValues}
						action={action}
						formProps={formProps}
						categories={categories}
					/>
				);
			case 1:
				return (
					<Courts
						hasValues={hasValues}
						setHasValues={setHasValues}
						action={action}
						formProps={formProps}
						servicesList={servicesList}
						key={'courtslist'}
            			courtStatusChange={courtStatusChangeModal}
					/>
				);
			case 2:
				return <PoliciesInstructions formProps={formProps} />;
			case 3:
				return (
					<Container component="main" maxWidth="md">
						<CssBaseline />
						<ClubImages
							clubImagesData={clubImagesData}
							setClubImagesData={setClubImagesData}
							hasValues={hasValues}
							setHasValues={setHasValues}
							action={action}
							formData={formData?.attachments}
							imgData={imgData}
							setImgData={setImgData}
							formProps={formProps}
						/>
					</Container>
				);
			default:
				return null;
		}
	};

	return (
		<>
			<Modal
				open={open}
				onClose={handleCloseModal}
				closeAfterTransition
				slotProps={{
					backdrop: {
						style: {
							backgroundColor:
								action === ActionTypes?.CREATE
									? 'none'
									: page !== 'clubDetail'
									? 'rgba(0,0,0,0.1)'
									: 'rgba(0,0,0,0.5)',
						},
					},
				}}
			>
				<Slide in={open} direction="left" timeout={{ enter: 300, exit: 300 }}>
					<Paper
						style={{
							position: 'absolute',
							right: '0',
							transform: 'translateY(-50%)',
							width: '35%',
							padding: '20px',
							height: '100vh',
							overflow: 'auto',
						}}
					>
						<Box>
							{/* Content of the sliding modal */}
							<BoldTypography variant="h6">
								{action === ActionTypes?.CREATE ? t('addANewClub') : t('editClub')}
							</BoldTypography>
							<Divider />
							<IconButton
								edge="end"
								color="inherit"
								onClick={handleCloseModal}
								aria-label="close"
								sx={{
									position: 'absolute',
									top: '15px',
									right: '24px',
									cursor: 'pointer',
								}}
							>
								<CloseIcon />
							</IconButton>
							<div>
								<p style={{ marginBottom: '20px' }}></p>
								<CustomStepper activeStep={activeStep} steps={steps} />
								<p style={{ marginBottom: '20px' }}></p>
								<Divider />
								<p style={{ marginBottom: '20px' }}></p>

								{activeStep < steps.length && (
									<Formik
										innerRef={formikRef}
										initialValues={
											formData?._id
												? editData
												: {
														...defaultGeneralInformationValue,
														...defaultCourtsValue,
														cancellationPeriodInHours:
															settingsData?.autoBookingCancelInHours,
														bookingPolicyPeriodInHours:
															settingsData?.minAllowTimeToBookACoaching,
														maxBookingAvailableDays:
															settingsData?.maxAllowTimeToBookACourt,
												  }
										}
										onSubmit={(values, actions) => {}}
										enableReinitialize
										validationSchema={AddClubValidationSchema(activeStep)}
									>
										{(formProps: any) => {
											return (
												<Form>
													<div>
														{/* Content for each step */}
														{activeStep === 0 &&
															renderStep(activeStep, formProps)}
														{activeStep === 1 &&
															renderStep(activeStep, formProps)}
														{activeStep === 2 &&
															renderStep(activeStep, formProps)}
														{activeStep === 3 &&
															renderStep(activeStep, formProps)}
													</div>
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between',
														}}
													>
														<Grid
															container
															spacing={2}
															alignItems="center"
															style={{ marginTop: '15px' }}
														>
															<Grid item xs={5}>
																<Button
																	disabled={activeStep === 0}
																	style={brownBackNextButtonStyle}
																	onClick={handleBack}
																>
																	<ArrowBack /> {t('back')}
																</Button>
															</Grid>
															<Grid
																item
																xs={7}
																container
																justifyContent="flex-end"
															>
																<SubmitButton
																	disabled={loading}
																	// variant="contained"
																	// color="primary"
																	// style={brownButtonStyle}
																	onClick={
																		activeStep === 3
																			? (e:any) => {
																					handleSubmit(
																						e,
																						action,
																						activeStep,
																						formProps?.values
																					);
																			  }
																			: (e:any) => {
																					formProps
																						.validateForm()
																						.then(
																							(
																								errors: any
																							) => {
																								if (
																									isEmpty(
																										errors
																									)
																								) {
																									handleNext(
																										e,
																										action,
																										activeStep,
																										formProps?.values
																									);
																								} else {
																									formProps.submitForm();
																								}
																							}
																						);
																			  }
																	}
																>
																	{getButtonText(
																		loading,
																		action,
																		activeStep,
																		steps,
																		t
																	)}{' '}
																</SubmitButton>
															</Grid>
														</Grid>
													</div>
                          							{courtStatusModalOpen && (
														<CourtStatusChangeModal
															openModal={courtStatusModalOpen}
															onClose={() => {
																setCourtStatusModalOpen(false);
															}}
															id={
																formProps?.values?.courts?.[
																	selectedCourtIndex
																]?._id ||
																formProps?.values?.courts?.[
																	selectedCourtIndex
																]?.court
															}
															status={
																formProps?.values?.courts?.[
																	selectedCourtIndex
																]?.status
															}
															entityName="court"
															modalTxt="court"
															refreshGrid={() => {
																if (setRefresh)
																	setRefresh(!refresh);
															}}
															selectedCourtIndex={selectedCourtIndex}
															setFieldValue={formProps?.setFieldValue}
														/>
													)}
												</Form>
											);
										}}
									</Formik>
								)}
							</div>
						</Box>
					</Paper>
				</Slide>
			</Modal>
			{/* Confirmation dialog for unsaved changes */}
			<ConfirmDialog
				open={confirmOpen}
				onClose={handleCancelClose}
				onConfirm={handleConfirmClose}
			/>
		</>
	);
};

export default AddClubModalForm;
