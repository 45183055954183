import { styled } from "@mui/material/styles";
import { Switch } from "@mui/material";

export const BlackSwitch = styled(Switch)(({ checked }) => ({
  "& .MuiSwitch-switchBase": {
    "& + .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: checked ? "#333333" : "#727272",
    },
    "& .MuiSwitch-thumb": {
      color: checked ? "#333333" : "#727272", // Set to black when checked, grey when unchecked
      backgroundColor: "#fff",
    },
  },
}));

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 44,
  height: 24,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 20,
      height: 20,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(12px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#333333",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 20,
    height: 20,
    borderRadius: 10,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "#727272",
    boxSizing: "border-box",
  },
}));
