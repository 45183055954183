export interface IGeneralInformation {
  name: string;
  clubs: [];
  variants: [];
  productPriceToShow: number | null;
  status: string;
  description: string;
  attachments: [];
  showToUser: boolean;
  taxPercentage: any;
  showSizeOption: boolean;
  applyVat: boolean;
}

export const defaultGeneralInformationValue: Readonly<IGeneralInformation> = {
  name: "",
  clubs: [],
  variants: [],
  productPriceToShow: null,
  status: "active",
  description: "",
  attachments: [],
  showToUser: true,
  taxPercentage: 0,
  showSizeOption: true,
  applyVat: false,
};

export interface IService {
  name: string;
  price: any;
  description: string;
}

export const defaultServiceValue: Readonly<IService> = {
  name: "",
  price: "",
  description: "",
};
export interface IPurchaseInformation {
  orderDate: string;
  club: "";
  products: [];
  orderDetails: any;
  user: string;
  status: string;
  totalOfSubTotalAmount: number;
  totalPayableAmount: number;
  totalTaxAmount: number;
  actualTotalOfSubTotalAmount?: number;
}

export const defaultExtraProductPurchaseValue: Readonly<IPurchaseInformation> =
  {
    orderDate: "",
    club: "",
    products: [],
    orderDetails: null,
    user: "",
    status: "active",
    totalOfSubTotalAmount: 0,
    totalPayableAmount: 0,
    totalTaxAmount: 0,
    actualTotalOfSubTotalAmount: 0,
  };
