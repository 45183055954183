import React from "react";
import { Typography, FormControl } from "@mui/material";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { useTranslation } from "react-i18next";
import {
  settingMinHours,
  courtSettingMinHours,
  minBookingHours
} from "../../../../config/constants";
import { FormTextField, FormSelectField } from "app/shared/atoms";
import { showToast } from "app/shared/util/toastHelper";
interface PoliciesInstructionsProps {
  formProps?: any;
}

const PoliciesInstructions: React.FC<PoliciesInstructionsProps> = ({
  formProps,
}) => {
  const { values, setFieldValue, setErrors } = formProps;
  const { t } = useTranslation();

  const handleMaxBookingDaysChange = (value: string) => {
    const isValidNumber = /^\d{1,2}$/.test(value); // Allowing 1 or 2 digits
    const parsedValue = parseInt(value);
    if (isValidNumber && parsedValue >= 0 && parsedValue <= 60) {
      setFieldValue("maxBookingAvailableDays", parsedValue);
      setErrors({}); // Clear any previous errors
    } else {
      setFieldValue("maxBookingAvailableDays", ""); // Clear the field value
      setErrors({
        maxBookingAvailableDays:
          "Invalid input. Please enter a number between 0 and 60.",
      });
      if (value !== "") {
        showToast(
          "Invalid input. Please enter a number between 0 and 60.",
          "Error"
        );
      }
    }
  };

  return (
    <div>
      <Typography variant="body2" gutterBottom>
        <strong>{t("policies")}</strong>
      </Typography>
      <FormControl fullWidth>
        <Typography
          variant="body2"
          gutterBottom
          style={{ color: "grey", textTransform: "uppercase" }}
        >
          {t("clubCancellationPeriod")}
        </Typography>
        <Typography variant="body2" gutterBottom style={{ color: "grey" }}>
          {t("timeAllowedToCancelBooking")}{" "}
        </Typography>

        <FormSelectField
          name="cancellationPeriodInHours"
          options={settingMinHours?.map(
            (policyHour: any) =>
              (policyHour = {
                label: `${policyHour} hrs`,
                value: policyHour,
              })
          )}
          value={values?.cancellationPeriodInHours}
        />
      </FormControl>
      <FormControl fullWidth style={{ marginTop: "15px" }}>
        <Typography variant="body2" gutterBottom style={{ color: "grey" }}>
          {t("clubBookingPolicy")}
        </Typography>
        <Typography variant="body2" gutterBottom style={{ color: "grey" }}>
          {t("timeAllowedToMakePrivateBooking")}{" "}
        </Typography>

        <FormSelectField
          name="bookingPolicyPeriodInHours"
          // options={courtSettingMinHours?.map(
          //   (policyHour: any) =>
          //     (policyHour = {
          //       label: `${policyHour} hrs`,
          //       value: policyHour,
          //     })
          // )}
          options={courtSettingMinHours?.map(
            (x: any) =>
              (x = {
                label: `${x} Hour${x > 1 ? "s" : ""}`,
                value: x,
              })
          )}
          value={values?.bookingPolicyPeriodInHours}
        />
      </FormControl>

      <FormControl fullWidth style={{ marginTop: "15px" }}>
        <Typography variant="body2" gutterBottom style={{ color: "grey" }}>
          {t("timeAllowedToMakePublicBooking")}{" "}
        </Typography>

        <FormSelectField
          name="minBookingTime"
          options={minBookingHours?.map(
            (x: any) =>
              (x = {
                label: `${x} Hour${x > 1 ? "s" : ""}`,
                value: x,
              })
          )}
          value={values?.minBookingTime}
          disabled={true}
        />
      </FormControl>

      <FormControl fullWidth style={{ marginTop: "15px" }}>
        <Typography variant="body2" gutterBottom style={{ color: "grey" }}>
          {t("maximumBookingAvailableDays")}
        </Typography>
        <FormTextField
          name="maxBookingAvailableDays"
          customCallback={(value) => handleMaxBookingDaysChange(value)}
          value={values?.maxBookingAvailableDays}
          defaultValue={values?.maxBookingAvailableDays}
        />
      </FormControl>
      <FormControl fullWidth style={{ marginTop: "15px" }}>
        <Typography variant="body2" gutterBottom>
          <strong>{t("instructions")}</strong>
          <span style={{ color: "grey" }}>{t("optional")}</span>
        </Typography>
        <TextareaAutosize
          value={values?.instructions}
          onChange={(e: any) => {
            setFieldValue("instructions", e?.target?.value);
          }}
          minRows={5}
          style={{ marginTop: "15px" }}
        />
      </FormControl>
    </div>
  );
};

export default PoliciesInstructions;
