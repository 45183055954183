export interface IGeneralInformation {
  id?: any;
  name?: any;
  address1?: any;
  address2?: any;
  city?: any;
  state?: any;
  country?: any;
  email?: any;
  phone?: any;
  countryCode?: any;
  features?: any;
  type?: any;
  location?: any;
  category?: any;
}

export const defaultGeneralInformationValue: Readonly<IGeneralInformation> = {
  id: undefined,
  name: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  country: "",
  email: "",
  phone: "",
  countryCode: "",
  features: [],
  type: "",
  location: { type: "Point", coordinates: [80.2416, 12.9538] },
  category: "",
};

export interface ISchedule {
  day?: any;
  startTime?: any;
  endTime?: any;
  slots?: any;
  peakSlots?: any;
  workingHours?: any;
  peakHours?: any;
  from?: any;
  to?: any;
}

export const defaultScheduleValue: Readonly<ISchedule> = {
  day: "",
  startTime: "",
  endTime: "",
  slots: [],
  peakSlots: [],
  workingHours: [],
  peakHours: [],
  from: "",
  to: "",
};

export interface ICourts {
  name?: any;
  image?: any;
  noOfCourts?: any;
  playersAllowed?: number;
  onPeakHourPrice?: number;
  offPeakHourPrice?: number;
  courts?: any;
  status?: any;
  minTimeSlot?: any;
  maxTimeSlot?: any;
}

export const defaultCourtsValue: Readonly<ICourts> = {
  //name: "",
  //image: "",
  noOfCourts: 1,
  minTimeSlot: "09:00",
  maxTimeSlot: "23:30",
  //playersAllowed: 0,
  //onPeakHourPrice: 0,
  //offPeakHourPrice: 0,
  //status: "active",
  // courts: [
  //   {
  //     name: undefined,
  //     image: "",
  //     playersAllowed: 4,
  //     onPeakHourPrice: null,
  //     offPeakHourPrice: null,
  //     onPeakOneAndHalfHourPrice: null,
  //     offPeakOneAndHalfHourPrice: null,
  //     onPeakTwoHourPrice: null,
  //     offPeakTwoHourPrice: null,
  //     status: "active",
  //     services: [],
  //     workingHours: [],
  //   },
  // ],
};

export interface IPoliciesInstructions {
  cancellationPeriodInHours?: any;
  bookingPolicyPeriodInHours?: any;
  instructions?: string;
  maxBookingAvailableDays?: any;
  minBookingTime?:any;
}

export const defaultPoliciesInstructionsValue: Readonly<IPoliciesInstructions> =
  {
    cancellationPeriodInHours: 12,
    bookingPolicyPeriodInHours: 24,
    instructions: "",
    maxBookingAvailableDays: 7,
    minBookingTime: 12,
  };

export interface IClubImages {
  attachments?: any;
}

export const defaultClubImagesValue: Readonly<IClubImages> = {
  attachments: [],
};
