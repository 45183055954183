export interface IGeneralInformation {
  name: string;
  type: string;
  value: string;
  //category: string;
  couponUse: string;
  usageCount: string;
  fromDate: any;
  toDate: any;
  status: string;
  clubs: [];
  cycles: number | null;
}

export const defaultGeneralInformationValue: Readonly<IGeneralInformation> = {
  name: "",
  type: "",
  value: "",
  //category: "",
  couponUse: "",
  usageCount: "1",
  fromDate: null,
  toDate: undefined,
  status: "active",
  clubs: [],
  cycles: null,
};
