export interface MembershipProductInformation {
  name: string;
  category: string;
  monthlyPrice: string;
  yearlyPrice: string;
  numberOfCoachingSessions: number | null;
  description: string;
  status: string;
  benefit: any;
}

export interface UserMembershipInformation {
  user: string;
  club: string;
  membership: string | any;
  category: string;
  price: string;
  amountPaid: string | any;
  coupon: string;
  paidAmount: string;
}

export const defaultMembershipProductValue: Readonly<MembershipProductInformation> =
  {
    name: "",
    category: "",
    monthlyPrice: "",
    yearlyPrice: "",
    status: "active",
    numberOfCoachingSessions: null,
    description: "",
    benefit: "",
  };

export const defaultUserMembershipValue: Readonly<UserMembershipInformation> = {
  user: "",
  club: "", 
  membership: undefined,
  category: "",
  price: "",
  amountPaid: undefined,
  coupon: "",
  paidAmount: "",
};
