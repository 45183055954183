import React, { useState } from "react";
import {
  Modal,
  Button,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { POST, BOOKING_MANAGEMENT } from "../../../config/API.constants";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {
  HANDLE_ERROR,
  HTTP_CALL,
  CUSTOM_ERROR_MSG,
} from "../../../config/http";
import { showToast } from "../../../shared/util/toastHelper";
import { isEmpty } from "lodash";

interface PaymentUpdateModalFormProps {
  open: boolean;
  onClose: () => void;
  selectedBookingIds: string[];
  totalPaidAmount?: any;
}

const paymentTypeBookingButtonStyle = {
  backgroundColor: "#333333",
  color: "white",
  marginRight: "10px",
  border: "1px solid #333333",
  height: "29px",
  borderRadius: "8px",
  padding: "4px 11px 4px 11px",
};

const nonPaymentTypeBookingButtonStyle = {
  backgroundColor: "white",
  color: "#333333",
  marginRight: "10px",
  border: "1px solid #bdbdbd",
  height: "29px",
  borderRadius: "8px",
  padding: "4px 11px 4px 11px",
  borderColor: "#bdbdbd",
};

const PaymentUpdateModalForm: React.FC<PaymentUpdateModalFormProps> = ({
  open,
  onClose,
  selectedBookingIds,
  totalPaidAmount,
}) => {
  const [paymentType, setPaymentType] = useState("");
  const [amount, setAmount] = useState(totalPaidAmount);
  const [isPaymentTypeSelected, setIsPaymentTypeSelected] = useState(false);

  const handlePaymentTypeChange = (selectedPaymentType: string) => {
    setPaymentType(selectedPaymentType);
    setIsPaymentTypeSelected(true); // Set to true when payment type is selected
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(parseFloat(event.target.value));
  };

  const handleMarkAsPaid = async () => {
    // Check if a payment type is selected
    if (!isPaymentTypeSelected) {
      // If no payment type is selected, show validation message
      showToast("Any one payment method needs to be selected", "Error", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; // Prevent further execution
    }
    // Make API call to update payment status
    const paymentUpdateAPI = BOOKING_MANAGEMENT.API_END_POINTS.PAYMENT_UPDATE;
    let response: any = {};
    try {
      let params = {
        bookingIds: selectedBookingIds,
        amount: amount,
        paymentType: paymentType?.toLowerCase(),
      };

      response = await HTTP_CALL(paymentUpdateAPI, POST, "", params);
      const {
        data: { data: apiData, statusCode, message },
      } = response;
      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          showToast(
            "Payment updated successfully for the selected bookings",
            "Success",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          onClose();
        } else {
          showToast(
            "Payment update failed for the selected bookings",
            "Error",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: "rgba(0,0,0,0.5)",
          },
        },
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          textAlign: "center",
          left: "50%",
          width: "25%",
          height: "33vh",
          borderRadius: "4px",
          transform: "translate(-50%, -50%)",
          padding: 16,
          background: "rgba(255, 255, 255, 0.9)",
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{
            position: "absolute",
            top: "15px",
            right: "24px",
            cursor: "pointer",
          }}
        >
          <CloseIcon />
        </IconButton>
        <h2 style={{ textAlign: "center" }}>Payment Type</h2>
        <Typography variant="subtitle1" style={{ textAlign: "center" }}>
          Select the type of payment for these bookings
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "10px",
            marginRight: "5px",
          }}
        >
          <Button
            variant="contained"
            onClick={() => handlePaymentTypeChange("Cash")}
            style={
              paymentType === "Cash"
                ? paymentTypeBookingButtonStyle
                : nonPaymentTypeBookingButtonStyle
            }
          >
            Cash
          </Button>
          <Button
            variant="contained"
            onClick={() => handlePaymentTypeChange("Card")}
            style={
              paymentType === "Card"
                ? paymentTypeBookingButtonStyle
                : nonPaymentTypeBookingButtonStyle
            }
          >
            Card
          </Button>
          <Button
            variant="contained"
            onClick={() => handlePaymentTypeChange("Subscription")}
            style={
              paymentType === "Subscription"
                ? paymentTypeBookingButtonStyle
                : nonPaymentTypeBookingButtonStyle
            }
          >
            Membership
          </Button>
          <Button
            variant="contained"
            onClick={() => handlePaymentTypeChange("Gymlib")}
            style={
              paymentType === "Gymlib"
                ? paymentTypeBookingButtonStyle
                : nonPaymentTypeBookingButtonStyle
            }
          >
            Gymlib
          </Button>
        </div>
        <TextField
          label="Amount"
          type="number"
          value={amount}
          onChange={handleAmountChange}
          fullWidth
          style={{ marginTop: "20px" }}
          disabled
        />
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleMarkAsPaid}
            style={
              !isPaymentTypeSelected
                ? nonPaymentTypeBookingButtonStyle
                : paymentTypeBookingButtonStyle
            }
            disabled={!isPaymentTypeSelected}
          >
            Mark as Paid
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentUpdateModalForm;
