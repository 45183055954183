import React, { useCallback, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
  },
  "& .MuiTextField-root": {
    marginTop: 0,
    marginBottom: 0,
  },
});

interface ICustomTextField {
  fullWidth?: any;
  name?: any;
  value?: any;
  label?: any;
  type?: any;
  onChange?: any;
  margin?: any;
  required?: any;
  error?: any;
  helperText?: any;
  inputProps?: any;
  InputProps?: any;
  disabled?: any;
  InputLabelProps?: any;
  placeholder?: any;
  style?: any;
  sx?: any;
  onFocus?: any;
  onClick?: any;
  autoComplete?: any;
  rows?: any;
  multiline?: any;
  onKeyUp?: any;
  onBlur?: any;
}

const StyledCustomTextField: React.FC<ICustomTextField> = ({
  fullWidth,
  name,
  value,
  label,
  type,
  onChange,
  margin,
  required,
  error,
  helperText,
  inputProps,
  InputProps,
  disabled,
  InputLabelProps,
  placeholder,
  style,
  sx,
  onFocus,
  onClick,
  autoComplete,
  rows,
  multiline,
  onKeyUp,
  onBlur,
}) => {
  const handleWheel = useCallback((event: WheelEvent) => {
    event.preventDefault();
  }, []);

  useEffect(() => {
    // Find the input element by name attribute to add a non-passive wheel listener
    const input: any = document.querySelector(`input[name='${name}']`);

    if (input) {
      input.addEventListener("wheel", handleWheel, { passive: false });
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (input) {
        input.removeEventListener("wheel", handleWheel);
      }
    };
  }, [handleWheel, name]);

  return (
    <StyledTextField
      fullWidth={fullWidth}
      name={name}
      value={value}
      label={label}
      type={type}
      onChange={onChange}
      margin={margin}
      required={required}
      error={error}
      helperText={helperText}
      inputProps={inputProps}
      InputProps={InputProps}
      disabled={disabled}
      InputLabelProps={InputLabelProps}
      placeholder={placeholder}
      style={style}
      sx={sx}
      onFocus={onFocus}
      onClick={onClick}
      autoComplete={autoComplete}
      rows={rows}
      multiline={multiline}
      onKeyUp={onKeyUp}
      onBlur={onBlur}
    />
  );
};

export default StyledCustomTextField;
