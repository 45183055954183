import * as yup from "yup";
import { useCommonTranslations } from "./CommonTranslations";
import { VALIDATION } from "app/config/constants";
import moment from "moment";

const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

const validateStartEndTime = (
  start: string | undefined | null,
  end: string | undefined | null
) => {
  const errors: string[] = [];

  if ((start && !end) || (!start && end)) {
    errors.push("Both start and end times must be provided");
  } else if (start && end) {
    const startTime = new Date(`01/01/2000 ${start}`);
    const endTime = new Date(`01/01/2000 ${end}`);

    if (startTime >= endTime) {
      errors.push("Start time must be before end time");
    }
  }

  return errors;
};

const timingSchema = yup
  .object()
  .shape({
    isPrivate: yup.boolean().required(),
  })
  .test(
    "start-end-validation",
    "Start and end time validation failed",
    function (value) {
      const { start, end } = this.parent;
      const errors = validateStartEndTime(start, end);
      return errors.length === 0;
    }
  );

const validateTiming = (timings: any, schema?: any) => {
  const validationErrors: any = [];

  timings?.forEach((timing: any, errorTimingIndex: number) => {
    const timingErrors: any = {};

    const { start, end } = timing;

    const isChecked = schema.parent?.isChecked; // Add a null check using optional chaining

    if (isChecked) {
      if (!start) {
        timingErrors.start = "Start time is required when checked";
      }
      if (!start && end) {
        timingErrors.start = "Start time is required when checked";
      }

      if (start && !end) {
        timingErrors.end = "End time is required when checked";
      }
    }

    if (start && !end) {
      timingErrors.end = "End time is required if start time is provided";
    }

    if (end && !start) {
      timingErrors.start = "Start time is required if end time is provided";
    }

    if (start && end) {
      const startTime = new Date(`01/01/2000 ${start}`);
      const endTime = new Date(`01/01/2000 ${end}`);

      if (startTime >= endTime) {
        timingErrors.start = "Start time must be before end time";
        timingErrors.end = "End time must be after start time";
      }

      // Check for overlapping time slots with other timing objects
      for (let i = 0; i < timings.length; i++) {
        if (i !== errorTimingIndex) {
          const otherStart = new Date(`01/01/2000 ${timings[i].start}`);
          const otherEnd = new Date(`01/01/2000 ${timings[i].end}`);

          if (
            (startTime >= otherStart && startTime < otherEnd) ||
            (endTime > otherStart && endTime <= otherEnd) ||
            (startTime <= otherStart && endTime >= otherEnd)
          ) {
            timingErrors.commonError =
              "Overlapping time slots are not allowed. Please check all times";
            break; // Stop checking for overlaps once one is found
          }
        }
      }
    }

    // Only add timing errors to the array if there are errors for this timing
    if (Object.keys(timingErrors).length > 0) {
      validationErrors.push({ ...timingErrors, errorTimingIndex });
    }
  });

  return validationErrors.length > 0 ? validationErrors : null;
};

const validateHoliday = (holidays?: any[], schema?: any) => {
  const validationErrors: any[] = [];

  holidays?.forEach((holiday: any, errorHolidayIndex: number) => {
    const holidayErrors: any = {};

    const { from, to } = holiday;

    if (from && to) {
      // Check for overlapping time slots with other holiday objects

      for (let i = 0; i < holidays.length; i++) {
        if (i !== errorHolidayIndex) {
          const event = holidays[i];
          const holidayFrom = moment.utc(from);
          const holidayTo = moment.utc(to);
          const eventFrom = moment.utc(event.from);
          const eventTo = moment.utc(event.to);

          if (
            (holidayFrom.isSameOrAfter(eventFrom) &&
              holidayFrom.isSameOrBefore(eventTo)) ||
            (holidayTo.isSameOrAfter(eventFrom) &&
              holidayTo.isSameOrBefore(eventTo))
          ) {
            holidayErrors.from = "Overlapping dates are not allowed";
            break; // Stop checking for overlaps once one is found
          }
        }
      }
    }

    if (Object.keys(holidayErrors).length > 0) {
      validationErrors[errorHolidayIndex] = holidayErrors;
    }
  });

  return validationErrors.length > 0 ? validationErrors : null;
};

export const AddCoachingValidationSchema = (activeStep = 0) => {
  // eslint-disable-next-line
  const { t } = useCommonTranslations();

  let validationData = [
    yup.object().shape({
      firstName: yup
        .string()
        .required(`${t("firstNameRequired")}`)
        .min(1, `${t("minimumOneLettersRequired")}`)
        .max(25, `${t("maximum25LettersAllowed")}`)
        .test("alphabets-only", t("AlphabetsOnly"), (val: any) => {
          return val === undefined || VALIDATION.ALPHABETS_SPACE.test(val);
        }),
      lastName: yup
        .string()
        .required(`${t("lastNameRequired")}`)
        .min(1, `${t("minimumOneLettersRequired")}`)
        .max(25, `${t("maximum25LettersAllowed")}`)
        .test("alphabets-only", t("AlphabetsOnly"), (val: any) => {
          return val === undefined || VALIDATION.ALPHABETS_SPACE.test(val);
        }),
      countryCode: yup.string().required(`${t("countryCodeRequired")}`),
      phone: yup
        .string()
        .required(t("phoneNumberRequired"))
        .max(13, `${t("maximum13LettersAllowed")}`)
        .matches(/^\d+$/, t("invalidPhoneNumber"))
        .test(
          "not-start-with-zero",
          t("phoneShouldNotStartWithZero"),
          (value:any) => value && !/^0/.test(value)
        ),
      email: yup
        .string()
        .email(`${t("invalidEmail")}`)
        .required(`${t("emailRequired")}`),
      // groupCoachingPricePerHour: yup
      //   .string()
      //   .required(`${t("groupCoachingPricePerHourRequired")}`)
      //   .test(
      //     "is-decimal",
      //     `${t("thePriceShouldbeDecimalwithMaximumTwoDigitsAfterComma")}`,
      //     (val: any) => {
      //       if (val !== undefined) {
      //         return patternTwoDigisAfterComma.test(val);
      //       }
      //       return true;
      //     }
      //   ),
      privateCoachingPricePerHour: yup
        .string()
        .required(`${t("PrivateCoachingPricePerHourRequired")}`)
        .test(
          "is-decimal",
          `${t("thePriceShouldbeDecimalwithMaximumTwoDigitsAfterComma")}`,
          (val: any) => {
            if (val !== undefined) {
              return patternTwoDigisAfterComma.test(val);
            }
            return true;
          }
        ),
      description: yup.string().required(`${t("descriptionRequired")}`),
    }),
    yup.object().shape({
      coachLevels: yup
        .array()
        .required(`${t("coachLevelRequired")}`)
        .min(1, "Minimum one required"),
      holidays: yup
        .array()
        .of(
          yup.object().shape({
            from: yup
              .string()
              .nullable()
              .test(
                "required",
                "From date required",
                function (value, context) {
                  const toDate = context.parent.to;
                  if (toDate && !value) return false; // If "to" date is filled, "from" date must be filled
                  return true;
                }
              ),
            to: yup
              .string()
              .nullable()
              .test("required", "To date required", function (value, context) {
                const fromDate = context.parent.from;
                if (fromDate && !value) return false; // If "from" date is filled, "to" date must be filled
                return true;
              })
              .test(
                "is-valid-date",
                "To date must be after or equal to from date",
                function (value, context) {
                  const fromDate = context.parent.from;
                  if (!fromDate || !value) return true;
                  return moment(value).isSameOrAfter(fromDate);
                }
              ),
          })
        )
        .test(
          "no-overlapping-date",
          "Validation failed",
          function (value, schema) {
            const validationErrors = validateHoliday(value, schema);
            if (validationErrors) {
              const errors: any = [validationErrors]; // Wrap the validationErrors in an array
              throw new yup.ValidationError(errors, undefined, this.path);
            }
            return true;
          }
        )
        .test(
          "unique-date",
          "From and to dates must be unique",
          function (value) {
            if (!value) return true;

            const seenDates = new Set();
            for (let i = 0; i < value.length; i++) {
              const holiday = value[i];
              const key = `${holiday.from}-${holiday.to}`;
              if (seenDates.has(key)) {
                return false; // Duplicate event
              }
              seenDates.add(key);
            }
            return true; // No duplicate found
          }
        ),
      availability: yup.array().of(
        yup.object().shape({
          timing: yup
            .array()
            .of(timingSchema)
            .test(
              "no-overlapping-time",
              "Validation failed",
              function (value, schema) {
                const validationErrors = validateTiming(value, schema);
                if (validationErrors) {
                  const errors: any = [validationErrors]; // Wrap the validationErrors in an array
                  throw new yup.ValidationError(errors, undefined, this.path);
                }
                return true;
              }
            ),
        })
      ),
    }),
  ];
  return validationData[activeStep];
};
