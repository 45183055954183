import { Dispatch, AnyAction } from "redux";
import { CUSTOM_ERROR_MSG, HANDLE_ERROR, HTTP_CALL } from "../../config/http";
import {
  GET,
  BOOKING_MANAGEMENT,
  PUT,
  POST,
  PATCH,
  DELETE,
} from "../../config/API.constants";
import { BookingFilterStatus } from "app/config/constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showToast } from "../../shared/util/toastHelper";
import { isEmpty } from "lodash";
import { transformFilters } from "app/shared/util/buildQuery";
import {
  bookingsRequested,
  bookingsReceived,
  bookingsReset,
  courtBookingsRequested,
  courtBookingsReceived,
} from "../../redux/bookingsReducer";

type AppDispatch = Dispatch<AnyAction>;

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const fetchBookingList =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      let qParams = {
        ...params,
        limit: params?.pageSize,
        sort: params?.sort || "createdAt|DESC",
        //filters: params?.bookingType || "bookingType|booking",
        filter_bookingType: "booking",
      };

      if(params?.filter_status === BookingFilterStatus.COMPLETED || params?.filter_status === BookingFilterStatus.SCHEDULED || params?.filter_status === BookingFilterStatus.INPROGRESS) {
        qParams.filter_currentStatus = qParams?.filter_status;
        delete qParams.filter_status;
      }

      // // Construct the filters object from the query parameter
      const filtersString: any = await transformFilters(qParams);
      const filteredSearchParams = new URLSearchParams(filtersString);

      if (!params?.isFilter) dispatch(bookingsRequested());

      response = await HTTP_CALL(
        `${
          BOOKING_MANAGEMENT.API_END_POINTS.GET_ALL_BOOKINGS
        }?${filteredSearchParams.toString()}`,
        GET,
        "",
        params
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      dispatch(bookingsReceived(response?.data));

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const fetchAllBookings = async (page: any, limit: any) => {
  const listBookingsAPI = BOOKING_MANAGEMENT.API_END_POINTS.GET_ALL_BOOKINGS;
  let response: any = {};
  const filterParamsObj = {
    page: page,
    limit: limit,
    filters: "bookingType%7Cbooking",
  };
  const filteredSearchParams = new URLSearchParams(filterParamsObj);
  try {
    response = await HTTP_CALL(
      `${listBookingsAPI}?${filteredSearchParams.toString()}`,
      GET
    );
    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (!isEmpty(apiData)) {
        return apiData;
      } else {
        showToast("No Data", "Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};

/**
 * Fetches booking detail data based on the booking id from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const fetchBookingDetailFromApi = async (id: any, apiName: string) => {
  let response: any = {};
  try {
    response = await HTTP_CALL(`${apiName}/${id}`, GET);
    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (!isEmpty(apiData)) {
        return apiData;
      } else {
        showToast("No Data", "Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};

/**
 * Update booking status
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const bookingStatusUpdate = async (id: any, status?: any) => {
  const bookingStatusUpdateApi =
    BOOKING_MANAGEMENT.API_END_POINTS.STATUS_UPDATE;
  let response: any = {};
  const params = {
    status: status,
  };
  try {
    response = await HTTP_CALL(
      `${bookingStatusUpdateApi}/${id}/status-update`,
      PUT,
      "",
      params
    );
    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (!isEmpty(apiData)) {
        showToast("Booking status updated successfully", "Success", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        showToast("No Data", "Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};

/**
 * Update booking status
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const bookingCancel = async (id: any, params?: any, callback?:any) => {
  const bookingStatusUpdateApi =
    BOOKING_MANAGEMENT.API_END_POINTS.STATUS_UPDATE;
  let response: any = {};
  try {
    response = await HTTP_CALL(
      `${bookingStatusUpdateApi}/${id}/status-update`,
      PUT,
      "",
      params
    );
    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (!isEmpty(apiData)) {
        showToast("Booking cancelled successfully", "Success", {
          position: toast.POSITION.TOP_RIGHT,
        });
        if (typeof callback === "function") {
          callback(apiData);
        }
      } else {
        showToast("No Data", "Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};

/**
 * Update booking status
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const bookingSlotAvailabilityCheck = async (
  date: any,
  club: any,
  court: any,
  slots: any
) => {
  const bookingSlotAvailabilityCheckApi =
    BOOKING_MANAGEMENT.API_END_POINTS.SLOT_AVAILABILITY;
  let response: any = {};
  const params = {
    date: date,
    club: club,
    court: court,
    slots: slots,
  };
  try {
    response = await HTTP_CALL(
      `${bookingSlotAvailabilityCheckApi}`,
      POST,
      "",
      params
    );
    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (!isEmpty(apiData)) {
        if (!isEmpty(apiData?.slots)) {
          showToast("Booking slots available", "Success", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return apiData?.slots;
        } else {
          showToast(
            "Booking slots not available for this court for the selected date, Please select a different court",
            "Success",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      } else {
        showToast("No Data", "Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};

/**
 * Update get time slots
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const getAvailableTimeSlots = async (
  date: any,
  club: any,
  court: any,
  showSuccessMessage=true
) => {
  const getAvailableTimeSlotsApi =
    BOOKING_MANAGEMENT.API_END_POINTS.SLOT_AVAILABILITY;
  let response: any = {};
  const params = {
    date: date,
    club: club,
    court: court,
  };
  try {
    response = await HTTP_CALL(`${getAvailableTimeSlotsApi}`, POST, "", params);
    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (!isEmpty(apiData)) {
        if (!isEmpty(apiData?.slots)) {
          if(showSuccessMessage)
            showToast("Booking slots available", "Success", {
              position: toast.POSITION.TOP_RIGHT,
            });
          return response?.data?.data?.slots;
        } else {
          showToast(
            "Booking slots not available for this court for the selected date, Please select a different court",
            "Error",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      } else {
        showToast("No Data", "Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};

/**
 * Update get courts availability based on the club and the date
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const getAvailableCourts = async (date: any, club: any) => {
  const getAvailableCourtsApi =
    BOOKING_MANAGEMENT.API_END_POINTS.COURT_AVAILABILITY;
  let response: any = {};
  const params = {
    date: date,
    club: club,
  };
  try {
    response = await HTTP_CALL(`${getAvailableCourtsApi}`, POST, "", params);
    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (!isEmpty(apiData)) {
        if (apiData?.courts?.length > 0) {
          showToast("Courts and slots available", "Success", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return apiData;
        } else {
          showToast(
            "Courts and slots not available, Please select a different date",
            "Error",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      } else {
        showToast("No Data", "Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};

/**
 * Add Notes
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const bookingNotesUpdate = async (id: any, notes: any) => {
  const bookingNotesUpdateApi = BOOKING_MANAGEMENT.API_END_POINTS.ADD_NOTES;
  let response: any = {};
  const params = {
    notes: notes,
  };
  try {
    response = await HTTP_CALL(
      `${bookingNotesUpdateApi}/${id}`,
      PUT,
      "",
      params
    );
    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (!isEmpty(apiData)) {
        showToast("Booking Notes updated successfully", "Success", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        showToast("No Data", "Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};

/**
 * Delete Notes
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const bookingDeleteNotesUpdate = async (id: any, notes: any) => {
  const bookingDeleteNotesUpdateApi =
    BOOKING_MANAGEMENT.API_END_POINTS.DELETE_NOTES;
  let response: any = {};
  const params = {
    notes: notes,
  };
  try {
    response = await HTTP_CALL(
      `${bookingDeleteNotesUpdateApi}/${id}`,
      PUT,
      "",
      params
    );
    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (!isEmpty(apiData)) {
        showToast("Booking Notes deleted successfully", "Success", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        showToast("No Data", "Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};

/**
 * Update booking participants
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const bookingParticipantsUpdate = async (
  id: any,
  participants?: any
) => {
  const bookingParticipantsUpdateApi =
    BOOKING_MANAGEMENT.API_END_POINTS.PARTICIPANTS_UPDATE;
  let response: any = {};
  try {
    response = await HTTP_CALL(
      `${bookingParticipantsUpdateApi}/${id}/participants`,
      PUT,
      "",
      participants
    );
    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (!isEmpty(apiData)) {
        showToast("Booking participant deleted successfully", "Success", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        showToast("No Data", "Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};

/**
 * Update booking participants
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const bookingParticipantActivate = async (
  id: any,
  bookingId:any,
  values?: any
) => {
  const bookingParticipantsUpdateApi =
    BOOKING_MANAGEMENT.API_END_POINTS.ACTIVATE_PARTICIPANT;
  let response: any = {};
  const params = {
    ...values
  };
  try {
    response = await HTTP_CALL(`${bookingParticipantsUpdateApi}/${bookingId}/participant/${id}/activate`,
      PATCH,
      "",
      params
    );
    
    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (!isEmpty(apiData)) {
        showToast("Booking participant activated successfully", "Success", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        showToast("No Data", "Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};

/**
 * Update booking participants
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const bookingParticipantsPaymentUpdate = async (
  payload?: any,
  callback?: (data: any) => void
) => {
  const bookingParticipantsUpdateApi =
    BOOKING_MANAGEMENT.API_END_POINTS.PAYMENT_STATUS_UPDATE;
  let response: any = {};
  try {
    response = await HTTP_CALL(
      `${bookingParticipantsUpdateApi}`,
      POST,
      "",
      payload
    );
    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (!isEmpty(apiData)) {
        showToast(
          "Participant payment status changed successfully",
          "Success",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        return apiData;
      } else {
        showToast("No Data", "Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};

export const addPlayerBooking =
  (values: any, callback?: (data: any, error?:any) => void, messageText?: string) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    const id = values?.bookingId;
    delete values.bookingId;
    try {
      response = await HTTP_CALL(
        `${BOOKING_MANAGEMENT.API_END_POINTS.PARTICIPANTS_UPDATE}/${id}/participant`,
        PATCH,
        "",
        values
      );

      showToast(messageText || "Player added successfully", "Success", {
        position: toast.POSITION.TOP_RIGHT,
      });

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      // if (values?.isUpdateLocalData) {
      //   dispatch(updateBookingData(apiData));
      // }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
        if (typeof callback === "function") {
          callback('', 'error');
        }
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
      if (typeof callback === "function") {
        callback('', 'error');
      }
    }
  };

/**
 * Update the coach status and cancel the sessions.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const deletePlayer =
  (bookingId: string, userId?: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    const url = `${BOOKING_MANAGEMENT.API_END_POINTS.PARTICIPANTS_UPDATE}/${bookingId}/participant/${userId}`;
    try {
      response = await HTTP_CALL(url, DELETE, "");

      showToast(`Player deleted successfully!`, "Success", {
        position: toast.POSITION.TOP_RIGHT,
      });

      if (typeof callback === "function") {
        callback(response);
      }

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const getSingleBooking =
  (id?: string, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        `${BOOKING_MANAGEMENT.API_END_POINTS.GET_BOOKING_DETAILS}/${id}`,
        GET,
        ""
      );

      const {
        data: { data: apiData, statusCode },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      }
    } catch (error: any) {}
  };

export const fetchCourtCoachList =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      const qParams = {
        ...params,
        limit: params?.pageSize,
        sort: params?.sort || "createdAt|DESC",
        //filters: params?.bookingType || "bookingType|booking",
      };
      // // Construct the filters object from the query parameter
      const filtersString: any = await transformFilters(qParams);
      const filteredSearchParams = new URLSearchParams(filtersString);

      if (!params?.isFilter) dispatch(courtBookingsRequested());

      response = await HTTP_CALL(
        `${
          BOOKING_MANAGEMENT.API_END_POINTS.COURT_COACH_BOOKINGS
        }?${filteredSearchParams.toString()}`,
        POST,
        "",
        params
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      dispatch(courtBookingsReceived(response?.data));
      if (typeof callback === "function") {
        callback(apiData);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData || [];
        } else return apiData || [];
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const fetchAvailableSlots =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      // // Construct the filters object from the query parameter

      response = await HTTP_CALL(
        `${BOOKING_MANAGEMENT.API_END_POINTS.COURT_COACH_AVAILABLE_SLOTS}`,
        POST,
        "",
        params
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const blockSlots =
  (params: any, id: string, unblock = false, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      // // Construct the filters object from the query parameter

      response = await HTTP_CALL(
        `${BOOKING_MANAGEMENT.API_END_POINTS.BLOCK_SLOTS}/${id}/${
          unblock ? "slot-unblock" : "slot-block"
        }`,
        PATCH,
        "",
        params
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          showToast(
            `Slots ${unblock ? "unblocked" : "blocked"} successfully`,
            "Success",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const resetData = () => async (dispatch: AppDispatch) => {
  dispatch(bookingsReset());
};
