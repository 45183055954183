import * as yup from "yup";
import { useCommonTranslations } from "./CommonTranslations";

export const AddTagValidationSchema = () => {
  // eslint-disable-next-line
  const { t } = useCommonTranslations();

  return yup.object().shape({
    name: yup
      .string()
      .required(`${t("tagRequired")}`)
      .min(3, `${t("minimum3LettersAllowed")}`)
      .max(15, `${t("maximum15LettersAllowed")}`),
    type: yup.array().required().min(1, "Minimum one type is required"),
    priority: yup.number().required(t("priorityRequired")),
    description: yup
      .string()
      .max(5000, `${t("maximum5000LettersAllowed")}`)
      // .matches(/^[a-zA-Z0-9.,!&%  ]*$/, {
      //   message: "Alphanumeric characters only allowed",
      // }),
  });
};
