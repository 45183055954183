import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dispatch, AnyAction } from "redux";
import { isEmpty } from "lodash";

import { CUSTOM_ERROR_MSG, HANDLE_ERROR, HTTP_CALL } from "../../config/http";

import {
  GET,
  PUT,
  PATCH,
  POST,
  BOOKING_SESSION_PACKAGES,
} from "../../config/API.constants";

import { showToast } from "../../shared/util/toastHelper";
import {
  sessionPackagesReceived,
  updateSessionPackagesData,
  singleSessionPackageReceived,
  sessionPackagesReset,
} from "app/redux/sessionPackagesReducer";

type AppDispatch = Dispatch<AnyAction>;

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const fetchSessionPackagesList =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    const filterParamsObj = {
      ...params,
      limit: params?.pageSize || 10,
      page: params?.page || 1,
      sort: params?.sort || "createdAt|DESC",
    };

    const filteredSearchParams = new URLSearchParams(filterParamsObj);

    try {
      response = await HTTP_CALL(
        `${
          BOOKING_SESSION_PACKAGES.GET_ALL_SESSION_PACKAGES
        }?${filteredSearchParams.toString()}`,
        GET
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      dispatch(sessionPackagesReceived(response?.data));

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const getSingleCoaching =
  (id?: string, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        `${BOOKING_SESSION_PACKAGES.GET_SESSION_PACKAGE_DETAILS}/${id}`,
        GET,
        ""
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;
      dispatch(singleSessionPackageReceived(apiData));

      if (typeof callback === "function") {
        callback(apiData);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const createSessionPackage =
  (values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        BOOKING_SESSION_PACKAGES.ADD_SESSION_PACKAGE,
        POST,
        "",
        values
      );

      showToast("Product created successfully", "Success", {
        position: toast.POSITION.TOP_RIGHT,
      });

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(response);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const updateSessionPackage =
  (id: string, values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    try {
      response = await HTTP_CALL(
        `${BOOKING_SESSION_PACKAGES.ADD_SESSION_PACKAGE}/${id}`,
        PATCH,
        "",
        values
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (statusCode === 200) {
        showToast("Session package updated successfully", "Success", {
          position: toast.POSITION.TOP_RIGHT,
        });

        if (!isEmpty(apiData)) {
          dispatch(updateSessionPackagesData(apiData));

          if (typeof callback === "function") {
            callback(apiData);
          }

          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Update the coach status and cancel the sessions.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const changeSessionPackageStatus =
  (id: string, values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        `${BOOKING_SESSION_PACKAGES.ADD_SESSION_PACKAGE}/${id}/status-update`,
        PATCH,
        "",
        values
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      showToast(apiData?.message || `Session Package ${values?.status} successfully`, "Success", {
        position: toast.POSITION.TOP_RIGHT,
      });

      if (!isEmpty(apiData)) {
        dispatch(updateSessionPackagesData({...values, _id: id}));
      }

      if (typeof callback === "function") {
        callback(response);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const resetData = () => async (dispatch: AppDispatch) => {
  dispatch(sessionPackagesReset());
};
