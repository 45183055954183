export const calculateEndTime = (startTime: string, duration: number): string => {
    // Parse the start time into hours and minutes
    const [startHours, startMinutes] = startTime.split(':').map(Number);

    // Calculate the end time
    const totalMinutes = startHours * 60 + startMinutes + duration;
    const endHours = Math.floor(totalMinutes / 60);
    const endMinutes = totalMinutes % 60;

    // Format the end time
    const formattedEndTime = `${String(endHours).padStart(2, '0')}:${String(endMinutes).padStart(2, '0')}`;

    return formattedEndTime;
  };