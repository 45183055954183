import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dispatch, AnyAction } from "redux";
import { isEmpty } from "lodash";

import { CUSTOM_ERROR_MSG, HANDLE_ERROR, HTTP_CALL } from "../../config/http";
import { buildQuery } from "app/shared/util/buildQuery";
import { downloadCsv } from "app/shared/util/downloadFile";
import {
  GET,
  PUT,
  POST,
  COACHING_MANAGEMENT,
  COACHING_SESSIONS_MANAGEMENT,
} from "../../config/API.constants";

import { showToast } from "../../shared/util/toastHelper";
import { transformFilters } from "app/shared/util/buildQuery";
import {
  coachingReceived,
  coachingRequested,
  singleCoachingReceived,
  coachingReset,
} from "../../redux/coachingReducer";

type AppDispatch = Dispatch<AnyAction>;

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const fetchCoachingList =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    try {
      const qParams = {
        ...params,
        limit: params?.pageSize || 10,
        sort: params?.sort || "createdAt|DESC",
      };
      // // Construct the filters object from the query parameter
      const filtersString: any = await transformFilters(qParams);
      const filteredSearchParams = new URLSearchParams(filtersString);

      if (!params?.isFilter) {
        dispatch(coachingReceived([]));
        dispatch(coachingRequested());
      }

      response = await HTTP_CALL(
        `${
          COACHING_MANAGEMENT.GET_ALL_COACHINGS
        }?${filteredSearchParams.toString()}`,
        GET,
        "",
        params
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      if (!params?.noSaveRedux) dispatch(coachingReceived(response?.data));

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const getSingleCoaching =
  (id?: string, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        `${COACHING_MANAGEMENT.GET_COACHING_DETAILS}/${id}`,
        GET,
        ""
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;
      dispatch(singleCoachingReceived(apiData));

      if (typeof callback === "function") {
        callback(apiData);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const createCoaching =
  (values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        COACHING_MANAGEMENT.ADD_COACHING_DETAILS,
        POST,
        "",
        values
      );

      showToast("Coach created successfully", "Success", {
        position: toast.POSITION.TOP_RIGHT,
      });

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(response);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const updateCoaching =
  (id: string, values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    try {
      response = await HTTP_CALL(
        `${COACHING_MANAGEMENT.ADD_COACHING_DETAILS}/${id}`,
        PUT,
        "",
        values
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (statusCode === 200) {
        showToast("Coach updated successfully", "Success", {
          position: toast.POSITION.TOP_RIGHT,
        });

        if (!isEmpty(apiData)) {
          //dispatch(updateCoachingData(apiData));

          if (typeof callback === "function") {
            callback(apiData);
          }

          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

// export const fetchTagsList =
//   (params: any, callback?: (data: any) => void) =>
//   async (dispatch: AppDispatch, getState: () => any) => {
//     let response: any = {};
//     try {
//       response = await HTTP_CALL(
//         COACHING_MANAGEMENT.GET_ALL_TAGS,
//         GET,
//         "",
//         params
//       );

//       const {
//         data: { data: apiData, statusCode, message },
//       } = response;

//       //dispatch(clubsReceived(response?.data));
//       if (typeof callback === "function") {
//         callback(apiData);
//       }

//       if (statusCode === 200) {
//         if (apiData?.data !== null) {
//           return apiData;
//         } else {
//           showToast("No Data", "Error", {
//             position: toast.POSITION.TOP_RIGHT,
//           });
//         }
//       } else {
//         CUSTOM_ERROR_MSG(message || statusCode);
//       }
//     } catch (error: any) {
//       HANDLE_ERROR(error);
//     }
//   };

export const getAllCoachList =
  (params?: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        `${COACHING_MANAGEMENT.GET_ALL_COACHINGS}?${buildQuery({
          ...params,
          limit: 1000,
        })}`,
        GET,
        "",
        params
      );

      if (typeof callback === "function") {
        callback(response);
      }
      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Update the coach status and cancel the sessions.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const changeCoachStatus =
  (id: string, values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        `${COACHING_MANAGEMENT.ADD_COACHING_DETAILS}/${id}/status-update`,
        PUT,
        "",
        values
      );

      showToast(
        values?.coach
          ? "Sessions transfer successful! The new coach will be notified of the changes"
          : `Coach ${values?.status} successfully`,
        "Success",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );

      if (typeof callback === "function") {
        callback(response);
      }

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Fetches sessions data based on the coach id from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const fetchSessionsList =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        `${COACHING_SESSIONS_MANAGEMENT.GET_ALL_SESSIONS}`,
        POST,
        "",
        params
      );

      //dispatch(coachingReceived(response?.data));

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const downloadTableData =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    const filterParamsObj = {
      ...params,
      isExport: true,
    };

    const filteredSearchParams = new URLSearchParams(filterParamsObj);

    try {
      response = await HTTP_CALL(
        `${
          COACHING_MANAGEMENT.GET_ALL_COACHINGS
        }?${filteredSearchParams.toString()}`,
        GET
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      downloadCsv(apiData, "CoachingList.xlsx");

      if (typeof callback === "function") {
        callback(apiData);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Fetches sessions data based on the club id from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const fetchCourtTournamentsList =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      const filtersString: any = await transformFilters(params);
      const filteredSearchParams = new URLSearchParams(filtersString);

      response = await HTTP_CALL(
        `${
          COACHING_SESSIONS_MANAGEMENT.GET_ALL_COURT_TOURNAMENTS
        }?${filteredSearchParams.toString()}`,
        GET,
        "",
        params
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData || [];
        } else return apiData || [];
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const resetData = () => async (dispatch: AppDispatch) => {
  dispatch(coachingReset());
};
