// Define the function that extracts the dial code from a country name with code
export const extractDialCode = (countryNameWithCode: string): string | null => {
  // Regular expression to match the dial code within parentheses
  const regex = /\((\+\d+)\)$/;
  if (countryNameWithCode) {
    // Attempt to match the regex pattern against the input string
    const match = countryNameWithCode?.match(regex);

    // If a match is found and the dial code is captured in the first group
    if (match && match[1]) {
      // Extract and return the dial code without the leading '+'
      return match[1]?.substring(1); // Remove the '+' character
    }
  } // Return null if no match was found
  return null;
};
