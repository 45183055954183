import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Button, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Deactivate } from 'app/config/constants';
import { courtStatusUpdate } from 'app/entities/Clubs/clubsApiService';
import { useTranslation } from 'react-i18next';
interface CourtStatusChangeModalProps {
	id?: any;
	status?: string;
	openModal?: any;
	onClose?: any;
	modalTxt?: any;
	entityName?: any;
	refreshGrid?: any;
	setFieldValue?:any;
	selectedCourtIndex?:any;
}

const brownButtonStyle = {
	backgroundColor: '#333333',
	color: 'white', // Text color
	marginTop: '30px',
	padding: '13px 15px',
	fontSize: '12px',
};

const CourtStatusChangeModal: React.FC<CourtStatusChangeModalProps> = ({
	openModal,
	onClose,
	id,
	status,
	modalTxt,
	entityName,
	refreshGrid,
	setFieldValue,
	selectedCourtIndex
}) => {
	const { t } = useTranslation();
	const [haveActiveBookings, setActiveBookings] = useState(false);

	const handleCloseConfirmation = () => {
		handleClose();
	};

	const handleConfirm = async () => {
        let params = {
			status: status === 'active' ? 'inactive' : 'active',
            bookingForceCancel: haveActiveBookings
		};
        
		// Close the confirmation dialog
		let response = await courtStatusUpdate(id, params);
		if (response === 'reconfirm') {
			setActiveBookings(true);
		} else {
			setFieldValue(`courts.[${selectedCourtIndex}].status`, 'inactive');
			setActiveBookings(false);
			onClose()
			handleClose();
		}
	};

	const handleClose = () => {
		onClose();
		refreshGrid();
	};

	return (
		<div>
			<Modal
				open={openModal}
				onClose={handleClose}
				closeAfterTransition
				slotProps={{
					backdrop: {
						style: {
							backgroundColor: 'rgba(0,0,0,0.7)',
						},
					},
				}}
			>
				<div
					style={{
						position: 'absolute',
						top: '50%',
						textAlign: 'center',
						left: '50%',
						width: '25%',
						height: '25vh',
						borderRadius: '5px',
						transform: 'translate(-50%, -50%)',
						padding: 16,
						background: 'rgba(255, 255, 255, 0.9)',
					}}
				>
					<IconButton
						edge="end"
						color="inherit"
						onClick={onClose}
						aria-label="close"
						sx={{
							position: 'absolute',
							top: '15px',
							right: '24px',
							cursor: 'pointer',
						}}
					>
						<CloseIcon />
					</IconButton>

					<div>
						<img width="100" alt="status_update_image" src={Deactivate} />
					</div>

					{haveActiveBookings && status === 'active' ? (
						<>
							<Typography sx={{ color: '#8F8F8F' }}>
							{t('ClubHaveActiveBookingsCancellation')}
							</Typography>
							<Button
								onClick={handleCloseConfirmation}
								style={{
									marginRight: '8px',
									backgroundColor: '#333333',
									color: 'white', // Text color
									marginTop: '30px',
									padding: '13px 15px',
									fontSize: '12px',
								}}
							>
								{t('cancel')}
							</Button>
							<Button onClick={handleConfirm} style={brownButtonStyle}>
								{t('confirm')}
							</Button>
						</>
					) : (
						<>
							<Typography sx={{ color: '#8F8F8F' }}>
								{t('areyouSureWantTo')}
								{status === 'active' || status === 'pending'
									? ' Deactivate'
									: status === 'inactive' ||
									  status === 'Inactive' ||
									  status === 'delete'
									? ' Activate'
									: ''}{' '}
								{t('this')} {modalTxt}?
							</Typography>
							<Button
								onClick={handleCloseConfirmation}
								style={{
									marginRight: '8px',
									backgroundColor: '#333333',
									color: 'white', // Text color
									marginTop: '30px',
									padding: '13px 15px',
									fontSize: '12px',
								}}
							>
								{t('cancel')}
							</Button>
							<Button onClick={handleConfirm} style={brownButtonStyle}>
								{t('confirm')}
							</Button>
						</>
					)}
				</div>
			</Modal>
		</div>
	);
};

export default CourtStatusChangeModal;
