import { Dispatch, AnyAction } from 'redux';
import { buildQuery, transformFilters } from 'app/shared/util/buildQuery';
import { CUSTOM_ERROR_MSG, HANDLE_ERROR, HTTP_CALL } from '../../config/http';
import { GET, PUT, POST, USER_MANAGEMENT, DELETE } from '../../config/API.constants';
import {
	adminUsersRequested,
	adminUsersReceived,
	endUsersRequested,
	endUsersReceived,
	usersReset,
} from '../../redux/usersReducer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToast } from '../../shared/util/toastHelper';
import { isEmpty, stubFalse } from 'lodash';
import { downloadCsv } from 'app/shared/util/downloadFile';

type AppDispatch = Dispatch<AnyAction>;

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const fetchAdminList =
	(params: any, callback?: (data: any) => void, isFilter = false) =>
	async (dispatch: AppDispatch, getState: () => any) => {
		let response: any = {};
		try {
			const qParams = {
				...params,
				limit: params?.pageSize,
				sort: params?.sort || 'createdAt|DESC',
			};
			// // Construct the filters object from the query parameter
			const filtersString: any = await transformFilters(qParams);
			const filteredSearchParams = new URLSearchParams(filtersString);

			dispatch(adminUsersRequested());

			response = await HTTP_CALL(
				`${
					USER_MANAGEMENT.API_END_POINTS.GET_ALL_ADMIN_USERS
				}?${filteredSearchParams.toString()}`,
				GET,
				'',
				params
			);

			const {
				data: { data: apiData, statusCode, message },
			} = response;

			if (typeof callback === 'function') {
				callback(apiData);
			}
			if (!isFilter) dispatch(adminUsersReceived(response?.data));

			if (statusCode === 200) {
				if (!isEmpty(apiData)) {
					return apiData;
				}
			} else {
				CUSTOM_ERROR_MSG(message || statusCode);
			}
		} catch (error: any) {
			HANDLE_ERROR(error);
		}
	};

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const fetchAdminUsersList =
	(params: any, callback?: (data: any) => void) =>
	async (dispatch: AppDispatch, getState: () => any) => {
		let response: any = {};
		try {
			response = await HTTP_CALL(
				`${USER_MANAGEMENT.API_END_POINTS.GET_ALL_ADMIN_USERS}?${buildQuery({
					...params,
					limit: params?.pageSize,
					sort: 'createdAt|DESC',
				})}`,
				GET,
				'',
				params
			);

			const {
				data: { data: apiData, statusCode, message },
			} = response;

			if (typeof callback === 'function') {
				callback(apiData);
			}

			dispatch(adminUsersReceived(response?.data));

			if (statusCode === 200) {
				if (!isEmpty(apiData)) {
					return apiData;
				}
			} else {
				CUSTOM_ERROR_MSG(message || statusCode);
			}
		} catch (error: any) {
			HANDLE_ERROR(error);
		}
	};
/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const fetchUserList =
	(params: any, callback?: (data: any) => void) =>
	async (dispatch: AppDispatch, getState: () => any) => {
		let response: any = {};

		const qParams = {
			...params,
			limit: params?.pageSize,
			sort: params?.sort || 'createdAt|DESC',
			filters: 'userType|customer',
		};
		// // Construct the filters object from the query parameter
		const filtersString: any = await transformFilters(qParams);
		const filteredSearchParams = new URLSearchParams(filtersString);

		try {
			dispatch(endUsersRequested());
			response = await HTTP_CALL(
				`${
					USER_MANAGEMENT.API_END_POINTS.GET_ALL_END_USERS
				}?${filteredSearchParams.toString()}`,
				GET,
				'',
				params
			);

			const {
				data: { data: apiData, statusCode, message },
			} = response;

			if (typeof callback === 'function') {
				callback(apiData);
			}

			dispatch(endUsersReceived(response?.data));

			if (statusCode === 200) {
				if (!isEmpty(apiData)) {
					return apiData;
				}
			} else {
				CUSTOM_ERROR_MSG(message || statusCode);
			}
		} catch (error: any) {
			HANDLE_ERROR(error);
		}
	};

export const fetchAdminUsers = async (page: any, limit: any) => {
	const listAllAdminUsersAPI = USER_MANAGEMENT.API_END_POINTS.GET_ALL_ADMIN_USERS;
	let response: any = {};
	const paramsObj = {
		page: page + 1,
		limit: limit,
		sort: 'createdAt|DESC',
	};
	const searchParams = new URLSearchParams(paramsObj);
	try {
		response = await HTTP_CALL(`${listAllAdminUsersAPI}?${searchParams.toString()}`, GET);
		const {
			data: { data: apiData, statusCode, message },
		} = response;

		if (statusCode === 200) {
			if (!isEmpty(apiData)) {
				return apiData;
			} else {
				showToast('No Data', 'Error', {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		} else {
			CUSTOM_ERROR_MSG(message || statusCode);
		}
	} catch (error: any) {
		HANDLE_ERROR(error);
	}
};

export const fetchEndUsers = async (page: any, limit: any, listAll = false) => {
	const listAllEndUsersAPI = USER_MANAGEMENT.API_END_POINTS.GET_ALL_END_USERS;
	let filterParamsObj: any = {
		page: page + 1,
		limit: limit,
		sort: 'createdAt|DESC',
		filters: 'status|active,userType|customer',
	};
	if (listAll) {
		filterParamsObj.listAll = true;
	}
	const filteredSearchParams = new URLSearchParams(filterParamsObj);
	let response: any = {};
	try {
		response = await HTTP_CALL(
			`${listAllEndUsersAPI}?${filteredSearchParams?.toString()}`,
			GET
		);
		const {
			data: { data: apiData, statusCode, message },
		} = response;

		if (statusCode === 200) {
			if (!isEmpty(apiData)) {
				return apiData;
			} else {
				showToast('No Data', 'Error', {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		} else {
			CUSTOM_ERROR_MSG(message || statusCode);
		}
	} catch (error: any) {
		HANDLE_ERROR(error);
	}
};


export const fetchSearchUsers = async (textSearch:any , page: any, listAll = false) => {
	const listAllEndUsersAPI = USER_MANAGEMENT.API_END_POINTS.GET_ALL_END_USERS;
	let filterParamsObj: any = {
		page: page,
		limit: 25,
		sort: 'createdAt|DESC',
		filters: 'status|active,userType|customer',
	};
	if (textSearch) {
		filterParamsObj.textSearch = textSearch;
	}
	if (listAll) {
		filterParamsObj.listAll = true;
	}
	const filteredSearchParams = new URLSearchParams(filterParamsObj);
	let response: any = {};
	try {
		response = await HTTP_CALL(
			`${listAllEndUsersAPI}?${filteredSearchParams?.toString()}`,
			GET
		);
		const {
			data: { data: apiData, statusCode, message },
		} = response;

		if (statusCode === 200) {
			if (!isEmpty(apiData)) {
				return response?.data;
			}
		} else {
			//CUSTOM_ERROR_MSG(message || statusCode);
		}
	} catch (error: any) {
		//HANDLE_ERROR(error);
	}
};

/**
 * Fetches user admin detail data based on the user id from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const fetchUserDetailFromApi = async (id: any, apiName: string) => {
	let response: any = {};
	try {
		response = await HTTP_CALL(`${apiName}${id}`, GET);
		const {
			data: { data: apiData, statusCode, message },
		} = response;

		if (statusCode === 200) {
			if (!isEmpty(apiData)) {
				return apiData;
			} else {
				showToast('No Data', 'Error', {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		} else {
			CUSTOM_ERROR_MSG(message || statusCode);
		}
	} catch (error: any) {
		HANDLE_ERROR(error);
	}
};

/**
 * Update user status
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const userStatusUpdate = async (id: any, status?: any) => {
	const clubStatusUpdateApi = USER_MANAGEMENT.API_END_POINTS.STATUS_UPDATE;
	let response: any = {};
	const params = {
		status: status,
	};
	try {
		response = await HTTP_CALL(`${clubStatusUpdateApi}${id}/status`, PUT, '', params);
		const {
			data: { data: apiData, statusCode, message },
		} = response;

		if (statusCode === 200) {
			if (!isEmpty(apiData)) {
				if (status === 'Delete') {
					showToast('User deleted successfully', 'Success', {
						position: toast.POSITION.TOP_RIGHT,
					});
				} else {
					showToast('User status updated successfully', 'Success', {
						position: toast.POSITION.TOP_RIGHT,
					});
				}
			} else {
				showToast('No Data', 'Error', {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		} else {
			CUSTOM_ERROR_MSG(message || statusCode);
		}
	} catch (error: any) {
		HANDLE_ERROR(error);
	}
};

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const fetchRoleDataFromApi = async (apiName: string) => {
	let response: any = {};
	try {
		response = await HTTP_CALL(`${apiName}`, GET);
		const {
			data: { data: apiData, statusCode, message },
		} = response;

		if (statusCode === 200) {
			if (!isEmpty(apiData)) {
				return apiData;
			} else {
				showToast('No Data', 'Error', {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		} else {
			CUSTOM_ERROR_MSG(message || statusCode);
		}
	} catch (error: any) {
		HANDLE_ERROR(error);
	}
};

/**
 * Reset password for the club admin.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const resetPassword = async (userEmail: any) => {
	const params = {
		email: userEmail,
	};
	const resetPasswordApi = USER_MANAGEMENT.API_END_POINTS.ADMIN_FORGOT_PASSWORD;
	let response: any = {};
	try {
		response = await HTTP_CALL(`${resetPasswordApi}`, POST, '', params);
		const {
			data: { data: apiData, statusCode, message },
		} = response;

		if (statusCode === 200) {
			if (!isEmpty(apiData)) {
				showToast(apiData?.message, 'Success', {
					position: toast.POSITION.TOP_RIGHT,
				});
			} else {
				showToast('No Data', 'Error', {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		} else {
			CUSTOM_ERROR_MSG(message || statusCode);
		}
	} catch (error: any) {
		HANDLE_ERROR(error);
	}
};

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const downloadTableData =
	(params: any, callback?: (data: any) => void) =>
	async (dispatch: AppDispatch, getState: () => any) => {
		let response: any = {};

		const filterParamsObj = {
			...params,
			isExport: true,
		};

		const filteredSearchParams = new URLSearchParams(filterParamsObj);

		try {
			response = await HTTP_CALL(
				`${
					USER_MANAGEMENT.API_END_POINTS.GET_ALL_END_USERS
				}?${filteredSearchParams.toString()}`,
				GET
			);

			const {
				data: { data: apiData, statusCode, message },
			} = response;

			downloadCsv(apiData, 'EndUserList.xlsx');

			if (typeof callback === 'function') {
				callback(apiData);
			}

			if (statusCode === 200) {
				if (!isEmpty(apiData)) {
					return apiData;
				}
			} else {
				CUSTOM_ERROR_MSG(message || statusCode);
			}
		} catch (error: any) {
			HANDLE_ERROR(error);
		}
	};

export const adminUserDelete = async (id: string) => {
	const adminUserDeleteApi = USER_MANAGEMENT.API_END_POINTS.DELETE_ADMIN_USER;

	try {
		const response: any = await HTTP_CALL(`${adminUserDeleteApi}/${id}`, DELETE);
		const {
			data: { statusCode, message },
		} = response;

		if (statusCode === 200) {
			showToast(message, 'Success', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			CUSTOM_ERROR_MSG(message || statusCode);
		}
	} catch (error: any) {
		HANDLE_ERROR(error);
	}
};

/**
 * Validate the phone number.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const checkPhoneNumberValidity = async (params: any, callback?: (data?:any) => void) => {
	const phoneValidityApi = USER_MANAGEMENT.API_END_POINTS.CHECK_PHONE_VALIDITY;
	let response: any = {};
	try {
		response = await HTTP_CALL(`${phoneValidityApi}`, POST, '', params);
		const {
			data: { data: apiData, statusCode },
		} = response;

		if (statusCode === 200) {
			if (!isEmpty(apiData)) {
				if (typeof callback === 'function') {
					callback(apiData);
				}
			}
		}
	} catch (error: any) {
		if (error) HANDLE_ERROR(error);
		if (typeof callback === 'function') {
			callback(null);
		}
	}
};

/**
 * Reedom the code.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const redeemCode = async (params: any, callback?: (data?:any) => void) => {
	const phoneValidityApi = USER_MANAGEMENT.API_END_POINTS.REDEEMCODE;
	let response: any = {};
	try {
		response = await HTTP_CALL(`${phoneValidityApi}`, POST, '', params);
		const {
			data: { data: apiData, statusCode },
		} = response;

		if (statusCode === 200) {
			if (!isEmpty(apiData)) {
				if (typeof callback === 'function') {
					showToast(`Casa credit added successfully`, 'Success', {
						position: toast.POSITION.TOP_RIGHT,
					});
					callback(apiData);
				}
			}
		}
	} catch (error: any) {
		if (error) HANDLE_ERROR(error);
		if (typeof callback === 'function') {
			callback(null);
		}
	}
};

export const resetData = () => async (dispatch: AppDispatch) => {
	dispatch(usersReset());
};
