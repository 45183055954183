import React, { useState, useEffect, memo } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { upperCase, lowerCase } from "lodash";
import CustomPagination from "../components/CustomPagination";
import {
  useLocationQuery,
  useLocationQuerySet,
} from "app/shared/util/useLocationQuery";

import { DEFAULT_PAGESIZE } from "app/config/constants";

interface DataGridProps {
  rows: any[];
  columns: any[];
  currentPage?: any;
  onCurrentPageChange?: any;
  menuName?: any;
  meta?: any;
  handlePageChange?: any;
  callback?: any;
  handleCellClick?: any;
  checkboxSelection?: boolean;
  rowHeight?: number;
  loading?: boolean;
  selectedBookingIds?: any;
  onRowSelection?: any;
  handleSelectedBookingIdsChange?: (selectedIds: any[]) => void;
}

const StyledDataGrid = styled(DataGrid)`
  .MuiDataGrid-footerContainer {
    display: none;
  }
  /* Add the following styles to show pointer cursor */
  .MuiDataGrid-root {
    cursor: pointer;
  }

  /* Add the following styles to show pointer cursor on cell click */
  .MuiDataGrid-cell:hover {
    cursor: pointer;
  }
  .MuiDataGrid-cellContent {
    color: #6e7079;
  }

  .MuiDataGrid-virtualScroller {
    min-height: 300px;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: 400; // Change this value to your desired font weight
  }
`;

const MUIDataGrid: React.FC<DataGridProps> = ({
  rows,
  columns,
  onCurrentPageChange,
  meta,
  callback,
  handleCellClick,
  checkboxSelection = true,
  rowHeight = 52,
  loading,
  selectedBookingIds,
  onRowSelection,
  handleSelectedBookingIdsChange,
}) => {
  const searchParams = useLocationQuery();
  const setQuery = useLocationQuerySet();
  const [sortModel, setSortModel] = useState<any>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const handleSortModelChange = (model: any) => {
    if (model?.length > 0) {
      const { field, sort } = model[0];
      setQuery({ ...searchParams, sort: `${field}|${upperCase(sort)}` });
    } else {
      delete searchParams.sort;
      setQuery({ ...searchParams });
    }
    callback();
  };

  const [paginationModel, setPaginationModel] = useState({
    page: meta?.page ? parseInt(meta?.page) : 1,
    pageSize: meta?.pageSize ? parseInt(meta?.pageSize) : DEFAULT_PAGESIZE,
  });

  useEffect(() => {
    // Update pagination model when meta.pageSize changes
    setPaginationModel({
      ...paginationModel,
      pageSize: meta?.pageSize ? parseInt(meta?.pageSize) : DEFAULT_PAGESIZE,
    });
  }, [meta, meta?.pageSize, meta?.limit]);

  const handlePageSizeChange = (data: any) => {
    setQuery({ ...searchParams, page: 1, pageSize: `${data.pageSize || 10}` });
    setPaginationModel({
      ...paginationModel,
      pageSize: data?.pageSize,
    });
    callback();
  };

  useEffect(() => {
    if (searchParams?.sort) {
      const splitSorting = searchParams?.sort?.split("|");
      setSortModel([
        { field: splitSorting?.[0], sort: lowerCase(splitSorting?.[1]) },
      ]);
      callback();
    } else {
      setSortModel([]);
    }
  }, [searchParams?.sort]);

  useEffect(() => {
    // Extract IDs from selected rows
    const selectedIds = selectedRows?.map((row) => row.id);
    // Update selectedBookingIds prop
    if (onRowSelection) {
      onRowSelection(selectedIds);
    }
  }, [selectedRows]);

  // Event handler for row selection
  const handleRowSelection = (newSelection: any[]) => {
    setSelectedRows(newSelection);
    // Extract IDs from selected rows
    const selectedIds = newSelection?.map((row) => row);
    // Update selectedBookingIds prop
    if (onRowSelection) {
      onRowSelection(selectedIds);
    }
    // Update selectedBookingIds in MainContainer
    // Check if handleSelectedBookingIdsChange is defined before calling it
    if (handleSelectedBookingIdsChange) {
      handleSelectedBookingIdsChange(selectedIds);
    }
  };

  return (
    <Box style={{ width: "100%" }}>
      <StyledDataGrid
        autoHeight
        key={rows?.length}
        disableColumnMenu
        rows={rows}
        rowHeight={rowHeight}
        getRowId={(row) => row?._id || row}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: paginationModel,
          },
        }}
        pageSizeOptions={[5, 10, 20, 30]}
        checkboxSelection={checkboxSelection}
        onSortModelChange={handleSortModelChange}
        sortModel={sortModel}
        onCellClick={handleCellClick}
        onRowSelectionModelChange={handleRowSelection}
        loading={loading}
        sx={{ minHeight: "300px" }}
      />

      <CustomPagination
        defaultPage={parseInt(searchParams?.page) || 1}
        defaultPageSize={parseInt(searchParams?.pageSize) || DEFAULT_PAGESIZE}
        dataCount={meta?.totalCount}
        totalPages={Math.ceil(
          meta?.totalCount / (searchParams?.pageSize || DEFAULT_PAGESIZE)
        )}
        currentRecords={rows || []}
        handlePageChange={(data: any) => {
          onCurrentPageChange({
            page: data?.page,
            pageSize: parseInt(data?.pageSize) || 10,
          });
        }}
        handlePageSizeChange={handlePageSizeChange}
      />
    </Box>
  );
};

export default memo(MUIDataGrid);
