import React, { useState } from "react";
import {
  IconButton,
  Typography,
  Divider,
  Button,
  Grid,
  Box,
} from "@mui/material";
import { Form, Formik } from "formik";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

import {
  FilterAlt as FilterAltIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import _ from "lodash";

import {
  useLocationQuery,
  useLocationQuerySet,
} from "app/shared/util/useLocationQuery";
import { FormSelectField } from "app/shared/atoms";
import { statusList } from "app/config/constants";

interface IFilterProps {
  callback?: any;
}

const ButtonWrapper = styled("div")`
  display: flex;
  justify-content: flex-end;
`;

const brownButtonStyle = {
  backgroundColor: "#333333",
  color: "white", // Text color
};

const ClubFilterDialog: React.FC<IFilterProps> = ({ callback }) => {
  const searchParams = useLocationQuery();
  const setQuery = useLocationQuerySet();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const fields = ["clubs", "filter_status"]; // Sample fields keys

  const initialValues = {
    ...searchParams,
    clubs: searchParams?.clubs?.split(",") || [],
  };

  const [open, setOpen] = React.useState(false);
  const [placement] = React.useState<PopperPlacementType>();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleReset = (resetForm: any) => (e: any) => {
    resetForm();

    const filteredSearchParamsObject = _.omit(searchParams, fields);
    setQuery({ ...filteredSearchParamsObject, page: 1 });
    callback();
  };

  const handleApply = () => {
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClick}
        style={{
          cursor: "pointer",
          borderRadius: "4px",
          border: "1px solid #F2F2F2",
          color: "#727272",
        }}
      >
        <FilterAltIcon style={{ marginRight: "8px", color: "#727272" }} />
        <Typography>{t("filter")}</Typography>
      </Button>

      <Popper
        sx={{ zIndex: 1200 }}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper style={{ padding: "30px", width: "500px" }}>
              <Grid container justifyContent={"space-between"}>
                <Grid>
                  <Typography variant="h6" style={{ flex: 1 }}>
                    {t("filterBy")}
                  </Typography>
                </Grid>
                <Grid>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <Divider style={{ marginTop: "1rem" }} />

              <Formik
                initialValues={{
                  ...initialValues,
                  users: searchParams?.users?.split(",") || [],
                }}
                onSubmit={(values, actions) => {
                  setQuery({ ...searchParams, ...values, page: 1 });
                  callback({ ...searchParams, ...values, page: 1 });
                }}
                enableReinitialize
                //validationSchema={DateFilterValidationSchema}
              >
                {({ values, setFieldValue, resetForm }) => {
                  return (
                    <>
                      <Form>
                        {/* <Box style={{ marginTop: "25px" }}>
                          <ClubsMultiSelect
                            label="Club"
                            multiple={true}
                            value={values?.clubs ? values?.clubs : []} // Pass the value from Formik's state
                            onChange={(clubs: any) => {
                              setFieldValue("clubs", clubs);
                            }}
                            isFilter={true}
                          />
                        </Box> */}
                        {/* <Box style={{ marginTop: "25px" }}>
                          <UsersMultiSelect
                            label="Users"
                            multiple={true}
                            value={values?.users ? values?.users : []} // Pass the value from Formik's state
                            onChange={(users: any) => {
                              setFieldValue("users", users);
                            }}
                            isFilter={true}
                          />
                        </Box> */}

                        <Box style={{ marginTop: "25px" }}>
                          <FormSelectField
                            label={t("status")}
                            name="filter_status"
                            options={statusList?.map((x: any) => ({
                              ...x,
                              label: t(x?.label),
                            }))}
                          />
                        </Box>

                        <Grid
                          container
                          justifyContent={"flex-end"}
                          alignItems={"center"}
                          mt={"3rem"}
                        >
                          <Button
                            onClick={handleReset(resetForm)}
                            style={{
                              marginRight: "1rem",
                              color: "#EB5757",
                              fontWeight: 600,
                              textTransform: "capitalize",
                            }}
                          >
                            {t("resetFilters")}
                          </Button>
                          <ButtonWrapper>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleApply}
                              style={brownButtonStyle}
                              className="capitalize"
                              type="submit"
                            >
                              {t("applyFilters")}
                            </Button>
                          </ButtonWrapper>
                        </Grid>
                      </Form>{" "}
                    </>
                  );
                }}
              </Formik>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default ClubFilterDialog;
