import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dispatch, AnyAction } from "redux";
import { isEmpty } from "lodash";
import { transformFilters } from "app/shared/util/buildQuery";

import { CUSTOM_ERROR_MSG, HANDLE_ERROR, HTTP_CALL } from "../../config/http";

import {
  GET,
  PUT,
  POST,
  PATCH,
  COUPONS_MANAGEMENT,
} from "../../config/API.constants";

import { showToast } from "../../shared/util/toastHelper";
import {
  couponsReceived,
  updateCouponData,
  singleCouponReceived,
  couponsRequested,
  couponsReset,
} from "app/redux/couponsReducer";

type AppDispatch = Dispatch<AnyAction>;

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const fetchCouponsList =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    const qParams = {
      ...params,
      limit: params?.pageSize || 10,
      page: params?.page || 1,
      sort: params?.sort || "createdAt|DESC",
    };
    // // Construct the filters object from the query parameter
    const filtersString: any = await transformFilters(qParams);
    const filteredSearchParams = new URLSearchParams(filtersString);

    try {
      dispatch(couponsRequested());
      response = await HTTP_CALL(
        `${
          COUPONS_MANAGEMENT.GET_ALL_COUPONS
        }?${filteredSearchParams.toString()}`,
        GET
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      dispatch(couponsReceived(response?.data));

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const getSingleCoupon =
  (id?: string, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        `${COUPONS_MANAGEMENT.GET_COUPON_DETAILS}/${id}`,
        GET,
        ""
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;
      dispatch(singleCouponReceived(apiData));

      if (typeof callback === "function") {
        callback(apiData);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const createCoupon =
  (values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        COUPONS_MANAGEMENT.GET_COUPON_DETAILS,
        POST,
        "",
        values
      );

      showToast("Coupon created successfully", "Success", {
        position: toast.POSITION.TOP_RIGHT,
      });

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(response);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const updateCoupon =
  (id: string, values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};

    try {
      response = await HTTP_CALL(
        `${COUPONS_MANAGEMENT.UPDATE_COUPON_DETAILS}/${id}`,
        PUT,
        "",
        values
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (statusCode === 200) {
        showToast("Coupon updated successfully", "Success", {
          position: toast.POSITION.TOP_RIGHT,
        });

        if (!isEmpty(apiData)) {
          dispatch(updateCouponData(apiData));

          if (typeof callback === "function") {
            callback(apiData);
          }

          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Update the coach status and cancel the sessions.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const changeCouponStatus =
  (id: string, values: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        `${COUPONS_MANAGEMENT.UPDATE_COUPON_DETAILS}/${id}/status`,
        PATCH,
        "",
        values
      );

      showToast(`Coupon ${values?.status} successfully`, "Success", {
        position: toast.POSITION.TOP_RIGHT,
      });

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (!isEmpty(apiData)) {
        dispatch(updateCouponData({ ...apiData, status: values?.status }));
      }

      if (typeof callback === "function") {
        callback(response);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Update the coach status and cancel the sessions.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const verifyCoupon =
  (couponCode: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      response = await HTTP_CALL(
        `${COUPONS_MANAGEMENT.VERIFY_COUPON}/${couponCode}`,
        GET,
        ""
      );

      showToast(`Coupon applied successfully`, "Success", {
        position: toast.POSITION.TOP_RIGHT,
      });

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

export const resetData = () => async (dispatch: AppDispatch) => {
  dispatch(couponsReset());
};
