import React from "react";
import { Modal, Button, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { POST, BOOKING_MANAGEMENT } from "../../../config/API.constants";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {
  HANDLE_ERROR,
  HTTP_CALL,
  CUSTOM_ERROR_MSG,
} from "../../../config/http";
import { showToast } from "../../../shared/util/toastHelper";
import { isEmpty } from "lodash";
import { CancelMultipleBookings } from "../../../config/constants";

interface CancelMultipleBookingsModalFormProps {
  open: boolean;
  onClose: () => void;
  selectedBookingIds: string[];
}

const multipleCancelBookingButtonStyle = {
  backgroundColor: "#333333",
  color: "white",
  marginRight: "10px",
  border: "1px solid #333333",
  height: "29px",
  borderRadius: "8px",
  padding: "4px 11px 4px 11px",
};

const CancelMultipleBookingsModalForm: React.FC<
  CancelMultipleBookingsModalFormProps
> = ({ open, onClose, selectedBookingIds }) => {
  const handleCancelMultipleBookings = async () => {
    // Make API call to cancel multiple booking
    const paymentUpdateAPI =
      BOOKING_MANAGEMENT.API_END_POINTS.MUTLIPLE_BOOKINGS_CANCEL;
    let response: any = {};
    try {
      let params = {
        bookingIds: selectedBookingIds,
        recurringBookingCancel: true,
      };

      response = await HTTP_CALL(paymentUpdateAPI, POST, "", params);
      const {
        data: { data: apiData, statusCode, message },
      } = response;
      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          showToast(
            "Booking cancelled successfully for the selected bookings",
            "Success",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          onClose();
        } else {
          showToast(
            "Booking cancel failed for the selected bookings",
            "Error",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: "rgba(0,0,0,0.5)",
          },
        },
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          textAlign: "center",
          left: "50%",
          width: "25%",
          height: "37vh",
          borderRadius: "4px",
          transform: "translate(-50%, -50%)",
          padding: 16,
          background: "rgba(255, 255, 255, 0.9)",
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{
            position: "absolute",
            top: "15px",
            right: "24px",
            cursor: "pointer",
          }}
        >
          <CloseIcon />
        </IconButton>
        <img src={CancelMultipleBookings} alt="Cancel Multiple Bookings" />
        <h2 style={{ textAlign: "center" }}>Cancel Booking</h2>
        <Typography
          variant="subtitle1"
          style={{ textAlign: "center", color: "grey" }}
        >
          Are you sure you want to cancel this booking?
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "10px",
            marginRight: "5px",
          }}
        ></div>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCancelMultipleBookings}
            style={multipleCancelBookingButtonStyle}
          >
            Cancel this Booking
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CancelMultipleBookingsModalForm;
