import * as yup from "yup";
import { useCommonTranslations } from "./CommonTranslations";

const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
export const AddMembershipProductValidationSchema = () => {
  // eslint-disable-next-line
  const { t } = useCommonTranslations();

  return yup.object().shape({
    name: yup
      .string()
      .required(`${t("productNameRequired")}`)
      .min(3, `${t("minimum3LettersAllowed")}`)
      .max(30, `${t("maximum30LettersAllowed")}`),
    category: yup.string().required(`${t("categoryRequired")}`),
    benefit: yup.string().required(`${t("benefitRequired")}`),
    cycleTypes: yup
      .array()
      .required()
      .min(1, "Minimum one cycletype is required"),
    monthlyPrice: yup
      .string()
      .test("monthlyPrice", "Monthly price is required", function (value) {
        const { cycleTypes } = this.parent;
        if (cycleTypes && cycleTypes.includes(1)) {
          return !!value;
        }
        return true;
      })
      .test(
        "is-decimal",
        `${t("thePriceShouldbeDecimalwithMaximumTwoDigitsAfterComma")}`,
        (val: any) => {
          if (val !== undefined) {
            return patternTwoDigisAfterComma.test(val);
          }
          return true;
        }
      )
      .typeError("Enter a valid number"),
    yearlyPrice: yup
      .string()
      .test("yearlyPrice", "Yearly price is required", function (value) {
        const { cycleTypes } = this.parent;
        if (cycleTypes && cycleTypes.includes(2)) {
          return !!value;
        }
        return true;
      })
      .test(
        "is-decimal",
        `${t("thePriceShouldbeDecimalwithMaximumTwoDigitsAfterComma")}`,
        (val: any) => {
          if (val !== undefined) {
            return patternTwoDigisAfterComma.test(val);
          }
          return true;
        }
      )
      .typeError("Enter a valid number"),
    membershipSessionMin: yup
      .number()
      .required(t("numberOfCoachingSessionsRequired")),
    description: yup
      .string()
      .max(5000, `${t("maximum5000LettersAllowed")}`)
      // .matches(/^[a-zA-Z0-9.,!&%  ]*$/, {
      //   message: "Alphanumeric characters only allowed",
      // }),
  });
};

export const AddUserMembershipValidationSchema = () => {
  // eslint-disable-next-line
  const { t } = useCommonTranslations();

  return yup.object().shape({
    user: yup.mixed()
    .test("is-string-or-object", `${t("userRequired")}`, function (value:any) {
      // Check if the value is either a string or an object
      return typeof value === "string" || (typeof value === "object" && value !== null);
    })
    .required(`${t("userRequired")}`),
    club: yup.string().required(`${t("clubRequired")}`),
    membership: yup.string().required(`${t("membershipRequired")}`),
    cycleTypes: yup.string().required("Cycle type is required"),
    //category: yup.string().required(`${t("categoryRequired")}`),
    paymentType: yup.string().required(`${t("paymentTypeRequired")}`),
    price: yup
      .string()
      .test(
        "is-decimal",
        `${t("thePriceShouldbeDecimalwithMaximumTwoDigitsAfterComma")}`,
        (val: any) => {
          if (val !== undefined) {
            return patternTwoDigisAfterComma.test(val);
          }
          return true;
        }
      )
      .typeError("Enter a valid number"),
    paidAmount: yup
      .string()
      .required("Paid Amount is required")
      .test(
        "is-decimal",
        `${t("thePriceShouldbeDecimalwithMaximumTwoDigitsAfterComma")}`,
        (val: any) => {
          if (val !== undefined) {
            return patternTwoDigisAfterComma.test(val);
          }
          return true;
        }
      )
      .typeError("Enter a valid number"),
    balance: yup
      .string()
      .required("Balance Amount is required")
      .test(
        "is-decimal",
        `${t("thePriceShouldbeDecimalwithMaximumTwoDigitsAfterComma")}`,
        (val: any) => {
          if (val !== undefined) {
            return patternTwoDigisAfterComma.test(val);
          }
          return true;
        }
      )
      .typeError("Enter a valid number"),
      membershipStart: yup.string().required(`${t("fromDateRequired")}`),
      membershipExpiry: yup
        .string()
        .required(`${t("toDateRequired")}`)
        .test(
          "is-valid-range",
          "To date must be after from date",
          function (value) {
            const { membershipStart } = this.parent;
            if (!membershipStart || !value) return true; // If either fromDate or toDate is not provided, don't perform validation
            return new Date(membershipStart) <= new Date(value); // Check if toDate is after fromDate
          }
        ),
  });
};
